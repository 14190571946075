import React from 'react';
import * as XLSX from 'xlsx';

const testMe = (item) => {

  const event = item.event;
  const user = item.user;
  const tempCodeJustification = item.tempCodeJustification;
  const openCloseCodesEffectiveDate = item.openCloseCodesEffectiveDate;
  const domain = item.domain;
  const physicalLocation = item.physicalLocation;
  const previousPhysicalLocation = item.previousPhysicalLocation;

  if(event === "VAULT EDITED") {
    if(physicalLocation !== previousPhysicalLocation) {
        return("Description Edited");
    } else {
        return("Codes Edited");
    }
  } else if(event ==="USER ASSIGNED TO VAULT") {        
        return(user + "assigned to vault");
    } else if(event ==="USER VAULT ASSIGNMENT REMOVED") {
        return(user + "unassigned from vault");
    } else if(event ==="MORNING/NIGHT CODES REQUESTED") {
        return("Morning/Night Codes requested for " + openCloseCodesEffectiveDate + "- Justification: " + tempCodeJustification);
    } else if(event ==="VAULT DOMAIN ASSIGNMENT MODIFIED") {
        return(domain + "domain assigned");
    } else if(event ==="TEMP CODE REQUEST - ENTRY") {
        return("Temp Code Requested - Justification: " + tempCodeJustification);
    } else if(event ==="VAULT ACCESS ACTIVITY") {
        return(tempCodeJustification);
    } else if(event ==="VAULT ACCESS CODES UPDATED") {
        return("Access Codes Updated");
    } else if(event ==="INVALID PASSCODE ATTEMPTED") {
        return("Invalid Passcode Attempted");
    }
  }


const ExportToExcel = ({ data, fileName }) => {
  const handleExport = () => {
    // Transform data to include only specific fields
    const filteredData = data.map(item => ({
      'Event Date': item.eventDate,
      'Device Id': item.assetTag,
      'Device Description': item.physicalLocation,
      'Triggered By': item.triggeredBy,
      'Event Detail': testMe(item),
    }));

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert filtered data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(filteredData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Vault Events');

    // Generate a binary string representation of the workbook
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Create a Blob from the binary string
    const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });

    // Create a link element to download the file
    const link = document.createElement('a');
    link.href = URL.createObjectURL(dataBlob);
    link.download = `${fileName}.xlsx`;
    link.click();
  };

  return (
    <button onClick={handleExport}>Export to Excel</button>
  );
};

export default ExportToExcel;
