import React, {Component} from 'react';

import {vaultService} from '../services/VaultServices';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Growl} from 'primereact/growl';
import {Messages} from 'primereact/messages';
import {Message} from 'primereact/message';
import {RadioButton} from 'primereact/radiobutton';
import {InputTextarea} from 'primereact/inputtextarea';
import {PickList} from 'primereact/picklist';
// import {InputSwitch} from 'primereact/inputswitch';
import  Select from 'react-select';
import DateTime from 'react-datetime';
import moment from 'moment';
import 'moment-timezone';
// import {Checkbox} from 'primereact/checkbox';
import {isMobile} from 'react-device-detect';


export class UserAdmin extends Component {
    constructor(props) {
        super(props);        

        this.state = {
            dataTableValue:[],
            panelCollapsed: true,
            totalRecords: 0,
            loading: false,
            dirty: false,
            vaultUserFirstNameError: "",
            vaultUserLastNameError: "",
            vaultUserEmailError: "",
            vaultUserDomainError: "",
            displayDialogEditUserButton: false,
            tempPassword: "",
            domainTable: [],
            originUserDomains: [],
            originUserVals: [],
            displayDialogDeleteUser: false,
            userDomains: null,
            addMode: false,
            fieldValHidden: true,
            vaultAssignments: {
                vaultId: ""
            },
            selectedUser: null,
            vaultList: null,
            height: props.height, width:props.width,
            featureList: "",
            curUID: ""
        };
        this.export = this.export.bind(this);        
        this.actionTemplate = this.actionTemplate.bind(this);        
        this.onSortChange = this.onSortChange.bind(this);
        this.onVaultUserSelect = this.onVaultUserSelect.bind(this);
        this.onVaultUserAddModRequest = this.onVaultUserAddModRequest.bind(this);
        this.updateProperty = this.updateProperty.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onVaultUserProcess = this.onVaultUserProcess.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.validate = this.validate.bind(this);
        this.copyToClipBoard = this.copyToClipBoard.bind(this);
        this.toggleShow = this.toggleShow.bind(this);
    }

    componentWillMount() {
        //console.log("WINDOW : ",window);
          this.setState({height: window.innerHeight + 'px',width:window.innerWidth+'px'});
    }

    componentDidMount() {
        let user = sessionStorage.getItem("user");
        if (user) {
                    //console.log(user);
                    let x = JSON.parse(user);
                    this.setState(() => ({
                    userId: x.userId,
                    token: x.token,
                    orgId: x.orgId,
                    domainId: x.domainId,
                    featureList: x.featureList,
                    userRole: x.userRole
                  }));
                  if (!x.acceptedEula || x.userRole === 3){
                    this.props.handleLogout();
                  } else{
                    this.setState({ loading: true });
                    vaultService.fetchVaultUsers(x.domainId, x.userId, x.token)
                                .then(data => this.setState({dataTableValue: data.itemResult,
                                                             totalRecords: data.itemResult.length,
                                                             curUID: x.userId}));                                                 
                    vaultService.fetchVaultDomains(x.userId, x.token)
                                .then(data => this.setState({domainTable: data.itemResult}));

                    vaultService.fetchVaults(x.domainId, x.token, x.userId).then(data => this.setState({vaultList: data[0]}));        

                    this.setState({loading: false});   
                                }
                    }                      
        }  
    
    toggleShow() {
        this.setState({ fieldValHidden: !this.state.fieldValHidden });
    }
    
    onVaultUserAddModRequest(action) {
      
        if(action === 'add'){
            this.setState({
                addMode:true,
                displayDialogEditUserButton: false,
                displayDialogAddModVaultUser:true,
                userDomains: "",
                vaultUserInput: { vaultUserEmail:''
                                 ,vaultUserFirstName: ''
                                 ,vaultUserLastName: ''
                                 ,vaultUserStatus: 2
                                 ,vaultUserMobilePhone: ""
                                 ,vaultUserLandline: ""
                                 ,vaultUserPostalCode: ""
                                 ,vaultUserState:""
                                 ,vaultUserAddress2: ""
                                 ,vaultUserAddress1: ""
                                 ,vaultUserTitle: ""
                                 ,vaultUserDept: ""
                                 ,vaultUserDomain: []
                                 ,vaultUserCity: ""
                                 ,vaultUserStartDate: ""
                                 ,vaultUserEndDate: ""
                                 ,vaultUserPassword: ""                                 
                                 ,userDomains: null
                                 ,vaultUserRole: 3     
                                 ,vaultUserFirstNameError: ""
                                 ,vaultUserLastNameError: ""
                                 ,vaultUserEmailError: ""
                                 ,vaultUserDomainError: ""                            
                        },
                tempPassword: "",
                fieldValHidden: true
                });
        } else {                      
            //console.log("and...",this.state);
            this.setState({
                displayDialogEditUserButton: true,
                displayDialogAddModVaultUser:true,
                addMode: false,
                fieldValHidden: true,
                tempPassword: ""
                ,vaultUserFirstNameError: ""
                ,vaultUserLastNameError: ""
                ,vaultUserEmailError: ""
                ,vaultUserDomainError: ""
                               
            });
           
        } 
    }
    
    updateProperty(property, value) {
        //console.log("prop:", property, "val:",value);
                   
        let vaultUserInput = this.state.vaultUserInput;     
        
        
        vaultUserInput[property] = value;  
        this.setState({dirty: true});
    }
    
    handleChange = userDomains => {
        this.setState({ userDomains,
                        dirty: true });
        
       //console.log(`Option selected:`, userDomains);
    };

    
    


    handleDate(date,which){
    //   //console.log(date.format());
        let vaultUserInput = this.state.vaultUserInput;     
        this.setState({dirty: true}); 
    
        if (which === 'startdate'){               
            vaultUserInput['vaultUserStartDate'] = date;      
        } else {
            vaultUserInput['vaultUserEndDate'] = date; 
        }
    }

    onCancel() {
      //console.log(this.state,"in cancel");
      this.setState({
                        vaultUserInput: {}
                        ,displayDialogAddModVaultUser:false
                        ,displayDialogVaultAssignments: false
                        ,vaultUserFirstNameError: ""
                        ,vaultUserLastNameError: ""
                        ,vaultUserEmailError: ""
                        ,vaultUserDomainError: ""
                        ,vaultUserNotes: ""
                        ,vaultUserPassword: ""
                        ,dirty: false
                        ,tempPassword: ""
                        ,loading: false
                        ,date:""
                        ,userDomains: null
                        ,addMode: false,
                        fieldValHidden: true
                });
    }
    
    showError = (message) =>  {
        let msg = {severity: 'error', summary: 'Error Message', detail: message};
        //this.growl.show(msg);
        this.messages.show(msg);
        this.setState({dirty:false});
    };

    async onVaultUserProcess(action){
        
        this.setState({ loading: true});        
        let err = false;
        let noUserDomainChange = false;
        let noUserValChange = false;

        if (action === 'add' || action === 'edit'){
            err = this.validate("add");
        } else {
            err = false;
        }
        
        let currentCount = 0;
        if (!err) { 
            if(action === 'add')
            {                                                  
                // console.log("in add: ", this.state.userDomains);
                let worked = await vaultService.processVaultUserAdminUsersAction_Add(this.state.token, 
                                                                                this.state.vaultUserInput,
                                                                                action,
                                                                                this.state.userId,
                                                                                this.state.userDomains);   
                
                if (worked) {
                        // console.log(data.itemResult)
                         //currentCount = this.state.totalRecords + 1;                             
                         vaultService.fetchVaultUsers(this.state.domainId, this.state.userId, this.state.token)
                                     .then(data => this.setState({dataTableValue: data.itemResult,
                                                                  totalRecords: data.itemResult.length,  
                                                                  loading: false,
                                                                  displayDialogAddModVaultUser:false,
                                                                  displayDialogDeleteUser:false,
                                                                  totalRecords: data.itemResult.length,
                                                                  dataTableValue: data.itemResult}));                         
                            this.growl.show({severity: 'success', summary: 'Success Message', detail: 'Vault User Added.'});                          
                }
                else {
                    this.showError(worked.toString())
                }
            }
                        
             else if(action === 'edit')
            {                                                  
                //console.log(this.state.vaultUserInput, this.state.originUserVals)
                
                noUserValChange = JSON.stringify(this.state.vaultUserInput) === JSON.stringify(this.state.originUserVals);
                noUserDomainChange = JSON.stringify(this.state.userDomains) === JSON.stringify(this.state.originUserDomains);

                let data = await vaultService.processVaultUserAdminUsersAction_Edit(this.state.token, 
                                                                                    this.state.vaultUserInput,
                                                                                    action,
                                                                                    this.state.userId,
                                                                                    this.state.userDomains,
                                                                                    noUserDomainChange,
                                                                                    noUserValChange
                                                                                    ); 
                if(noUserValChange){
                    currentCount = this.state.totalRecords;
                    this.setState({loading: false,
                        displayDialogAddModVaultUser:false,
                        displayDialogDeleteUser:false,
                        totalRecords: currentCount,
                        dataTableValue: this.state.dataTableValue});                         
                    this.growl.show({severity: 'success', summary: 'Success Message', detail: 'Vault User Edited.'});  

                } else if (data.itemResult) {
                    currentCount = this.state.totalRecords;     
                    this.setState({loading: false,
                                    displayDialogAddModVaultUser:false,
                                    displayDialogDeleteUser:false,
                                    totalRecords: currentCount,
                                    dataTableValue: data.itemResult});                         
                    this.growl.show({severity: 'success', summary: 'Success Message', detail: 'Vault User Edited.'});                     
                }
                 else {
                    this.showError(data.data)
                }
            } else if(action === 'delete')
            {                                                  
                //  console.log('in delete', this.state.curUID, this.state.vaultUserInput);
                
                let data = await vaultService.processVaultUserAdminUsersAction_Delete(this.state.token, 
                                                                                        this.state.vaultUserInput,
                                                                                        action,
                                                                                        this.state.userId); 
                if (data.itemResult) {
                    currentCount = this.state.totalRecords -1;     
                    this.setState({loading: false,
                    displayDialogAddModVaultUser:false,
                    displayDialogDeleteUser:false,
                    totalRecords: currentCount,
                    dataTableValue: data.itemResult});                         
                    this.growl.show({severity: 'success', summary: 'Success Message', detail: 'Vault User Deleted.'});  
                    //console.log(data.itemResult);                   
                }
                else {
                    this.showError(data.data)
                }
            }         
        } else {
            this.setState({dirty: false})
        }
    }

    validate = (action) => {
        let isError = false;
        let vaultUserEmail = "";
        let vaultUserFirstName = "";
        let vaultUserLastName = "";
        let vaultPassword = "";
        let vaultUserStatus = "";
        let startDate = "";
        let endDate = "";
                       
        //console.log("val:",this.state.userDomains);

        const errors = {
          vaultUserEmailError: "",
          vaultUserFirstNameError: "",
          vaultUserLastNameError: "",
          vaultUserDomainError: "",
          vaultPasswordError: "",
          vaultStartDateError: "",
          vaultEndDateError: ""          
        };        
        
        vaultUserStatus = this.state.vaultUserInput.vaultUserStatus;                


        if (action === 'add'){
            vaultUserEmail = this.state.vaultUserInput.vaultUserEmail.replace(/^\s+|\s+$/gm,'');
            vaultUserFirstName = this.state.vaultUserInput.vaultUserFirstName.replace(/^\s+|\s+$/gm,'');
            vaultUserLastName = this.state.vaultUserInput.vaultUserLastName.replace(/^\s+|\s+$/gm,'');
            vaultPassword = this.state.vaultUserInput.vaultUserPassword.replace(/^\s+|\s+$/gm,'');
            startDate = this.state.vaultUserInput.vaultUserStartDate;
            endDate = this.state.vaultUserInput.vaultUserEndDate;         
        } else{
            vaultUserEmail = this.state.vaultUserInput.vaultUserEmailEdit.replace(/^\s+|\s+$/gm,'');
            vaultUserFirstName = this.state.vaultUserInput.vaultUserFirstNameEdit.replace(/^\s+|\s+$/gm,'');
            vaultUserLastName = this.state.vaultUserInput.vaultUserLastNameEdit.replace(/^\s+|\s+$/gm,'');            
            vaultPassword = this.state.vaultUserInput.vaultUserPasswordEdit.replace(/^\s+|\s+$/gm,''); 
            startDate = this.state.vaultUserInput.vaultUserStartDate;
            endDate = this.state.vaultUserInput.vaultUserEndDate;    
        };

        if (!vaultUserEmail.length >= 1) {
                isError = true;
                errors.vaultUserEmailError = "User Email is required";                
        } else if (vaultUserEmail.indexOf("@") === -1){
                
                isError = true;
                errors.vaultUserEmailError = "Requires valid information";
        };
                
        if (!vaultUserFirstName.length >= 1) {
                isError = true;
                errors.vaultUserFirstNameError = "First Name is required";
        } else if (!vaultUserLastName.length >= 1){
                isError = true;
                errors.vaultUserLastNameError = "Last Name is required";
        }else if (vaultPassword.length < 6 && vaultUserStatus === 1){
            isError = true;
            errors.vaultPasswordError = "Password and min. length of 6 are required";
        }; 

       if(startDate.length > 0){
        if (Object.prototype.toString.call(startDate) !== "[object Date]") {
            isError = true;
            errors.vaultStartDateError = "Invalid Start Date";
        }
       };
       if(endDate.length > 0){
        if (Object.prototype.toString.call(endDate) !== "[object Date]") {
            isError = true;
            errors.vaultEndDateError = "Invalid End Date";
        }
       };
       


        let test = this.state.userDomains;
        let result = false;
        if( test && test !== "null" && test !== "undefined" && test.length >= 1){
            result = true;
        }
    
        if (!result) {
            isError = true;
            errors.vaultUserDomainError = "A domain selection is required";                   
        }; 
        
        this.setState({
          ...this.state,
          ...errors
        });
    
        return isError;
      }

    handleFocus = name => event => {
        var x = [name] + "Error";    
        this.setState({[x]: ""});         
    }

    async generateTemporaryPass() {
       var generator = require('generate-password-browser');
       let temp =  generator.generate({
        length: 6,
        numbers: true
      });
     
        this.setState({
                        tempPassword : temp
                        ,dirty: true
                    });   
        this.state.vaultUserInput.vaultUserPassword = temp;
    }
    

    copyToClipBoard = () => {
        //console.log("in clip",this.state.tempPassword);
            var msg = this.state.tempPassword;

            navigator.clipboard.writeText(msg);            
            this.growl.show({severity: 'success', detail: 'Password copied!'});
            
    };
 
    export() {
        this.dt.exportCSV();
    }

    onSortChange(event) {
        let value = event.value;

        if (value.indexOf('!') === 0)
            this.setState({sortOrder: -1, sortField:value.substring(1, value.length), sortKey: value});
        else
            this.setState({sortOrder: 1, sortField:value, sortKey: value});
    }

    async onVaultUserSelect(data,action){
       //console.log("data",data);
            
        if(action === "vaultUserDelete"){
                this.setState({
                    displayDialogDeleteUser:true,                    
                    vaultUserInput: {
                        vaultUserEmail: data.email,
                        vaultUserFirstName: data.firstName,
                        vaultUserLastName: data.lastName,
                        vaultUserStatus: data.statusId,
                        vaultUserMobilePhone: data.mobilePhone
                        ,vaultUserLandline: data.landline
                        ,vaultUserPostalCode: data.postalCode
                        ,vaultUserState: data.state
                        ,vaultUserAddress2: data.address2
                        ,vaultUserAddress1: data.address1
                        ,vaultUserTitle: data.title
                        ,vaultUserDept: data.department
                        ,vaultUserDomain: data.domain
                        ,vaultUserId: data.userId
                        ,vaultUserCity: data.city
                        ,vaultUserStartDate: data.startDate
                        ,vaultUserEndDate: data.endDate
                        ,vaultUserPassword: data.password                        
                        ,vaultUserNotes: data.notes
                        ,vaultAuditHelper: data.auditHelper                        
                    },
                    tempPassword: ""
                });                         
        } else if (action === "vaultUserEdit") { 
            this.setState({ loading: true });            
            vaultService.FetchUserToDomains(data.userId, this.state.token)
                        .then(data => this.setState({userDomains: data,
                                                     originUserDomains: data}));                        
            // console.log('and?', data);   
            if(data.startDate){
                data.startDate = moment(data.startDate).format('L');
            }
            if(data.endDate){
                data.endDate = moment(data.endDate).format('L');
            }

            this.setState({ 
                    loading: false,                                      
                    vaultUserInput: {      
                            userId: data.userId,                      
                            vaultUserEmail: data.email,
                            vaultUserFirstName: data.firstName,
                            vaultUserLastName: data.lastName,
                            vaultUserStatus: data.statusId,
                            vaultUserMobilePhone: data.mobilePhone,
                            vaultUserLandline: data.landline
                            ,vaultUserPostalCode: data.postalCode
                            ,vaultUserState: data.state
                            ,vaultUserAddress2: data.address2
                            ,vaultUserAddress1: data.address1
                            ,vaultUserTitle: data.title
                            ,vaultUserDept: data.department
                            ,vaultUserDomain: data.domainId
                            ,vaultUserId: data.userId
                            ,vaultUserCity: data.city
                            ,vaultUserStartDate: data.startDate
                            ,vaultUserEndDate: data.endDate
                            ,vaultUserPassword: data.password                               
                            ,vaultUserNotes: data.notes
                            ,vaultUserRole: data.userRole
                            ,vaultAuditHelper: data.auditHelper                                                
                        }  
                        ,tempPassword: "" 
                        , originUserVals: {
                            vaultUserUserId: data.userId,
                            vaultUserEmail: data.email,
                            vaultUserFirstName: data.firstName,
                            vaultUserLastName: data.lastName,
                            vaultUserStatus: data.statusId,
                            vaultUserMobilePhone: data.mobilePhone,
                            vaultUserLandline: data.landline
                            ,vaultUserPostalCode: data.postalCode
                            ,vaultUserState: data.state
                            ,vaultUserAddress2: data.address2
                            ,vaultUserAddress1: data.address1
                            ,vaultUserTitle: data.title
                            ,vaultUserDept: data.department
                            ,vaultUserDomain: data.domainId
                            ,vaultUserId: data.userId
                            ,vaultUserCity: data.city
                            ,vaultUserStartDate: data.startDate
                            ,vaultUserEndDate: data.endDate
                            ,vaultUserPassword: data.password                               
                            ,vaultUserNotes: data.notes
                            ,vaultUserRole: data.userRole
                            ,vaultAuditHelper: data.auditHelper                                                
                        }              
                    });                                                                    
            this.onVaultUserAddModRequest('edit');

        } else if (action === "user2VaultAssign") {                        
            this.buildPickLists(data);
            
            this.setState({
                displayDialogVaultAssignments:true,
                dirty: false,
                user: Object.assign({}, data)                
            });
        } else { //change status 
                this.setState({
                    displayDialogStatusChange:true,
                    vaultUserInput: Object.assign({}, data)
                })
        }

    }

    statusTemplate(rowData, column) {
        var src = "";
        
        if (rowData.statusId === 1){
            src = "pi pi-check";            
        } else {
            src = "pi pi-times";
        }
        return <i className={src} style={{fontSize:'2em'}}/>;
    }

    mobileOnlyTemplate(rowData, column) {
        var src = "";
        
        if (rowData.userRole === 3){
            src = "pi pi-check";            
        } else {
            src = "pi pi-times";
        }
        return <i className={src} style={{fontSize:'2em'}} />;
    }

    actionTemplate(rowData, column) {        
    //  console.log("row: " + rowData.userRole + "usr: " + this.state.userRole);
     
        if (rowData.description !== 'Default'){
            if(!isMobile){  
                if(this.state.userRole === 1){
                    return <div>                   
                    <Button id="vaultUserEdit"
                            type="button" icon="pi pi-pencil"
                            style={{fontSize:'1em'}}
                            className="p-button-raised p-button-rounded p-button-warning"
                            tooltip="Edit this user"
                            onClick={() => this.onVaultUserSelect(rowData, "vaultUserEdit")}></Button>
                    {rowData.userId !== this.state.curUID &&
                    <Button id="vaultUserDelete"
                            type="button" icon="pi pi-times"
                            style={{margin:'2px',fontSize:'1em'}}
                            className="p-button-raised p-button-rounded p-button-danger"
                            tooltip="Remove this user"
                            onClick={() => this.onVaultUserSelect(rowData, "vaultUserDelete")}></Button>              
                    }
                    <Button id="user2VaultAssign"
                        type="button" icon="pi pi-lock"
                        style={{margin:'2px'}}
                        className="p-button-raised p-button-rounded p-button-info"
                        tooltip="Mobile App Device Assignments"
                        onClick={() => this.onVaultUserSelect(rowData, "user2VaultAssign")}></Button>                        
                    </div>;
                } else if(rowData.userRole > 2 || rowData.userId === this.state.curUID){                  
                    return <div>                   
                        <Button id="vaultUserEdit"
                                type="button" icon="pi pi-pencil"
                                style={{fontSize:'1em'}}
                                className="p-button-raised p-button-rounded p-button-warning"
                                tooltip="Edit this user"
                                onClick={() => this.onVaultUserSelect(rowData, "vaultUserEdit")}></Button>
                        {rowData.userId !== this.state.curUID &&
                        <Button id="vaultUserDelete"
                                type="button" icon="pi pi-times"
                                style={{margin:'2px',fontSize:'1em'}}
                                className="p-button-raised p-button-rounded p-button-danger"
                                tooltip="Remove this user"
                                onClick={() => this.onVaultUserSelect(rowData, "vaultUserDelete")}></Button>              
                        }
                        <Button id="user2VaultAssign"
                            type="button" icon="pi pi-lock"
                            style={{margin:'2px'}}
                            className="p-button-raised p-button-rounded p-button-info"
                            tooltip="Mobile App Device Assignments"
                            onClick={() => this.onVaultUserSelect(rowData, "user2VaultAssign")}></Button>                        
                    </div>;
                }                
            } else { 
                if(rowData.userRole > 2 || rowData.userId === this.state.curUID){                  
                    return <div>
                    <Button id="vaultUserEdit"
                            type="button" icon="pi pi-pencil"
                            style={{fontSize:'1em'}}
                            className="p-button-raised p-button-rounded p-button-warning"
                            tooltip="Edit this user"
                            onClick={() => this.onVaultUserSelect(rowData, "vaultUserEdit")}></Button>                                            
                    {rowData.userId !== this.state.curUID &&
                        <Button id="vaultUserDelete"
                                type="button" icon="pi pi-times"
                                style={{margin:'2px',fontSize:'1em'}}
                                className="p-button-raised p-button-rounded p-button-danger"
                                tooltip="Remove this user"
                                onClick={() => this.onVaultUserSelect(rowData, "vaultUserDelete")}></Button>              
                        }                            
                    </div>;
                }
            }
        }
        
    }    
     
    async  onVaultAssignmentAdd(thisUser) {
        
        try {
            this.setState({ loading: true });
               let userId = this.state.userId;
               let orgId = this.state.orgId;
               let newArr = this.state.vaultPickTarget.map(function(elem) {
                    return {                        
                        vaultId: elem.vaultId,
                        userId: thisUser,
                        action: 'add',
                        activeUserId: userId,
                        orgId: orgId
                    };
                });                 
                    // console.log(newArr);
            await vaultService.processUserToVaultAssignment(newArr, this.state.token, thisUser, userId);                           

            this.growl.show({severity: 'success', summary: 'Success Message', detail: 'Vault Assignment completed.'});             
            
            this.setState({loading: false,
                           displayDialogVaultAssignments:false});
            
          } catch (e) {
            alert(e);                    
            this.setState({loading: false,
                           displayDialogVaultAssignments:false});             
          }        
    }

    onVaultPickChange = (event) => {       
        this.setState({
            vaultPickSource: event.source,
            vaultPickTarget: event.target,         
            dirty: true
        });
    }

    async buildPickLists(data) {
                
        const freshPickList = await vaultService.buildUserToVaultChoices(data.userId, this.state.userId, this.state.token);   
        const existingAssignments = await vaultService.fetchVaultToUserExistingAssignments(data.userId, this.state.userId, this.state.token);
        const picklist = freshPickList.itemResult;
        const existingList = existingAssignments.itemResult;

        // console.log(existingList)
    
        this.setState({
            vaultPickTarget : [...existingList],
            vaultPickSource : [...picklist]
        });       
    }

    vaultTemplate(vault) {      
        if (!vault){
            return null
        }
         return (
             <div className="p-clearfix">
                     <div>{vault.assetTag} - {vault.physicalLocation}</div>                
             </div>
         );       
     }
     roleChangeNotice = () =>  {
		let msg = {severity: 'info', summary: 'Important Note', detail: 'Don\'t forget to modify vault assignments'};
		this.growl.show(msg);		
	};
/* =========================================================================================== */
    render() {                
        const { loading, dirty, vaultUserInput, domainTable, addMode,  vaultPickTarget, vaultPickSource, user, curUID, featureList } = this.state;                                
        
       
        const { userDomains } = this.state;

        const domains = domainTable.map(item => {
            const container = {};
        
            container.label = item.domain + '-' + item.description;
            container.value = item.domainId;
        
            return container;
        })
        var yesterday = moment().subtract( 1, 'day' );
        var valid = function( current ){
            return current.isAfter( yesterday );
        };
        // console.log(userDomains)
       
        const customStyles = {
            control: (provided, state ) => ({
              //none of react-select's styles are passed to <Control />
              ...provided,
              
              width: 220
              
            })
        }
 
        var footer = <div style={{textAlign:'left'}}>
                        <Button type="button" icon="pi pi-external-link" 
                                iconPos="left" label="Export" 
                                onClick={this.export}>
                        </Button>
                     </div>;

        var header = <div className="p-grid p-fluid">
                        <div className="p-col p-lg-2 p-sm-12">
                            <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})}
                                       placeholder="Global Search" size="50" autoComplete="false" />
                        </div>
                        <div className="p-col p-sm-12 p-lg-10" style={{textAlign:'right'}}>
                              <span>Total Users: {this.state.totalRecords}<br/></span>
                              {/* <span>Filtered Results: 0</span> */}
                        </div>                        
                     </div>; 

    //    console.log(featureList);

        /*===================================================================================*/              
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1><i className="pi pi-users"/> Users</h1>
                        {
							    loading && <ProgressSpinner/> 
						}
                        {featureList.includes(6) &&                          
                        <div className="p-col-12" style={{textAlign:"right"}}>                            
                            <span>
                            <Button id="vaultUserAdd"
                                type="button" icon="pi pi-plus"
                                style={{margin:'2px'}}
                                label="Add User"
                                className="p-button-raised p-button-rounded p-button-success"
                                tooltip="Add a new user"
                                onClick={() => this.onVaultUserAddModRequest('add')}>
                            </Button>                            
                            </span>
                        </div>
                        }
                        <Growl ref={(el) => this.growl = el} />
                        <div>
                        <DataTable id="vaultUsers"
                                   value={this.state.dataTableValue} paginatorPosition="bottom"
                                   header={header} footer={footer} paginator={true} rows={10} responsive={true}
                                   selection={this.state.dataTableSelection} selectionMode="single"
                                   resizableColumns={true} 
                                   globalFilter={this.state.globalFilter} emptyMessage="No records found"
                                   ref={(el) => { this.dt = el; }}>
                        {featureList.includes(6) &&
                            <Column body={this.actionTemplate.bind(this)} header="Actions" style={{textAlign:'center',width:'10%'}}/>                            
                        }    
                            <Column field="firstName" header="First Name" sortable={true} headerStyle={{textAlign: "left"}}/>
                            <Column field="lastName" header="Last Name" sortable={true} headerStyle={{textAlign: "left"}}/>
                            {/* <Column field="orgId" header="Organization" sortable={true}/> */}
                            <Column field="email" header="Email" sortable={true} headerStyle={{textAlign: "left"}}/>
                            <Column field="userRoleFull" header="User Mode" sortable={true} headerStyle={{textAlign: "left"}}/>
                            <Column field="statusId" header="Enabled?" sortable={true} body={this.statusTemplate} style={{textAlign: 'center'}} />
                            {/* <Column field="userRole" header="Mobile User Only?" sortable={true} body={this.mobileOnlyTemplate} style={{textAlign: 'center'}} /> */}
                        </DataTable>
                        </div>
                    </div>

                    <div>                   
                    <Dialog visible={this.state.displayDialogAddModVaultUser}
                            modal={true} header={this.state.displayDialogEditUserButton ? (
                                                    <span><i className="pi pi-user"/>Editing {vaultUserInput.vaultUserFirstName} {vaultUserInput.vaultUserLastName}</span>
                                                 ):(
                                                    <span><i className="pi pi-user-plus"/>Add User</span>
                                                 )
                                                }
                            responsive={true}  
                            style={{maxHeight:this.state.height, overflow:'auto'}}                           
                            onHide={() => this.setState({displayDialogAddModVaultUser: false,
                                                         vaultUserEmailError : "",
                                                         vaultUserFirstNameError: "",
                                                         vaultUserLastNameError: "",
                                                         vaultUserDomainError: "",
                                                         vaultPasswordError: "" ,
                                                         vaultStartDateError: "",
                                                         vaultEndDateError: ""                                
                                                        })}>                            
                            
                            
                            <div className="p-grid p-fluid">
                                                                       
                                    {this.state.vaultUserEmailError && (
							           	<Message severity="error" text="A valid email is required" />              
							        )}  
                                    {this.state.vaultUserFirstNameError && (
                                        <Message severity="error" text="First name is required"/>
                                    )}    
                                    {this.state.vaultUserLastNameError && (
                                        <Message severity="error" text="Last name is required"/>
                                    )}
                                    {this.state.vaultUserDomainError && (
                                        <Message severity="error" text="A domain selection is required"/>
                                    )}
                                    {this.state.vaultPasswordError && (
                                        <Message severity="error" text="Password with min. length of 6 is required"/>
                                    )}
                                    {this.state.vaultStartDateError && (
                                        <Message severity="error" text="Invalid Start Date"/>
                                    )}
                                    {this.state.vaultEndDateError && (
                                        <Message severity="error" text="Invalid End Date"/>
                                    )}
                            </div>                                                                                    
                            
                            <Messages ref={(el) => this.messages = el}></Messages>
                            {/* <ScrollPanel style={{width: '100%', height: '240px'}}> */}
                            { vaultUserInput &&  
                            
                            <div className="p-grid p-fluid">                            
                                <div className="p-col-12 p-md-4 p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi contactIcon"></i>
                                    </span>
                                    <InputText id="vaultUserFirstName"
                                               onChange={(e) => {this.updateProperty('vaultUserFirstName', e.target.value)}}
                                               placeholder="First Name" value={vaultUserInput.vaultUserFirstName}
                                               onFocus={this.handleFocus('vaultUserFirstName')}
                                               autoComplete="false" 
                                               className={
                                                            this.state.vaultUserFirstNameError ? 'p-error' : null
                                                        }/> 
                                </div>                                
                                <div className="p-col-12 p-md-4 p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi contactIcon"></i>
                                    </span>
                                    <InputText id="vaultUserLastName"
                                               onChange={(e) => {this.updateProperty('vaultUserLastName', e.target.value)}}
                                               placeholder="Last Name" value={vaultUserInput.vaultUserLastName}
                                               onFocus={this.handleFocus('vaultUserLastName')}
                                               autoComplete="false" 
                                               className={
                                                            this.state.vaultUserLastNameError ? 'p-error' : null
                                                        }/> 
                                </div>
                                <div className="p-col-12 p-md-4 p-inputgroup">                                 
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-envelope"></i>
                                    </span>                                        
                                    <InputText id="vaultUserEmail" 
                                               onChange={(e) => {this.updateProperty('vaultUserEmail', e.target.value)}}
                                               placeholder="Email"  value={vaultUserInput.vaultUserEmail} 
                                               onFocus={this.handleFocus('vaultUserEmail')}
                                               className={
                                                            this.state.vaultUserEmailError ? 'p-error' : null
                                                        }
                                               disabled={!addMode}
                                               autoComplete="false" 
                                                        />
                                </div>                                
                                <div className="p-col-12 p-md-4 p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-sitemap"></i>
                                    </span>
                                    <InputText id="vaultUserTitle"
                                               onChange={(e) => {this.updateProperty('vaultUserTitle', e.target.value)}}
                                               placeholder="Title" value={vaultUserInput.vaultUserTitle}
                                               onFocus={this.handleFocus('vaultUserTitle')}
                                               autoComplete="false" 
                                               /> 
                                </div>
                                <div className="p-col-12 p-md-6 p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-sitemap"></i>
                                        </span> 
                                        { this.state.userRole === 1 ?                                              
                                        <Select value={userDomains}
                                            isMulti
                                            name="vaultUserDomain"
                                            options={domains}
                                            styles={customStyles}                                                                                                
                                            onChange={this.handleChange}                                                                                       
                                            />
                                        :
                                        <Select value={userDomains}
                                            isMulti
                                            name="vaultUserDomain"
                                            options={domains}
                                            styles={customStyles}                                                                                                
                                            onChange={this.handleChange}
                                            isDisabled={this.state.displayDialogEditUserButton ? true : false}                                            
                                            />
                                        }                          
                                </div>                                
                                <div className="p-col-12 p-md-4 p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-sitemap"></i>
                                        </span>                       
                                        <InputText id="vaultUserDept"
                                                   onChange={(e) => {this.updateProperty('vaultUserDept', e.target.value)}}
                                                   placeholder="Organization" value={vaultUserInput.vaultUserDept}
                                                   onFocus={this.handleFocus('vaultUserDept')}
                                                   autoComplete="false" 
                                                   />  
                                </div>
                                <div className="p-col-12 p-md-4 p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi contactIcon"></i>
                                        </span>                       
                                        <InputText id="vaultUserAddress1"
                                                   onChange={(e) => {this.updateProperty('vaultUserAddress1', e.target.value)}}
                                                   placeholder="Address 1" value={vaultUserInput.vaultUserAddress1}
                                                   onFocus={this.handleFocus('vaultUserAddress1')}
                                                   autoComplete="false" 
                                                   />  
                                </div>
                                <div className="p-col-12 p-md-4 p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi contactIcon"></i>
                                        </span>                       
                                        <InputText id="vaultUserAddress2"
                                                   onChange={(e) => {this.updateProperty('vaultUserAddress2', e.target.value)}}
                                                   placeholder="Address 2" value={vaultUserInput.vaultUserAddress2}
                                                   onFocus={this.handleFocus('vaultUserAddress2')}
                                                   autoComplete="false" 
                                                   />  
                                </div>
                                <div className="p-col-12 p-md-4 p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi contactIcon"></i>
                                        </span>                       
                                        <InputText id="vaultUserCity"
                                                   onChange={(e) => {this.updateProperty('vaultUserCity', e.target.value)}}
                                                   placeholder="City/Town" value={vaultUserInput.vaultUserCity}
                                                   onFocus={this.handleFocus('vaultUserCity')}
                                                   autoComplete="false" 
                                                   />  
                                </div>
                                <div className="p-col-12 p-md-4 p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi contactIcon"></i>
                                        </span>                       
                                        <InputText id="vaultUserState"
                                                   onChange={(e) => {this.updateProperty('vaultUserState', e.target.value)}}
                                                   placeholder="State" value={vaultUserInput.vaultUserState}
                                                   onFocus={this.handleFocus('vaultUserState')}
                                                   autoComplete="false" 
                                                   />  
                                </div>
                                <div className="p-col-12 p-md-4 p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi contactIcon"></i>
                                        </span>                       
                                        <InputText id="vaultUserPostalCode"
                                                   onChange={(e) => {this.updateProperty('vaultUserPostalCode', e.target.value)}}
                                                   placeholder="Postal Code" value={vaultUserInput.vaultUserPostalCode}
                                                   onFocus={this.handleFocus('vaultUserPostalCode')}
                                                   autoComplete="false" 
                                                   />  
                                </div>
                                <div className="p-col-12 p-md-4 p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi teleIcon" />
                                        </span>                       
                                        <InputText id="vaultUserLandline"
                                                   onChange={(e) => {this.updateProperty('vaultUserLandline', e.target.value)}}
                                                   placeholder="Phone" value={vaultUserInput.vaultUserLandline}
                                                   onFocus={this.handleFocus('vaultUserLandline')}
                                                   autoComplete="false" 
                                                   />  
                                </div>
                                <div className="p-col-12 p-md-4 p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-mobile"></i>
                                        </span>                       
                                        <InputText id="vaultUserMobilePhone"
                                                   onChange={(e) => {this.updateProperty('vaultUserMobilePhone', e.target.value)}}
                                                   placeholder="Mobile Phone" value={vaultUserInput.vaultUserMobilePhone}
                                                   onFocus={this.handleFocus('vaultUserMobilePhone')}
                                                   autoComplete="false" 
                                                   />  
                                </div>
                                <div className="p-col-12 p-md-4 p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-calendar"></i>
                                        </span>  
                                        
                                        <DateTime closeOnSelect={true} 
                                                  name="vaultUserStartDate"                                                  
                                                  className="p-inputtext p-container dpFill-width" 
                                                  inputProps={{ placeholder: 'Mobile Access Start Date', disabled: vaultUserInput.vaultUserRole !== 3, autoComplete: 'startdate'}}
                                                  value={(vaultUserInput.vaultUserStartDate==="1/1/1900" ? "" : vaultUserInput.vaultUserStartDate)}
                                                  onChange={(e) => this.handleDate(e,'startdate')}
                                                  timeFormat={false}  
                                                  isValidDate={valid}                                                
                                                 />
                                </div>
                                <div className="p-col-12 p-md-4 p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-calendar"></i>
                                        </span>                       
                                        <DateTime closeOnSelect={true} 
                                                  name="vaultUserEndDate"
                                                  timeFormat={false} 
                                                  className="p-inputtext p-container dpFill-width" 
                                                  inputProps={{ placeholder: 'Mobile Access End Date', disabled: vaultUserInput.vaultUserRole !== 3, autoComplete: 'enddate'}}
                                                  value={(vaultUserInput.vaultUserEndDate==="1/1/1900" ? "" : vaultUserInput.vaultUserEndDate)}
                                                  onChange={(e) => this.handleDate(e,'enddate')}
                                                  autoComplete="false"
                                                  isValidDate={valid}   
                                                  />
                                </div>
                                <div className="p-col-12 p-md-4 p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-key"></i>
                                        </span>                                                              
                                        <input type={this.state.fieldValHidden ? "password" : "text"}
                                               value={vaultUserInput.vaultUserPassword}
                                               onChange={(e) => {this.updateProperty('vaultUserPassword', e.target.value)}}
                                            
                                               className={
                                                            this.state.vaultPasswordError ? 'form-control p-inputtext p-container dpFill-width p-error' : 
                                                         "form-control p-inputtext p-container dpFill-width"}
                                                
                                               
                                               
                                               placeholder="Password"
                                               onFocus={this.handleFocus('vaultUserPassword')}
                                               autoComplete="false" 
                                               />                              
                                        <div className="p-inputgroup" style={{borderLeft: '1px solid #E0E0E0', borderRadius: '3px', paddingLeft: '5px'}}>
                                         <i className="diceIcon">
                                        <Button id="vaultPasswordGenerator"
                                                type="button" icon="pi"
                                                tooltip="Generate password"
                                                style={{backgroundColor: 'transparent', border: 'none'}}                                                                                                                                              
                                                onClick={() => this.generateTemporaryPass()}></Button>
 {/* disabled={vaultUserInput.vaultUserStatus === 2} */}
                                        </i> 
                                        <i className={this.state.fieldValHidden ? "eyeOpenIcon" : "eyeClosedIcon"} style={{marginLeft: '1em'}}>
                                        <Button id="vaultUserEdit"
                                                type="button" icon="pi"
                                                tooltip={this.state.fieldValHidden ? "View password" : "Hide password"}
                                                style={{backgroundColor: 'transparent', border: 'none'}}                                                                                                                                              
                                                onClick={this.toggleShow}></Button>
                                        </i>                                                                                                                                                  
                                         
                                        </div>                                     
                                </div>
                                <div className="p-md-4 p-inputgroup">

                                </div>
                                <div className="p-col-12 p-md-4 p-inputgroup">                                 
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon" style={{margin:'auto'}}>
                                                <i className="pi pi-user" />
                                                <strong>User Mode</strong>
                                            </span>
                                            { this.state.userRole === 1 ?                                            
                                            <span style={{marginTop:'5px',marginLeft:'5px'}}>                                            
                                                <RadioButton id="rd01"
                                                            name="vaultUserRole"
                                                            inputId="vaultUserRole" 
                                                            value={1}
                                                            tooltip="Administrators have unrestricted access to the enterprise website and the mobile app."
                                                            onChange={(e) => {this.updateProperty('vaultUserRole', e.target.value)}} 
                                                            checked={vaultUserInput.vaultUserRole === 1 || vaultUserInput.vaultUserRole === 2} />
                                                <label htmlFor="rb01" className="p-radiobutton-label" style={{marginRight:'5px'}}>Administrator</label>                                       
                                                <br/>
                                                <RadioButton inputId="vaultUserRole" id="rb02" 
                                                            value={3} name="vaultUserRole"
                                                            tooltip="Mobile Only users can only use the MicroKeyVault Mobile app. They cannot access the enterprise website."
                                                            onChange={(e) => {this.updateProperty('vaultUserRole', e.target.value); this.roleChangeNotice();}} 
                                                            checked={vaultUserInput.vaultUserRole === 3} />
                                                <label htmlFor="rb02" className="p-radiobutton-label">Mobile Only</label> 
                                                <br/>
                                                <RadioButton inputId="vaultUserRole" id="rb03" 
                                                            value={5} name="vaultUserRole"
                                                            tooltip="SOC Mode users can only retrieve Temp Codes and Morning/Night codes from the enterprise website."
                                                            onChange={(e) => {this.updateProperty('vaultUserRole', e.target.value); this.roleChangeNotice();}} 
                                                            checked={vaultUserInput.vaultUserRole === 5} />
                                                <label htmlFor="rb03" className="p-radiobutton-label">SOC Mode</label> 
                                                <br/>
                                                <RadioButton inputId="vaultUserRole" id="rb04" 
                                                            value={4} name="vaultUserRole"
                                                            tooltip="AP Mode users have read-only access to the enterprise website. They cannot manage domains, users, or retrieve Temp Codes or Morning/Night codes."
                                                            onChange={(e) => {this.updateProperty('vaultUserRole', e.target.value); this.roleChangeNotice();}} 
                                                            checked={vaultUserInput.vaultUserRole === 4} />
                                                <label htmlFor="rb04" className="p-radiobutton-label">AP Mode</label> 
                                            </span>                                                
                                            :  this.state.userRole === 2 && this.state.curUID === vaultUserInput.userId ?  
                                            <span style={{marginTop:'5px',marginLeft:'5px'}}>  
                                            <RadioButton inputId="vaultUserRole" id="rb02" 
                                                            value={3} name="vaultUserRole"
                                                            checked={vaultUserInput.vaultUserRole === 2} 
                                                            disabled={true}                                                            
                                                            />
                                                <span style={{color:'gray'}}>Administrator</span>
                                            </span>
                                            :
                                            <span style={{marginTop:'5px',marginLeft:'5px'}}>  
                                            <RadioButton inputId="vaultUserRole" id="rb02" 
                                                            value={3} name="vaultUserRole"
                                                            checked={vaultUserInput.vaultUserRole === 3} 
                                                            disabled={true}                                                            
                                                            />
                                                <span style={{color:'gray'}}>Mobile Only</span>
                                            </span>
                                            }                                      
                                        </div>                                                 
                                </div>
                                <div className="p-col-12 p-md-4 p-inputgroup">                                 
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon" style={{margin:'auto'}}>
                                                <i className="pi pi-info" />
                                                <strong>Account Status</strong>
                                            </span>
                                            <span style={{margin:'auto', paddingLeft:'1em'}}>                                           
                                                <RadioButton id="rd01"
                                                            name="vaultUserStatus"
                                                            inputId="vaultUserStatus" 
                                                            value={1}
                                                            onChange={(e) => {this.updateProperty('vaultUserStatus', e.target.value)}} 
                                                            checked={vaultUserInput.vaultUserStatus === 1} />
                                                <label htmlFor="rb01" className="p-radiobutton-label" style={{marginRight:'5px'}}>Enabled</label>                                       
                                                <br/>
                                                <RadioButton inputId="vaultUserStatus" id="rb02" 
                                                            value={2} name="vaultUserStatus"
                                                            onChange={(e) => {this.updateProperty('vaultUserStatus', e.target.value)}} 
                                                            checked={vaultUserInput.vaultUserStatus === 2} />
                                                <label htmlFor="rb02" className="p-radiobutton-label">Disabled</label> 
                                            </span>                                          
                                        </div>                                                 
                                </div>                                
                                <div className="p-col-12 p-md-12 p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-pencil"></i>
                                        </span> 
                                        <InputTextarea rows={5} cols={30} value={vaultUserInput.vaultUserNotes} 
                                                       onChange={(e) => {this.updateProperty('vaultUserNotes', e.target.value)}} 
                                                       onFocus={this.handleFocus('vaultUserLastName')}
                                                       placeholder="Notes"
                                                       autoComplete="false"
                                                       wrap={true}
                                                       />                      
                                        
                                </div>  
                             
                                
                              
                            </div>
                            }
                            <div className="p-grid" style={{textAlign:'right'}}>
                                <div className="p-col" style={{marginTop:'1em'}}>                                   
                                       
                                        {this.state.displayDialogEditUserButton ? (
                                            <Button id="vaultEdit"
                                                type="button" icon="pi pi-check"
                                                style={{margin:'2px'}}                               
                                                className="p-button-raised p-button-rounded p-button-success"
                                                tooltip="Edit user"
                                                label="Complete Edit"
                                                onClick={() => this.onVaultUserProcess('edit')}
                                                disabled={!dirty}>
                                            </Button>
                                        )  
                                        :(                                            
                                            <Button id="userAdd"
                                                type="button" icon="pi pi-check"
                                                style={{margin:'2px'}}                               
                                                className="p-button-raised p-button-rounded p-button-success"
                                                tooltip="Add a new user"
                                                label="Add User"
                                                onClick={() => this.onVaultUserProcess('add')}
                                                disabled={!dirty}>
                                            </Button>                                          
                                        )
                                        }
                                        <Button id="userCancel"
                                            type="button" icon="pi pi-times"
                                            style={{margin:'2px'}}                               
                                            className="p-button-raised p-button-rounded p-button-danger"
                                            tooltip="Cancel"
                                            label="Cancel"
                                            onClick={() => this.onCancel('add')}>
                                        </Button>  
                                </div>
                                                                                               
                             </div>   
                            {/* </ScrollPanel>                                                    */}
                    </Dialog>
                   
                    </div>

                    { vaultUserInput &&  
                    <div>
                    <Dialog visible={this.state.displayDialogDeleteUser}
                            modal={true} header={<span><i className="pi pi-user"/>Delete User</span>}
                            responsive={true} 
                            onHide={() => this.setState({displayDialogDeleteUser: false})}>
                            <div className="p-grid">
                                <div className="p-col">
                                    <div className="p-inputgroup">
                                        Are you sure you want to delete {vaultUserInput.vaultUserFirstName} {vaultUserInput.vaultUserLastName} ?<br/>
                                        This action is permanent
                                    </div>
                                </div>
                            </div>                            
                            <div className="p-grid" style={{textAlign:'right'}}>
                                <div className="p-col" style={{marginTop:'1em'}}>                                   
                                        <Button id="domainConfirmed"
                                            type="button" icon="pi pi-check"
                                            style={{margin:'2px'}}                               
                                            className="p-button-raised p-button-rounded p-button-success"                                            
                                            label="Yes"
                                            onClick={() => this.onVaultUserProcess('delete')}>
                                        </Button>  
                                        <Button id="domainCancel"
                                            type="button" icon="pi pi-times"
                                            style={{margin:'2px'}}                               
                                            className="p-button-raised p-button-rounded p-button-danger"                                            
                                            label="Cancel"
                                            onClick={() => this.setState({displayDialogDeleteUser: false, loading: false})}>
                                        </Button>  
                                </div>                                                              
                             </div>
                            
                    </Dialog>
                    </div>      
                    }  

                    <div>
                    <Dialog visible={this.state.displayDialogVaultAssignments}
                            modal={true} header="Mobile App Device Assignments" style={{width:"60%"}}
                            onHide={() => this.setState({displayDialogVaultAssignments: false})}>
                        
                        {
                            vaultPickSource &&
                                 
                            <div className="card">
                                    <div className="p-grid p-align-center p-fluid">
                                        <div className="p-col">
                                            <div className="box"><h1>Mobile App Device Assignments for {user.firstName} {user.lastName}:</h1></div>
                                        </div>
                                        <div className="p-col">
                                            <div className="p-grid">
                                                <div className="p-col">
                                                    <Button id="vaultAssignAdd"
                                                        type="button" icon="pi pi-check"                                                        
                                                        className="p-button-raised p-button-rounded p-button-success"
                                                        tooltip="Create assignments"
                                                        label="Assign"
                                                        onClick={() => this.onVaultAssignmentAdd(user.userId)}
                                                        disabled={!dirty}>
                                                    </Button>  
                                                </div>
                                                <div className="p-col">
                                                    <Button id="vaultAssignCancel"
                                                        type="button" icon="pi pi-times"                                                                             
                                                        className="p-button-raised p-button-rounded p-button-danger"                                                        
                                                        label="Cancel"
                                                        onClick={() => this.onCancel('addAssign')}>
                                                    </Button>
                                                </div>
                                            </div>  
                                        </div>                                        
                                    </div>                                   
                                    <div className="p-col">
                                            <PickList source={vaultPickSource}
                                                      target={vaultPickTarget}                                                       
                                                      sourceHeader="Available" 
                                                      targetHeader="Selected"
                                                      itemTemplate={this.vaultTemplate} 
                                                      responsive={true} 
                                                      sourceStyle={{height: '300px'}} 
                                                      targetStyle={{height: '300px'}}
                                                      onChange={this.onVaultPickChange}>
                                            </PickList>                                                                             
                                    </div>
                                </div>
                        }
                    </Dialog>
                    </div>

                </div>
            </div>
        );
    }
}