import React, {Component} from 'react';

export class Downloads extends Component {
    constructor(props) {
        super(props);
        this.state = {        
            AndroidButton: '/assets/images/downloadAndroidApp.jpg',
            AppleButton: '/assets/images/downloadAppleApp.jpg',
            AndroidUATButton: '/assets/images/downloadAndroidUATApp.jpg'
        };       
    }      
    render() {
        return (
            <div>
                <div className="p-col-12">
                    <div className="card" style={{textAlign:"center"}}>
                        <h1>MicroKeyVault Mobile App</h1>
                        <a href="/assets/resource/mkv_mobile.apk" 
                           alt="Download UAT App" 
                           title="Download the Android UAT App" 
                           target="_blank" 
                           rel="noopener noreferrer">                        
                        <img src={this.state.AndroidUATButton} 
                             alt="Download Android UAT App" 
                             title="Download Android UAT App" 
                             width="240px" 
                             height="85px" 
                             style={{marginRight: "5px"}}/></a> 
                        <a href="https://play.google.com/store/apps/details?id=com.mwspi" 
                           alt="Download App" 
                           title="Download the Android App" 
                           target="_blank" 
                           rel="noopener noreferrer">                        
                        <img src={this.state.AndroidButton} 
                             alt="Download Android App" 
                             title="Download Android App" 
                             width="240px" 
                             height="85px" 
                             style={{marginRight: "5px"}}/></a>                           
                        <a href="https://apps.apple.com/us/app/microkeyvault-mobile/id1544336093" 
                           alt="Download App" 
                           title="Download the Android App" 
                           target="_blank" 
                           rel="noopener noreferrer">                        
                        <img src={this.state.AppleButton} alt="Download Apple App" title="Download Apple App" width="240px" height="85px"/></a>                     
                    </div>
                </div>
            </div>
        );
    }
}