import React, {Component} from 'react';
import {Switch, withRouter, Route} from 'react-router-dom';
import UnauthenticatedRoute from "../routes/UnauthenticatedRoute";
import AuthenticatedRoute from "../routes/AuthenticatedRoute";

import Login from "../pages/Login";
import NotFound from "../pages/NotFound";
import {Dashboard} from "../components/Dashboard";
import {Vault} from '../components/Vault';
import {Domains} from '../components/Domains';
import {VaultGroups} from '../components/VaultGroups';
import {UserAdmin} from '../components/UserAdmin';
import {Roles} from '../components/Roles';
import {VaultActivities} from '../components/VaultActivities';
import {UserActivities} from '../components/UserActivities';
import {QRScan} from '../components/QRScan';
import {VisualDomainManagementTool} from '../components/VisualDomainManagementTool';
import {Eula} from '../components/Eula';
import {EulaStatic} from '../components/EulaStatic';
import {Downloads} from '../components/Downloads';

import { userService } from "../services/AuthServices";

class AppWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      fields: {},
      organizationName: ""
    };
  }
  
  async componentDidMount() {         
    try {
      if (await userService.authUser()) {
          this.userHasAuthenticated(true);         
      }
    }
    catch(e) {
      alert(e);
    }

    this.setState({ 
      isAuthenticating: false
     });
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  handleLogout = event => {
    userService.logout();

    this.userHasAuthenticated(false);
    this.setState({disableMenu: false});
    this.props.history.push("/login");
  }
  
  componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0)
		}
	}

	render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      handleChange: this.handleChange,
      fields: this.state.fields,
      handleLogout: this.handleLogout
    };     
    
     return (
      !this.state.isAuthenticating &&  
      <Switch>
        <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />        
        <AuthenticatedRoute path="/" exact component={Eula} props={childProps} />
        <AuthenticatedRoute path="/dashboard" exact component={Dashboard} props={childProps} />
        <AuthenticatedRoute path="/vaultadmin" exact component={Vault} props={childProps} />
        <AuthenticatedRoute path="/domains" exact component={Domains} props={childProps} />        
        <AuthenticatedRoute path="/vaultgroups" exact component={VaultGroups} props={childProps} />
        <AuthenticatedRoute path="/useradmin" exact component={UserAdmin} props={childProps} />
        <AuthenticatedRoute path="/roles" exact component={Roles} props={childProps} />
        <AuthenticatedRoute path="/vaultactivities" exact component={VaultActivities} props={childProps} />
        <AuthenticatedRoute path="/useractivities" exact component={UserActivities} props={childProps} />
        <AuthenticatedRoute path="/qrscan" exact component={QRScan} props={childProps} />
        <AuthenticatedRoute path="/visualdomainmanagementtool" exact component={VisualDomainManagementTool} props={childProps} />
        <AuthenticatedRoute path="/EulaStatic" exact component={EulaStatic} props={childProps} />
        <AuthenticatedRoute path="/Downloads" exact component={Downloads} props={childProps} />

        { /* Finally, catch all unmatched routes */ }
        <Route component={NotFound} />
      </Switch>);
    }      	
}

export default withRouter(AppWrapper);