import React, {Component} from 'react';

import {vaultService} from '../services/VaultServices';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Growl} from 'primereact/growl';
import {ProgressSpinner} from 'primereact/progressspinner';
import DateTime from 'react-datetime';
import moment from 'moment';
import 'moment-timezone';
import {MobileView, BrowserView} from 'react-device-detect';
import ObjectUtils from 'primereact/components/utils/ObjectUtils';
import ExportToExcel from './ExportToExcel';

export class VaultActivities extends Component {

    constructor() {
        super();
        this.state = {
            dataTableValue:[],
            panelCollapsed: true,
            defaultMLImagePath: 'assets/images/masterlock/',
            defaultImgExt: '.svg',            
            totalRecords: 0,
            vault:null,
            dirty: false,
            user: null,            
            selectedVault: null, 
            visible: false,
            sortKey: null,
            sortOrder: null,
            domainList: [],
            expandedRows: null,
            auditStartDate: null,
            auditEndDate: null,
            orgTimeZone: null
        };

        
        this.onSortFunction = this.onSortFunction.bind(this);
        this.imageTemplate = this.imageTemplate.bind(this);
        this.rowClassName = this.rowClassName.bind(this);  
        this.detailTemplate = this.detailTemplate.bind(this);
        this.endDateRef = React.createRef();      
    }

    componentDidMount() {
        let user = sessionStorage.getItem("user");
        if (user) {
                // console.log(user);
                let x = JSON.parse(user);
                this.setState(() => ({
                userId: x.userId,
                token: x.token,
                domainId: x.domainId
                }));  
                
                if (!x.acceptedEula || x.userRole === 3){
                    this.props.handleLogout();
                  } else{

                this.setState({ loading: true });
                
                vaultService.fetchVaultEvents(x.userId, x.token).then(data => this.setState({dataTableValue: data.itemResult,
                                                                                             totalRecords: data.itemResult.length,
                                                                                             orgTimeZone: 
                                                                                            data.itemResult.length ? 
                                                                                             data.itemResult[0].orgTimeZone
                                                                                            : "---NONE FOUND---",
                                                                                         loading: false}));      
                // this.setState({loading: false}); 
                  }
        }                   
    }    

    handleDate(date,which){
        //console.log(this.state.auditEndDate);                               
           
            if (which === 'startdate'){
                 
                if(this.state.auditEndDate != null){
                    this.setState({ auditStartDate : date,
                                    dirty: true});
                } else {
                    this.setState({auditStartDate: date});
                }
                
                this.setState({ auditStartDate : date});
            } else {
                if(this.state.auditStartDate != null){
                    if(date < this.state.auditStartDate){
                        alert("The end search date must be greater than or equal to the start date\nPlease correct");                                                
                        this.endDateRef.current.setState({inputValue: null});                        
                        this.resetDataTable();                        
                    } else {
                        this.setState({ auditEndDate : date,
                                        dirty: true}); 
                    }                    
                } else {
                    this.setState({ auditEndDate : date}); 
                }                
            }
            
    }
    async  updateDataTable() {
        
        try {
            this.setState({ loading: true });
            let userId = this.state.userId;
            let token = this.state.token;
            let startDate = this.state.auditStartDate;
            let endDate = this.state.auditEndDate;

            await vaultService.fetchVaultEvents(userId, token, startDate, endDate).then(data => this.setState({dataTableValue: data.itemResult,
                                                                                                               totalRecords: data.itemResult.length}));
            this.setState({loading: false});
            
          } catch (e) {
            alert(e);                    
            this.setState({loading: false});             
          }        
    }

    async  resetDataTable() {
        
        try {
            this.setState({ loading: true,
                            auditStartDate: null,
                            auditEndDate: null });
            let userId = this.state.userId;
            let token = this.state.token;
           

            await vaultService.fetchVaultEvents(userId, token).then(data => this.setState({dataTableValue: data.itemResult,
                                                                                           totalRecords: data.itemResult.length}));
            this.setState({loading: false,
                           dirty: false});
            
          } catch (e) {
            alert(e);                    
            this.setState({loading: false,
                           dirty: false});             
          }        
    }     
           
    renderHeader() {
       
        return (
            <div className="p-grid">
                <div className="p-col" style={{textAlign: 'left'}}>                                                                                                                                             
                    <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})} 
                               placeholder="Global Search" size="25"/>
                    {/* <i className="pi pi-search" style={{margin:'4px 4px 0 6px'}}></i>                        */}
                </div>
                <BrowserView>
                <div className="p-col">                    
                    <ExportToExcel data={this.state.dataTableValue} fileName="VaultEvents" />
                </div>
                </BrowserView>
                <div className="p-col" style={{paddingTop: "1em"}}>
                    Total Events: {this.state.totalRecords}
                </div>               
            </div>
        );
    }    
    
    imageTemplate(data) {
        let imgCheck = data.vaultModel;
        
        if (imgCheck.length){
            return  (
                    <div><img style={{width:"100%",margin:"auto"}} src={this.state.defaultMLImagePath + data.vaultModel + this.state.defaultImgExt}
                            alt={data.vaultModel}
                            width="30px"
                            height="30px" />
                    </div>
            );
        } else {
            return  (
                <div style={{padding:'1em',width:'100%'}}><img src="/assets/layout/images/warning.png"
                        alt={data.vaultModel}
                        width="32px"
                        height="32px" />
                </div>
        );
        }
    }

    detailTemplate(data) {                         
        // console.log("data: ", data);
        if(data.event === "VAULT EDITED") {
            if(data.physicalLocation !== data.previousPhysicalLocation) {
                return(<span>Description Edited</span>);
            } else {
                return(<span>Codes Edited</span>);
            }
        } else if(data.event ==="USER ASSIGNED TO VAULT") {
            return(<span>{data.user} assigned to vault</span>);
        } else if(data.event ==="USER VAULT ASSIGNMENT REMOVED") {
            return(<span>{data.user} unassigned from vault</span>);
        } else if(data.event ==="MORNING/NIGHT CODES REQUESTED") {
            return(<span>Morning/Night Codes requested for {data.openCloseCodesEffectiveDate} - Justification: {data.tempCodeJustification}</span>);
        } else if(data.event ==="VAULT DOMAIN ASSIGNMENT MODIFIED") {
            return(<span>{data.domain} domain assigned</span>);
        } else if(data.event ==="TEMP CODE REQUEST - ENTRY") {
            return(<span>Temp Code Requested - Justification: {data.tempCodeJustification}</span>);
        } else if(data.event ==="VAULT ACCESS ACTIVITY") {
            return(<span>{data.tempCodeJustification}</span>);
        } else if(data.event ==="VAULT ACCESS CODES UPDATED") {
            return(<span>Access Codes Updated</span>);
        } else if(data.event ==="INVALID PASSCODE ATTEMPTED") {
            return(<span>Invalid Passcode Attempted</span>);
        }
    }

    rowClassName(rowData) {
        let event = rowData.event;
        
        return {'p-highlight' : (event.includes('INVALID')||event.includes('NOT AUTHORIZED'))};
    }
    
    onSortFunction(event) {
        
        var value = [...this.state.dataTableValue];
        value.sort((data1, data2) => {
            let value1 = ObjectUtils.resolveFieldData(data1, event.field);
            let value2 = ObjectUtils.resolveFieldData(data2, event.field);
            let result = null;

            if (value1 === null && value2 !== null)
                result = -1;
            else if (value1 !== null && value2 === null)
                result = 1;
            else if (value1 === null && value2 === null)
                result = 0;           
            else if (typeof value1 === 'string' && typeof value2 === 'string') {
    
                    // let test = value1.substr(2,1)                   
                    /*
                    if(test === '-' || test === '/'){                      
                      //var date1 = moment(value1).format("DD/MM/YYYY HH:mm:ss");
                      //var date2 = moment(value2).format("DD/MM/YYYY HH:mm:ss");
                      //result = moment.duration(moment(value1).diff(moment(value2,'hours')));
                      result = moment(value1).diff(moment(value2));
                    }else{
                    //   result = value1.localeCompare(value2);
                    result = moment(value1).diff(moment(value2));
                    }*/
                    result = moment(value1).diff(moment(value2));
                
            }           
            else
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

        //    console.log("result: ", event.order * result)


            return (event.order * result);
        });

        return value;
    }
   
   
    
        
/* =========================================================================================== */
    render() {
        const { loading, auditEndDate, auditStartDate, dirty } = this.state;
        const header = this.renderHeader();     

        //console.log(this.state.dataTableValue);

/* =========================================================================================== */    
        return (
            <div className="flexi-grid">
                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1>
                        {
							    loading && <ProgressSpinner style={{width: '50px', height: '50px'}} 
                                                             strokeWidth="3"                                                             
                                                             animationDuration=".8s"/>
						}
                        <i className="pi pi-unlock"/>	Device Activity </h1>
                        <Growl ref={(el) => this.growl = el} />
                        <MobileView>
                        <div className="p-col-4" style={{float: "left", margin: 0, padding:0}}> All activities recorded in {this.state.orgTimeZone}</div> 
                        <div className="p-grid p-justify-center">                           
                            <div className="p-col-12 p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-calendar"></i>
                                </span>                       
                                <DateTime closeOnSelect={true} 
                                        name="auditStartDate"                                                  
                                        className="p-inputtext p-container dpFill-width" 
                                        inputProps={{ placeholder: 'Start Date'}}
                                        value={(auditStartDate==="1/1/1900" ? "" : auditStartDate)}
                                        onChange={(e) => this.handleDate(e,'startdate')}
                                        timeFormat={true}                                                  
                                        />
                            </div>
                            <div className="p-col-12 p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-calendar"></i>
                                </span>                       
                                <DateTime closeOnSelect={true} 
                                            name="auditEndDate"                                                  
                                            className="p-inputtext p-container dpFill-width" 
                                            inputProps={{ placeholder: 'End Date'}}
                                            value={(auditEndDate ==="1/1/1900" ? "" : auditEndDate)}
                                            onChange={(e) => this.handleDate(e,'enddate')}
                                            timeFormat={true}
                                            ref={this.endDateRef}                                                   
                                            />
                            </div>
                            <div className="p-col-8 p-justify-right p-inputgroup">
                                <Button id="filter"
                                    type="button" icon="pi pi-key"
                                    style={{margin:'2px'}}                               
                                    className="p-button-raised p-button-rounded p-button-success"
                                    tooltip="Filter Records"
                                    label="GO"
                                    onClick={() => this.updateDataTable()}
                                    disabled={dirty === false}>
                                </Button>
                                <Button id="clear"
                                    type="button" icon="pi pi-trash"
                                    style={{margin:'2px'}}                               
                                    className="p-button-raised p-button-rounded p-button-warning"
                                    tooltip="Clear Filter"
                                    label="CLEAR"
                                    onClick={() => this.resetDataTable()}
                                    disabled={dirty === false}>
                                </Button> 
                            </div>
                        </div>
                        </MobileView>
                        <BrowserView>            
                        <div style={{float: "left", margin: 0, padding:0}}> All activities recorded in {this.state.orgTimeZone}</div>            
                        <div className="p-grid p-justify-center">                           
                        
                            <div className="p-col-2 p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-calendar"></i>
                                </span>                       
                                <DateTime closeOnSelect={true} 
                                        name="auditStartDate"                                                  
                                        className="p-inputtext p-container" 
                                        style={{width: '100px'}}
                                        inputProps={{ placeholder: 'Start Date-Time'}}
                                        value={(auditStartDate==="1/1/1900" ? "" : auditStartDate)}
                                        onChange={(e) => this.handleDate(e,'startdate')}
                                        timeFormat={true}                                                  
                                        />
                            </div>
                            <div className="p-col-2 p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-calendar"></i>
                                </span>                       
                                <DateTime closeOnSelect={true} 
                                            name="auditEndDate"                                                  
                                            className="p-inputtext p-container dpFill-width" 
                                            inputProps={{ placeholder: 'End Date-Time'}}
                                            value={(auditEndDate ==="1/1/1900" ? "" : auditEndDate)}
                                            onChange={(e) => this.handleDate(e,'enddate')}
                                            timeFormat={true}
                                            ref={this.endDateRef}                                                   
                                            />
                            </div>
                            <div className="p-col-1 p-inputgroup">
                                <Button id="filter"
                                    type="button" icon="pi pi-key"
                                    style={{margin:'2px'}}                               
                                    className="p-button-raised p-button-rounded p-button-success"
                                    tooltip="Filter Records"
                                    label="GO"
                                    onClick={() => this.updateDataTable()}
                                    disabled={dirty === false}>
                                </Button>
                                <Button id="clear"
                                    type="button" icon="pi pi-trash"
                                    style={{margin:'2px'}}                               
                                    className="p-button-raised p-button-rounded p-button-warning"
                                    tooltip="Clear Filter"
                                    label="CLEAR"
                                    onClick={() => this.resetDataTable()}
                                    disabled={dirty === false}>
                                </Button> 
                                
                            </div>                           
                        </div>
                        </BrowserView>
                        
                        <MobileView>
                        <div>
                        <DataTable id="vaultEvents"
                                   value={this.state.dataTableValue} paginatorPosition="bottom"
                                   header={header} paginator={true} rows={10} responsive={true}
                                   globalFilter={this.state.globalFilter} emptyMessage="No records found"
                                   ref={(el) => { this.dt = el; }} style={{fontSize:'75%'}}                                   
                                   rowExpansionTemplate={this.rowExpansionTemplate} dataKey="evtId"
                                   rowClassName={this.rowClassName}>
                            <Column body={this.imageTemplate} style={{width: '4em', textAlign: 'left',margin:0,padding:0}}/> 
                            <Column field="eventDate" header="Event Date" style={{width:"15%"}} sortable="custom" sortFunction={this.onSortFunction}/>
                            <Column field="assetTag" header="Device ID" sortable={true} style={{width:"10%"}}/> 
                            <Column field="physicalLocation" header="Device Description" sortable={true}/> 
                            <Column field="triggeredBy" header="Triggered By" sortable={true}/>
                            <Column body={this.detailTemplate} header="Event Detail" sortable={true}/>
                        </DataTable>                        
                        </div>
                        </MobileView>
                        <BrowserView>
                            <div>                        
                                <DataTable id="vaultEvents"
                                        value={this.state.dataTableValue} paginatorPosition="bottom"
                                        header={header} paginator={true} rows={20} responsive={true}
                                        selection={this.state.dataTableSelection} selectionMode="single"
                                        resizableColumns={true} globalFilter={this.state.globalFilter} emptyMessage="No records found"
                                        ref={(el) => { this.dt = el; }} autoLayout dataKey="evtId" rowClassName={this.rowClassName}>                                                                                  
                                    <Column body={this.imageTemplate} style={{width: '4em', textAlign: 'left',margin:0,padding:0}}/>                            
                                    <Column field="eventDate" header="Event Date" style={{width:"15%",textAlign:"center"}} sortable="custom" sortFunction={this.onSortFunction} />
                                    <Column field="assetTag" header="Device ID" sortable={true} headerStyle={{textAlign:"left"}} style={{width:"10%"}}/>
                                    <Column field="physicalLocation" header="Device Description" headerStyle={{textAlign:"left"}} sortable={true}/>                            
                                    <Column field="triggeredBy" header="Triggered By" sortable={true} headerStyle={{textAlign:"left"}}/>
                                    <Column body={this.detailTemplate} header="Event Detail" sortable={true} headerStyle={{textAlign:"left"}}/>
                                </DataTable>                        
                            </div>
                        </BrowserView>

                    </div>
                    

                    
                </div>
            </div>
        );
    }
}