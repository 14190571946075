import React, {Component} from 'react';
import $ from "jquery";
import orgchart from "orgchart";
import './jquery.orgchart.min.css';
import './JSONLoop';
import {vaultService} from '../services/VaultServices';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Growl} from 'primereact/growl';
import {Button} from 'primereact/button';
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';


const _ = deepdash(lodash);
var oc = null;

export class VisualDomainManagementTool extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            domains: [],
            dirty: false,            
            featureList: ""            
        };    
        this.orgchart = React.createRef(); 
    }

    componentDidMount() {
        let user = sessionStorage.getItem("user");
        if (user) {
                    //console.log(user);
                    let x = JSON.parse(user);
                    this.setState(() => ({
                    userId: x.userId,
                    token: x.token,
                    orgId: x.orgId,
                    domainId: x.domainId,
                    featureList: x.featureList
                  }));
                  if (!x.acceptedEula || x.userRole !== 1){
                    this.props.handleLogout();
                  } else{
                    this.setState({ loading: true });
                    vaultService.fetchDomainsForVisualTool(x.userId, x.token)
                                .then(data => this.setState(
                                                            {domains: data.itemResult, loading:false},
                                                            () => {this.buildTheOutput(this.state.domains)}
                                                            ));  
                                                        }                       
                /* =============================================== */
                    this.$el = $(this.el);                                                         
                /* =============================================== */
                               

        }  
    }

    componentWillUnmount() {
        this.$el.empty();
    }
    
    onCommit = () => {
       var dataset = [oc.getHierarchy()];
 
/*
        const filterList = [
            'id',
            'parent_id'
        ];
        // filter by name
        let arr = _.filterDeep( dataset,
            (item, key, parentVal, ctx) => {
            return (_.includes(filterList, key) ||
                (ctx.parent &&
                _.includes(filterList, _.get(ctx, 'parent.parent.parent.value.name')))
            );
            },
            { tree: { children: 'items' } }
        );
        // remove empty 'child'
        arr = _.filterDeep( arr,
            (val, key) => {
            return key !== 'child' || (val !== null && val.items.length > 0);
            },
            { leavesOnly: false, onTrue: { skipChildren: false } }
        );
        //    console.log(arr)
*/

       this.setState({ loading: true, dirty: true });
       try {
            vaultService.processDomainStructureChange(dataset)
                   .then(data => this.setState({loading:false}));     
            this.growl.show({severity: 'success', summary: 'Success Message', detail: 'Changes Committed to Database.'}); 
        }
        catch (e) {
             // alert(e);                    
            this.setState({loading: false,
                           dirty: false});
            this.growl.show({severity: 'error', summary: 'Error Message', detail: 'Critical error: ' + e});              
        }  
        
        
    }

    onReset = () => {
        oc.init({'data': this.state.datasource});   
        this.setState({dirty: false});     
        this.growl.show({severity: 'success', summary: 'Success Message', detail: 'Changes Discarded.'}); 
    }

    buildTheOutput = (obj) => {  
        
        let domainArr = obj.map(function(elem) {           
            return {
                id: elem.id,
                name: elem.name,
                parent_id: parseInt(elem.parentId)
            };
        });         
        var dataset = domainArr;
        //   console.log(dataset)
        let datasource = {};
        
        dataset.forEach(function(item, index) {
            // console.log(item)
            if (isNaN(item.parent_id)) {                
                delete item.parent_id;
                Object.assign(datasource, item);        
            } else {
              let jsonloop = new window.JSONLoop(datasource, 'id', 'children');
            //   console.log("here...", jsonloop)
              jsonloop.findNodeById(datasource, item.parent_id, function(err, node) {
                if (err) {
                  console.error(err);
                } else {
                  delete item.parent_id;
                  if (node.children) {
                    node.children.push(item);
                  } else {
                    node.children = [ item ];
                  }
                }
              });
            }
        });
        //this.setState({datasource: datasource});        

        oc =  this.$el.orgchart({
                    'data' : datasource,
                    'nodeId': 'id',
                    'draggable': 'true',
                    'parentNodeSymbol': ''                  
                });                         
              
        //console.log(datasource)     
        // return datasource;

        if(datasource.relationship === '000'){
            this.setState({tbd: "Only available for Corporate level at this time",
                           dirty: true});
        }

    }

    processDrop = () => {
        this.setState({dirty: false})
        let x = oc.$chart.data("dropZone");

        //console.log(x);
        
    }
         
/* =========================================================================================== */
    render() {
                      
        const { loading, dirty,tbd } = this.state;

        return (<div>
                    <div className="p-grid" style={{textAlign:'center'}}>                       
                        {
							    loading && <ProgressSpinner/> 
						}
                        
                        <Growl ref={(el) => this.growl = el} />
                        <div className="p-col" style={{marginTop:'1em'}}>                                   
                                <Button id="domainConfirmed"
                                    type="button" icon="pi pi-check"
                                    style={{margin:'2px'}}                               
                                    className="p-button-raised p-button-rounded p-button-success"                                            
                                    label="Commit"
                                    disabled={tbd}
                                    onClick={() => this.onCommit()}>
                                </Button>  
                                <Button id="domainReset"
                                    type="button" icon="pi pi-undo"
                                    style={{margin:'2px'}}                               
                                    className="p-button-raised p-button-rounded p-button-info"                                            
                                    label="Reset"
                                    disabled={dirty}
                                    onClick={() => this.onReset()}>
                                </Button>  
                        </div>                                                              
                    </div>  
                    <div style={{textAlign:"center"}}>
                        {tbd &&
                            <div>{tbd}</div>
                        }
                        
                        <orgchart ref={this.orgchart}></orgchart>                        
                        <div id="chart-container" ref={el => this.el = el}  onDrop={() => this.processDrop()}></div>             
                    </div>                     
                </div>
        );
    }
}