import React, {Component} from 'react';
import {vaultService} from '../services/VaultServices';
import QrReader from 'react-qr-reader';
import {Dialog} from 'primereact/dialog';
import {ProgressSpinner} from 'primereact/progressspinner';
import {isMobile} from 'react-device-detect';
import './QRScan.css';

export class QRScan extends Component {
    constructor (props) {
        super(props)
    
        this.state = {   
          userId: "",
          token: "",
          loading: false,
          error: null,
          errorInfo: null,
          disabled: false,
          open: false,
          vaultId: "",
          result: "",
          delay: 100,
          bgColor: "",      
          cme: "",
          pulser: false,
          tempCodeJustification: null
        }
        this.handleScan = this.handleScan.bind(this);    
      }
    
      componentDidMount() {
        let user = sessionStorage.getItem("user");
          if (user) {
           // console.log(user);
            let x = JSON.parse(user);
            this.setState(() => ({
              userId: x.userId,
              token: x.token,
              orgId: x.orgId
            }));
            if (!x.acceptedEula || x.userRole === 3){
              this.props.handleLogout();
            }
          }          
      }
    
      handleClose = () => {        
        this.setState({displayDialogFetchTempCode: false});
        this.props.history.push('/vaultadmin');
      };

      handleScan(data){
        if(data) {
          this.setState({
            result: data,
            bgColor: '#42f453',        
            cme: "hidden",
            pulser: "visible",
          })          
          this.onData();
        };
      };
     
      handleError = () => {
        let isError = false;
        
        if (this.state.result < 2) {
          isError = true;
          this.setState({
              error : "Invalid Vault Id"
          });
        }    
        return isError;
      };
    
      reloadMe = () => {
        this.setState({result:null,bgColor:""});
        
      };
    
      onData = async () => {    
        
        const separator = ':';
        let tempCodeJustify = 'Vault scanned by user';      
        this.setState({vaultId: this.state.result.split(separator).shift(), tempCodeJustification: tempCodeJustify,vaultIdError:""}); 

        //fetch data - ferret out the arrows - output the results//
        let whichCode = 0;        
        let data = await vaultService.fetchTempAccessCodeFromScan(this.state.vaultId , tempCodeJustify
                                                                        , this.state.userId
                                                                        , this.state.token
                                                                        , null
                                                                        , whichCode);
                                                                    
        
        let vaultData = data.data;
        let y = null;
        //console.log(vaultData);
        let display = vaultData["tempCode"];
            // console.log(display)
        let arr = this.getArrows(display);
            
        if(isMobile){
            y = arr.map(code => (
                <div style={{display: "inline-block"}}>
                    {code === 'R' ?
                    (<i class="pi pi-arrow-right" style={{'fontSize': '1.5em'}}></i>)
                    : code === 'L' ?
                    (<i class="pi pi-arrow-left" style={{'fontSize': '1.5em'}}></i>)
                    : code === 'U' ?
                    (<i class="pi pi-arrow-up" style={{'fontSize': '1.5em'}}></i>)
                    : code === 'D' ?         
                    (<i class="pi pi-arrow-down" style={{'fontSize': '1.5em'}}></i>)
                    : <span>{code}</span>
                    }
                </div>
                )
            );
        } else {
            y = arr.map(code => (
                <div style={{display: "inline-block"}}>
                    {code === 'R' ?
                    (<i class="pi pi-arrow-right" style={{'fontSize': '3em'}}></i>)
                    : code === 'L' ?
                    (<i class="pi pi-arrow-left" style={{'fontSize': '3em'}}></i>)
                    : code === 'U' ?
                    (<i class="pi pi-arrow-up" style={{'fontSize': '3em'}}></i>)
                    : code === 'D' ?         
                    (<i class="pi pi-arrow-down" style={{'fontSize': '3em'}}></i>)
                    : <span>{code}</span>
                    }
                </div>
                )
                );   
        }
            
        this.setState({displayDialogFetchTempCode:true,
                        loading: false,
                        tempCode: y});
            
            //console.log(y)
                           
    };
    getArrows = (theVal) => {
  
      var s = theVal.split('');
        for (var i = 0; i < s.length; i++) {
            switch(s[i]) {
                case 'R':            
                s[i] = "R";
                break;
            case 'L':     
                s[i] = "L";
                break;
            case 'U':
                s[i] = "U";
                break;
            case 'D':
                s[i] = "D";
                break;
            default:
                break;
            }      
        }
        var x = s.join('');
        var result = this.flatMap(x, function (part) {
          return [part];
        });
    
        return result ;
    }
    flatMap(array, fn) {
      var result = [];
      for (var i = 0; i < array.length; i++) {
        var mapping = fn(array[i]);
        result = result.concat(mapping);
      }
      return result;
    } 
    
/* =========================================================================================== */      
    render() {
        // const { classes } = this.props; 
    const { loading, tempCode, result } = this.state;     
/* =========================================================================================== */
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">

                        {result ?
                        (
                            <div>
                            <Dialog visible={this.state.displayDialogFetchTempCode}
                                    modal={true} header={<span><i className="pi pi-unlock"/>Temp Code</span>}
                                    responsive={true} style={{width:'90%'}}
                                    onHide={() => this.handleClose()}>                                                   
                                {
                                        loading && <ProgressSpinner/> 
                                }
                                <div className="p-col-12" style={{padding: '2em', borderBottom: '1px solid #d9d9d9'}}>                                       
                                    {tempCode}
                                </div>                    
                            </Dialog>                    
                            </div>
                        )      
                        :
                        (           
                        <div>          
                        <QrReader
                            delay={this.state.delay}
                            facingMode={"environment"}
                            style={{ width: '100%', height: "100%", objectFit: "fill", padding: '0',zIndex: 1}}
                            onError={this.handleError}
                            onScan={this.handleScan}                            
                        />          
                        </div>               
                        )} 
                    </div>
                </div>
            </div>
        );
    }
}