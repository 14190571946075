import React, {Component} from 'react';
import { ScrollPanel } from 'primereact/scrollpanel';
import {Button} from 'primereact/button';
import {vaultService} from '../services/VaultServices';

export class Eula extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            dirty: false,
            acceptedEula: false,
            mobileOnly: false,
            logo: '/assets/images/appIcon.png',
            slogan: '/assets/images/slogan.png'            
        };       
    }            
    
    componentDidMount() {
        let user = sessionStorage.getItem("user");
        if (user) {
                    // console.log(user);
                    let x = JSON.parse(user);
                    this.setState(() => ({
                    userId: x.userId,
                    token: x.token,
                    orgId: x.orgId,                  
                    acceptedEula: x.acceptedEula,
                    mobileOnly: x.userRole === 3 ? true : false
                  }));
                     

                    // //console.log('tok',x.orgId);
                    // this.setState({ loading: true });
                    // vaultService.fetchVaultDomains(x.userId, x.token).then(data => this.setState({dataTableValue: data.itemResult,
                    //                                                                      totalRecords: data.itemResult.length}));                                             
                    // this.setState({loading: false});   
                    }  
    }   

    componentWillMount() {
        // console.log("WINDOW : ",window);
          this.setState({height: window.innerHeight - 140 + 'px',width:window.innerWidth+'px'});

	}
    
    userAccepted = async (ind) => {
        if(ind){
            let data = await vaultService.acceptedEula(this.state.userId);
            let user = JSON.parse(sessionStorage.getItem("user")); 
            user.acceptedEula = 1;
            sessionStorage.setItem('user', JSON.stringify(user));  
            if (user.userRole === 3){
                this.props.history.push("/Downloads");
            }  else {
                this.props.history.push("/Dashboard");
            }                 
           
        } else {
            this.props.handleLogout();
        }
        
    }    

/*==============================================================*/
    render() {
        
        if(this.state.acceptedEula){
         if (this.state.mobileOnly){
            this.props.history.push("/Downloads");
         } else { 
            this.props.history.push("/Dashboard");
         }
        }

        return (
                <div className="card">
                    <div className="p-grid">
                        <div className="p-col-12">    
                        <div className="p-col-12" style={{borderBottom: "2px red solid", width:"100%", height:"75px"}}>                    
                        <div style={{float:"left",width:"204px"}}>
                             <img src={this.state.logo}
                                  width="200px"
                                  height="66px"
                                  alt="logo"/></div>
                        <div style={{float:"right", paddingRight:"25px"}}>
                        <img src={this.state.slogan}
                                  width="200px"
                                  height="66px"
                                  alt="logo"/>
                        </div>
                        </div>
                        
                            <ScrollPanel style={{ width: '100%', height: this.state.height}}>
                                <div style={{ padding: '1em', lineHeight: '1.5' }}>
                                    <h1 style={{textAlign:"center"}}>MicroKeyVault EULA and Terms of Service<br/><span style={{fontSize:"70%"}}>Revised 6/25/2020</span></h1>
                                                                        
<p>
<strong>Acceptance of Terms</strong><br/><br/>
MicroKeyVault is an online key storage management service (the "Service") provided by Midwest Security Products, Inc. (MWSPI). 
This service is provided to active End Users (“the End User”) of MWSPI, subject to the following End User License Agreement (“EULA”) and Terms of Service ("TOS").
</p>
<p>
Upon initial login to MicroKeyVault, or whenever the EULA or TOS change, the End User is required to accept these terms in order to access the software.
</p>
<p>
This EULA ends whenever End User access to MicroKeyVault is terminated by MWSPI.
</p>
<p>
The most current version of the "TOS” is available for your review by clicking on the link located on the MicroKeyVault website home page. 
You are responsible for checking these terms periodically for changes.
</p>
<p>
Master Lock LLC, as a software SDK licensor of MWSPI, retains exclusive ownership of the Master Lock SDK Materials, 
Master Lock Data and Master Lock Platform and all Intellectual Property Rights therein and to any Master Lock Materials.
</p>
<p>
<strong>Description of Service</strong></p>
<p>
All software and web sites related to the software is the property of MWSPI. The functionality and information displayed is controlled by MWSPI 
and may be altered from time to time at our discretion. Access is offered in an “AS IS” condition. All data of the End User is the property of 
the End User and is available upon request by authorized personnel.
</p>
<p>
<strong>End User Account, Password and Security</strong>
</p>
<p>
As a selected End User with access rights to Enterprise.Microkeyvault.com, you will receive a password and account designation. 
You are responsible for maintaining the confidentiality of the password and agree to (a) immediately notify MWSPI of any unauthorized 
use of your password or account or any other breach of security, and (b) ensure that you exit from your account at the end of each session.
MWSPI cannot and will not be liable for any loss or damage arising from your failure to comply with this section.
</p>
<p>
You acknowledge and agree that MWSPI may preserve content and may also disclose content if required to do so by law or in the good faith belief that such preservation or disclosure is reasonably necessary to: (a) comply with legal process; (b) enforce the TOS; (c) respond to claims that any content violates the rights of third-parties.
</p>
<p>
You understand that the technical processing and transmission of the Service, including your content, may involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices.
</p>
<p>
<strong>Links</strong>
</p>
<p>
The Service may provide, or third parties may provide, links to other World Wide Web sites or resources. Because MWSPI has no control over such sites and 
resources, you acknowledge and agree that MWSPI is not responsible for the availability of advertising, products, other materials on or available from 
such sites or resources. You further acknowledge and agree that MWSPI shall not be responsible or liable, directly or indirectly, for any damage or loss caused by or in connection with use of or reliance on any such content, goods or services available on or through any such site or resource.
</p>
<p>
<strong>Limitation of Liability</strong>
</p>
<p>
Under no circumstances will MWSPI or Master Lock  or any of its affiliates be liable to End Users for any special, indirect, incidental, consequential, 
punitive, or exemplary damages, including any claims for business interruption, lost profits, loss of use or data, diminution of value, or lost 
business opportunities, in connection  with this agreement, however caused and whatever theory of liability (including negligence), 
even if the End User has been advised of the possibility of such damages, and 
</p>
<p>
To the maximum extent permitted by law, MWSPI and Master Lock’s total liability under this agreement, for whatever cause, whether in an action in contract or in tort or otherwise, will be limited to direct money damages and will in no event exceed an amount equal to the greater of (a) the aggregate of all fees actually paid or owing under this agreement by the End User to MWSPI during the twelve month period preceding the date of the event giving rise to the claim or (b) one hundred dollars ($100).
</p>
<p>
<strong>Master Lock and MWSPI Warranties and limitations</strong>
</p>
<p>
Each party (Master Lock, MWSPI, and End User) represents and warrants to the other party : (a) such party has the full and unencumbered right, 
power, and authority to enter into this Agreement and to otherwise carry out its obligations under this Agreement; (b) this Agreement 
constitutes the legal, valid, and binding obligation of such Party, enforceable against it in accordance with its terms; and (c) 
the execution, delivery, or performance of this Agreement does not violate, conflict with, or constitute a default under any agreement 
or instrument to which it is a party or by which it is otherwise bound.
</p>
<p>
The warranties set forth in this section are the sole warranties provided by Master Lock in connection with the Master Lock materials, 
including the Master Lock platform and Master Lock SDK materials. Master Lock is providing the materials, including the Master Lock 
platform and Master Lock SDK materials on an “as is” and “as available” basis without warranty of any kind. to the maximum extent permitted 
by applicable law, master lock expressly disclaims all other representations, warranties, conditions, and guaranties of any kind, 
either express or implied, oral or written, with respect to this agreement, the Master Lock materials, Master Lock platform, 
Master Lock SDK materials or any other items or services covered by or furnished under this agreement, including all implied warranties
of merchantability, fitness for a particular purpose, non-infringement, or arising from course of performance, course of dealing, 
or usage of trade. Master Lock does not warrant that the Master Lock materials, including the Master Lock platform or Master Lock SDK 
materials, will be uninterrupted or error free, be compatible or work with any hardware, software, device, system or services, or be 
secure, accurate, complete, free of any virus, malware or other harmful code and makes no warranties as to the availability of the Master 
lock platform, End User’s ability to access the master lock platform, or any loss, damage or unauthorized changes to any company data.
 </p>
<p>
<strong>Acceptable Use</strong>
</p>
<p>
This agreement prohibits the End User from, and allows MWSPI and Master Lock to suspend provision of the Master Lock Platform for: 
(i) using the Master Lock Materials, including Master Lock Platform, for any illegal purpose or in violation of any applicable law or 
regulation, (ii) participating in a denial-of-service attack on the Master Lock Platform, (iii) accessing the Master Lock Platform 
in a fraudulent manner, including by using any falsified device identifier; (iv) renting, leasing, or reselling its access to the 
Master Lock Platform; (v) circumventing, disabling, or impairing any feature of the Master Lock Materials, including the Master Lock 
SDK Materials or Master Lock Platform, that provides or enhances security, restricts access, protects privacy, or monitors use; 
(vi) materially disrupting or impairing the operation of the Master Lock Materials, including the Master Lock Platform, the Master 
Lock Products, or service to others by any means including introduction of malicious code; or (vii) attempting to do, or assisting 
any third party to do, any of the foregoing.
</p>
<p>
The End User grants Master Lock a perpetual, irrevocable, worldwide, sub licensable, royalty-free, and non-exclusive license to use 
Master Lock API Data, excluding device location data, to provide the services of the Master Lock Platform and in connection with the 
operation of the Master Lock Platform. Master Lock may collect, store, process, use and disclose to third parties the Master Lock API Data, 
excluding device location data, on an aggregated, anonymized basis and in a manner that does not identify Company or 
End Users (“Anonymous Company Data”) and use such Anonymous Company Data to improve or enhance the Master Lock Platform 
or Master Lock SDK Materials.  Master Lock will not use “Anonymous Company Data” for any other purpose, including commercial 
purposes, without prior approval from the End User.  
</p>
<p>
    <strong>Use Restrictions</strong>
</p>
<p>
The End User shall not do any of the following:
<ul>
    <li>Reverse engineer, decompile, disassemble, or attempt to derive source code or any other type of information from the 
        Master Lock Materials, including the Master Lock SDK Materials or Master Lock Platform, except as explicitly provided in this 
        Agreement or instructed in the Master Lock SDK Materials</li>
    <li>Generate an inordinate number of lock codes beyond what is reasonably required or for later use after termination of this Agreement</li>
</ul>
</p>
<p>
Master Lock is a third-party beneficiary of this EULA entitling Master Lock to enforce provisions against the End User in respect of 
the Master Lock Materials, including the Master Lock SDK Materials and Master Lock Platform.
</p>
<p>
Unless explicitly stated otherwise, any new features that augment or enhance the current Service shall be subject to the TOS. 
In order to use the Service you must obtain access to the World Wide Web and pay any service fees associated with such access. 
In addition, you must provide all equipment necessary to make such connection to the World Wide Web, including a computer and modem or 
other access device.
</p>
<div style={{textAlign:"center",marginBottom:"10px"}}>
<span style={{marginRight:"25px"}}>
<Button id="userAdd"
    type="button" icon="pi pi-times"
    style={{margin:'2px'}}                               
    className="p-button-raised p-button-rounded p-button-danger"
    tooltip="Refuse terms"
    tooltipOptions={{position: 'bottom'}}
    label="I Do Not Accept"
    onClick={() => this.userAccepted(0)}>
</Button>  
</span>
<Button id="userAdd"
    type="button" icon="pi pi-check"
    style={{margin:'2px'}}                               
    className="p-button-raised p-button-rounded p-button-success"
    tooltip="Accept terms"
    label="I Accept"
    onClick={() => this.userAccepted(1)}>
</Button>  


</div>
                            </div>
                            </ScrollPanel>
                            
                        </div>
                    </div>
                </div>            
        );
    }
}