import React from "react";
import { Route, Redirect } from "react-router-dom";
import Mkve from "../app/Mkve";
// import {AppFooter} from '../app/AppFooter';

export default ({ component: C, props: cProps, ...rest }) =>
   
   <Route
    {...rest}
    render={props =>
      cProps.isAuthenticated
        ? <div>            
            <Mkve childProps={cProps} />
            <C {...props} {...cProps} />
            {/* <AppFooter /> */}
          </div>
        : <Redirect
            to={`/login?redirect=${props.location.pathname}${props.location
              .search}`}
          />}
  />;
