import { config } from './config';
import axios from 'axios';
// import moment from 'moment';

export const vaultService = {
    fetchVault,
    fetchVaults,
    processVaultAction,
    fetchVaultDomains,
    addVaultDomain,
    editVaultDomain,
    deleteVaultDomain,
    processVaultsToDomainsAssignment,
    fetchVaultUsers,
    FetchUserToDomains,
    DomainBuildForDropDown,
    DomainBuildForExport,
    processVaultUserAdminUsersAction_Add,
    processVaultUserAdminUsersAction_Edit,
    processVaultUserAdminUsersAction_Delete,
    fetchDomainsToVault,
    buildVaultToDomainChoices,
    fetchVaultToDomainExistingAssignments,
    fetchTempAccessCode,
    fetchUserVaultAssignments,
    processUserToVaultAssignment,
    buildUserToVaultChoices,
    fetchVaultToUserExistingAssignments,
    fetchVaultEvents,
    fetchUserEvents,
    fetchUserMenu,
    fetchDashboardTotals,
    fetchTop10VaultEvents,
    fetchTempAccessCodeFromScan,
    fetchDomainsForVisualTool,
    processDomainStructureChange,
    acceptedEula
};

/*==========================================================================*/
async function fetchVault(thisVault) {
        
    
    let x = JSON.parse(sessionStorage.getItem("user"));
    //console.log("in fetch", x.orgId);
    try {
        const response = await axios({
                                        method: 'post',
                                        headers: {  'mode':'cors',
                                                    'Access-Control-Allow-Origin': "*",
                                                    'Authorization': 'Bearer ' + x.token},
                                        url: config.apiUrl + '/VaultDomain/fetchVault',
                                        data: {                                            
                                            domainId: x.domainId,
                                            userId: x.userId,
                                            orgId: x.orgId,
                                            vaultId: thisVault
                                        }
                                    });
        
        //console.log([response.data.itemResult , response.data.itemResult.length, domainList]);
        // console.log(response.data.itemResult[0]);
        return (response.data.itemResult[0]);
    } catch (error) {
        return false
    }
}
/*==========================================================================*/


/*==========================================================================*/
async function fetchVaults(domainId, token, userId) {
        
    
    let x = JSON.parse(sessionStorage.getItem("user"));
    //console.log("in fetch", x.orgId);
    try {
        const response = await axios({
                                        method: 'post',
                                        headers: {  'mode':'cors',
                                                    'Access-Control-Allow-Origin': "*",
                                                    'Authorization': 'Bearer ' + token},
                                        url: config.apiUrl + '/VaultDomain/fetchVaults',
                                        data: {                                            
                                            domainId: domainId,
                                            userId: userId,
                                            orgId: x.orgId
                                        }
                                    });
        
        const domainList = await fetchDomainsToVault(response.data.itemResult, userId, token);
        
        //console.log([response.data.itemResult , response.data.itemResult.length, domainList]);
        return ([response.data.itemResult , response.data.itemResult.length, domainList])
    } catch (error) {
        return false
    }
}
/*==========================================================================*/
async function fetchVaultDomains(userId, token) {
        
    // //console.log(domainId);
    let x = JSON.parse(sessionStorage.getItem("user"));
    try {
        const response = await axios({
                                        method: 'post',
                                        headers: {  'mode':'cors',
                                                    'Access-Control-Allow-Origin': "*",
                                                    'Authorization': 'Bearer ' + token},
                                        url: config.apiUrl + '/VaultDomain/fetchVaultDomains',
                                        data: {
                                                userId: userId,
                                                orgId: x.orgId
                                        }
                                    });
        // //console.log(response)
        
        return response.data                
    } catch (error) {
        return false

    }

}
/*==========================================================================*/
async function processVaultAction(inboundVars, action, userId) {
        
    //  console.log("in process",inboundVars);
    
    let x = JSON.parse(sessionStorage.getItem("user"));
    
    if (action ===   'edit'){
        
        try {
            const response = await axios({
                                            method: 'post',
                                            headers: {  'mode':'cors',
                                                        'Access-Control-Allow-Origin': "*",
                                                        'Authorization': 'Bearer ' + x.token},
                                            url: config.apiUrl + '/VaultDomain/processVaultAction',
                                            data: {                                                    
                                                    assetTag: inboundVars.assetTag,
                                                    physicalLocation: inboundVars.vaultPhysicalLocation, 
                                                    primaryCodeDescription: inboundVars.primaryCodeDescription,
                                                    primaryCode: inboundVars.pendingPrimaryCode,                                                  
                                                    code1Description: inboundVars.code1Description,
                                                    code1: inboundVars.pendingCode1,
                                                    code2Description: inboundVars.code2Description,
                                                    code2: inboundVars.pendingCode2,
                                                    code3Description: inboundVars.code3Description,
                                                    code3: inboundVars.pendingCode3,
                                                    code4Description: inboundVars.code4Description,
                                                    code4: inboundVars.pendingCode4,
                                                    code5Description: inboundVars.code5Description,
                                                    code5: inboundVars.pendingCode5,
                                                    userId: userId,
                                                    orgId: x.orgId,
                                                    pendingCode1Delete: inboundVars.pendingCode1Delete,
                                                    pendingCode2Delete: inboundVars.pendingCode2Delete,
                                                    pendingCode3Delete: inboundVars.pendingCode3Delete,
                                                    pendingCode4Delete: inboundVars.pendingCode4Delete,
                                                    pendingCode5Delete: inboundVars.pendingCode5Delete
                                            }
                                        });
            
        return fetchVaults("",x.token,userId);               
        } catch (error) {
            return false

        }        
     
    }
   
}
/*==========================================================================*/
async function addVaultDomain(token, domainName, domainDescription, userId) {
        
    let x = JSON.parse(sessionStorage.getItem("user"));
    try {
        const response = await axios({
                                        method: 'post',
                                        headers: {  'mode':'cors',
                                                    'Access-Control-Allow-Origin': "*",
                                                    'Authorization': 'Bearer ' + token},
                                        url: config.apiUrl + '/VaultDomain/addVaultDomain',
                                        data: {                                                                                                
                                                domain: domainName,
                                                description: domainDescription,
                                                userId: userId,
                                                orgId: x.orgId
                                        }
                                    });
       //console.log(response)
        return response.data               
    }catch (error) {
        // Error 😨
        if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            return error.response
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            //console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            //console.log('Error', error.message);
        }
        //console.log(error);
    }
}
/*==========================================================================*/
async function editVaultDomain(domainId, token, domainName, domainDescription, userId) {
        
   // //console.log(domainName, domainId);
   let x = JSON.parse(sessionStorage.getItem("user"));
    try {
        const response = await axios({
                                        method: 'post',
                                        headers: {  'mode':'cors',
                                                    'Access-Control-Allow-Origin': "*",
                                                    'Authorization': 'Bearer ' + token},
                                        url: config.apiUrl + '/VaultDomain/editVaultDomain',
                                        data: {                                                                                                
                                                domainId: domainId,
                                                domain: domainName,
                                                description: domainDescription,
                                                userId: userId,
                                                orgId: x.orgId
                                        }
                                    });
       //console.log(response)
        return response.data               
    }catch (error) {
        // Error 😨
        if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            return error.response
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            //console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            //console.log('Error', error.message);
        }
        //console.log(error);
    }
}
/*==========================================================================*/
async function deleteVaultDomain(domainId, token, userId) {
        
    //console.log("in delete", token);
    let x = JSON.parse(sessionStorage.getItem("user"));
    try {
        const response = await axios({
                                        method: 'post',
                                        headers: {  'mode':'cors',
                                                    'Access-Control-Allow-Origin': "*",
                                                    'Authorization': 'Bearer ' + token},
                                        url: config.apiUrl + '/VaultDomain/deleteVaultDomain',
                                        data: {
                                                 domainId: domainId
                                                ,userId: userId
                                                ,orgId: x.orgId
                                        }
                                    });
        //console.log("in svcs",response.data.rc)
        return response.data;              
    } catch (error) {
        return false

    }
}
/*==========================================================================*/
async function processVaultsToDomainsAssignment(domainAssignment, token, userId, vault ,orgId) {
   
    //console.log(domainAssignment.length);
    if (domainAssignment.length === 0){
        domainAssignment.push({domainId:0,vaultId:vault,userId:userId,orgId:orgId}); 
    }
  //console.log(domainAssignment);
  //let x = JSON.parse(sessionStorage.getItem("user"));
    try {
        await axios({
                                        method: 'post', 
                                        headers: {  'mode':'cors',
                                                    'Access-Control-Allow-Origin': "*",
                                                    'Authorization': 'Bearer ' + token},
                                        url: config.apiUrl + '/VaultDomain/processVaultsToDomainsAssignment',
                                        data: {
                                                 domainAssignment: domainAssignment
                                                                                               
                                        }
                                    });
       
        const results = await fetchVaults(0, token, userId);
        //console.log("andr:",results);
        return ([results[0] , results[1], results[2]])
        // return response.data;
    } catch (error) {
        return false

    }
}
/*==========================================================================*/
async function fetchVaultUsers(domainId, userId, token) {
        
    // //console.log("in fetch", userId, token, domainId);
    let x = JSON.parse(sessionStorage.getItem("user"));
    try {
        const response = await axios({
                                        method: 'post',
                                        headers: {  'mode':'cors',
                                                    'Access-Control-Allow-Origin': "*",
                                                    'Authorization': 'Bearer ' + token},
                                        url: config.apiUrl + '/VaultDomain/fetchVaultUsers',
                                        data: {                                            
                                            domainId: domainId,
                                            userId: userId,
                                            orgId: x.orgId
                                        }
                                    });
        //console.log(response.data)
        return response.data               
    } catch (error) {
        return false

    }

}
/*==========================================================================*/
async function processVaultUserAdminUsersAction_Edit(token, inboundVars, action, activeUserId, userDomains, noUserDomainChange, noUserValChange) {
    
    //console.log("in edit", inboundVars);
    
    let x = JSON.parse(sessionStorage.getItem("user"));
    let domains = null;
    if(userDomains !== null){
        domains = userDomains.map(item => {
            const container = {};

            container.userId = inboundVars.vaultUserId;
            container.domainId = item.value;

            return container;
        });
    }
   
    try {
        const [response] = await Promise.all([
            
                noUserValChange ?
                "" :
                axios({
                    method: 'post',
                    headers: {  'mode':'cors',
                                'Access-Control-Allow-Origin': "*",
                                'Authorization': 'Bearer ' + token},
                    url: config.apiUrl + '/VaultDomain/processVaultUserAdminUsersAction',
                    data: {
                            email: inboundVars.vaultUserEmail,
                            firstName: inboundVars.vaultUserFirstName,
                            lastName: inboundVars.vaultUserLastName,
                            statusId: inboundVars.vaultUserStatus,
                            userId: inboundVars.vaultUserId,
                            title:  inboundVars.vaultUserTitle,
                            domain: inboundVars.vaultUserDomain,
                            department: inboundVars.vaultUserDept,
                            address1: inboundVars.vaultUserAddress1,
                            address2: inboundVars.vaultUserAddress2,
                            city: inboundVars.vaultUserCity,
                            state: inboundVars.vaultUserState,
                            postalCode: inboundVars.vaultUserPostalCode,
                            landline: inboundVars.vaultUserLandline,
                            mobilePhone: inboundVars.vaultUserMobilePhone,
                            startDate: inboundVars.vaultUserStartDate,
                            endDate: inboundVars.vaultUserEndDate,
                            password: inboundVars.vaultUserPassword,
                            action: action,
                            activeUserId: activeUserId,
                            login: inboundVars.vaultUserEmail,                           
                            orgId: x.orgId,
                            notes: inboundVars.vaultUserNotes,
                            userRole: inboundVars.vaultUserRole,
                            audithelper: inboundVars.vaultAuditHelper
                    }
                }),
                noUserDomainChange ?   
                          "" :
                axios({
                    method: 'post',
                    headers: {  'mode':'cors',
                                'Access-Control-Allow-Origin': "*",
                                'Authorization': 'Bearer ' + token},
                    url: config.apiUrl + '/VaultDomain/processUserDomainAssignments',
                    data: {                    
                            userDomains: domains,                    
                            action: action,
                            activeUserId: activeUserId,
                            orgId: x.orgId
                    }
                })               
        ]);
        return response.data
    } catch (error) {       
        return false
    }
}
/*==========================================================================*/
async function FetchUserToDomains(userId, token) {
        
    // //console.log(domainId);
    let x = JSON.parse(sessionStorage.getItem("user"));
    try {
        const response = await axios({
                                        method: 'post',
                                        headers: {  'mode':'cors',
                                                    'Access-Control-Allow-Origin': "*",
                                                    'Authorization': 'Bearer ' + token},
                                        url: config.apiUrl + '/VaultDomain/fetchUserToDomains',
                                        data: {
                                                userId: userId,
                                                orgId: x.orgId
                                        }
                                    });
        //console.log(response)
        
       //var data = DomainBuild(response.data);    
       //console.log('and back...',data);    
       return DomainBuildForDropDown(response.data);               
    } catch (error) {
        return false

    }

}
/*==========================================================================*/
async function DomainBuildForDropDown(data) {
        
    let workingModel = Array.from(data.itemResult);
    
    let domains = workingModel.map(item => {
        const container = {};

        container.label = item.domain + '-' + item.description;
        container.value = item.domainId;

        return container;
    })
    //console.log(domains);
    return domains             
  
  

}
/*==========================================================================*/
async function DomainBuildForExport(userId, domainList) {
          
    let domains = domainList.map(item => {
        const container = {};

        container.userId = userId;
        container.domainId = item.value;

        return container;
    })
   // //console.log(domains);
    return domains
  
  

}
/*==========================================================================*/
async function processVaultUserAdminUsersAction_Add(token, inboundVars, action, activeUserId, userDomains) {     
  
    // console.log(inboundVars);

    //1st create the user fetching the generated id
   let x = JSON.parse(sessionStorage.getItem("user"));
   try {
      
    const response = await axios({
                                method: 'post',
                                headers: {  'mode':'cors',
                                            'Access-Control-Allow-Origin': "*",
                                            'Authorization': 'Bearer ' + token},
                                url: config.apiUrl + '/VaultDomain/processVaultUserAdminUsersAction',
                                data: {
                                        email: inboundVars.vaultUserEmail,
                                        firstName: inboundVars.vaultUserFirstName,
                                        lastName: inboundVars.vaultUserLastName,
                                        statusId: inboundVars.vaultUserStatus,
                                        userId: 0,
                                        title:  inboundVars.vaultUserTitle,
                                        domain: inboundVars.vaultUserDomain,
                                        department: inboundVars.vaultUserDept,
                                        address1: inboundVars.vaultUserAddress1,
                                        address2: inboundVars.vaultUserAddress2,
                                        city: inboundVars.vaultUserCity,
                                        state: inboundVars.vaultUserState,
                                        postalCode: inboundVars.vaultUserPostalCode,
                                        landline: inboundVars.vaultUserLandline,
                                        mobilePhone: inboundVars.vaultUserMobilePhone,
                                        startDate: inboundVars.vaultUserStartDate,
                                        endDate: inboundVars.vaultUserEndDate,
                                        password: inboundVars.vaultUserPassword,
                                        action: action,
                                        activeUserId: activeUserId,
                                        login: inboundVars.vaultUserEmail,
                                        orgId: x.orgId,
                                        userRole: inboundVars.vaultUserRole,                                        
                                        audithelper: inboundVars.vaultAuditHelper
                                }
                            });

        //newUserId = response.data               
        
        // console.log(response.data.returnCode)

        

        //now let's pop the domainList & call it a day        

        let domains = userDomains.map(item => {
            const container = {};
            container.userId = response.data.returnCode;
            container.domainId = item.value;
            return container;
        });
   
        // console.log(domains)

        await axios({
                    method: 'post',
                    headers: {  'mode':'cors',
                                'Access-Control-Allow-Origin': "*",
                                'Authorization': 'Bearer ' + token},
                    url: config.apiUrl + '/VaultDomain/processUserDomainAssignments',
                    data: {                    
                            userDomains: domains,                    
                            action: action,
                            activeUserId: activeUserId,
                            orgId: x.orgId
                    }
                });
        //return newUserId 
        //return response.data
       return true

    } catch (error) {
        // Error 😨
        console.log(error);
        return error;
        // if (error.response) {
        //     /*
        //      * The request was made and the server responded with a
        //      * status code that falls out of the range of 2xx
        //      */
        //     // console.log(error.response.data);
        //     // console.log(error.response.status);
        //     // console.log(error.response.headers);
        //     return error.response
        // } else if (error.request) {
        //     /*
        //      * The request was made but no response was received, `error.request`
        //      * is an instance of XMLHttpRequest in the browser and an instance
        //      * of http.ClientRequest in Node.js
        //      */
        //     // console.log(error.request);
        // } else {
        //     // Something happened in setting up the request and triggered an Error
        //     //console.log('Error', error.message);
        // }
        
    }
}
/*==========================================================================*/
async function processVaultUserAdminUsersAction_Delete(token, inboundVars, action, activeUserId) {
        
    // //console.log("in delete");

    // console.log(inboundVars, activeUserId);
    
    let x = JSON.parse(sessionStorage.getItem("user"));
    try {
        const response = await axios({            
                    method: 'post',
                    headers: {  'mode':'cors',
                                'Access-Control-Allow-Origin': "*",
                                'Authorization': 'Bearer ' + token},
                    url: config.apiUrl + '/VaultDomain/processVaultUserAdminUsersAction',
                    data: {
                            email: inboundVars.vaultUserEmail,
                            firstName: inboundVars.vaultUserFirstName,
                            lastName: inboundVars.vaultUserLastName,
                            statusId: inboundVars.vaultUserStatus,
                            userId: inboundVars.vaultUserId,
                            title:  inboundVars.vaultUserTitle,
                            domain: inboundVars.vaultUserDomain,
                            department: inboundVars.vaultUserDept,
                            address1: inboundVars.vaultUserAddress1,
                            address2: inboundVars.vaultUserAddress2,
                            city: inboundVars.vaultUserCity,
                            state: inboundVars.vaultUserState,
                            postalCode: inboundVars.vaultUserPostalCode,
                            landline: inboundVars.vaultUserLandline,
                            mobilePhone: inboundVars.vaultUserMobilePhone,
                            startDate: inboundVars.vaultUserStartDate,
                            endDate: inboundVars.vaultUserEndDate,
                            password: inboundVars.vaultUserPassword,
                            action: action,
                            activeUserId: activeUserId,
                            login: inboundVars.vaultUserEmail,
                            orgId: x.orgId,
                            userRole: inboundVars.vaultUserRole,
                            audithelper: inboundVars.vaultAuditHelper
                    }
               
                });
        return response.data           
    } catch (error) {
        // Error 😨
        if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            return error.response
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            //console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            //console.log('Error', error.message);
        }
        //console.log(error);
    }
}
/*==========================================================================*/
async function fetchDomainsToVault(vaultList, userId, token) {
        
   //console.log("in fetch", vaultList, userId, token);
   let x = JSON.parse(sessionStorage.getItem("user"));
    try {
       

        const vaults = vaultList.map(item => {
            const container = {};
        
            container.vaultId = item.vaultId;
            // container.value = item.domainId;
        
            return container;
        });
        const response = await axios({
                                    method: 'post',
                                    headers: {  'mode':'cors',
                                                'Access-Control-Allow-Origin': "*",
                                                'Authorization': 'Bearer ' + token},
                                    url: config.apiUrl + '/VaultDomain/fetchDomainsToVault',
                                    data: {                                            
                                        vaults: vaults,
                                        userId: userId,
                                        orgId: x.orgId
                                    }
                                });

       //console.log(response.data.itemResult);
        return response.data.itemResult               
    } catch (error) {
        return false
    }
}
/*==========================================================================*/
async function buildVaultToDomainChoices(vaultId, userId, token) {
        
    // //console.log(vaultId);
    let x = JSON.parse(sessionStorage.getItem("user"));
    try {
        const response = await axios({
                                        method: 'post',
                                        headers: {  'mode':'cors',
                                                    'Access-Control-Allow-Origin': "*",
                                                    'Authorization': 'Bearer ' + token},
                                        url: config.apiUrl + '/VaultDomain/buildVaultToDomainChoices',
                                        data: {
                                                vaultId: vaultId
                                                ,userId: userId
                                                ,orgId: x.orgId
                                        }
                                    });
        // //console.log(response)
        return response.data              
    } catch (error) {
        return false

    }

}
/*==========================================================================*/
async function fetchTempAccessCode(vaultId, tempCodeJustification, userId, token, openCloseCodesDate, whichCode) {
    let x = JSON.parse(sessionStorage.getItem("user"));
    
    // console.log("vaultId: ", vaultId, " tempCodeJustification ", tempCodeJustification, " userId ", userId
    //             , " token ", token)


    if(openCloseCodesDate === null){
        openCloseCodesDate = new Date();
    }
    
   
    try {
        const response = await axios({
                                        method: 'post',
                                        headers: {  'mode':'cors',
                                                    'Access-Control-Allow-Origin': "*",
                                                    'Authorization': 'Bearer ' + token},
                                        url: config.apiUrl + '/VaultDomain/fetchTempAccessCode',
                                        data: {
                                                vaultId: vaultId,
                                                tempCodeJustification: tempCodeJustification,
                                                userId: userId,
                                                orgId: x.orgId,
                                                openCloseCodesDate: openCloseCodesDate,
                                                whichCode: whichCode
                                        }
                                    });
        //console.log(response.data)
        return response               
    } catch (error) {
        return false

    }
}
/*==========================================================================*/
async function fetchUserVaultAssignments(userId, token) {
        
    //console.log("in fetch", vaultList, userId, token);
    let x = JSON.parse(sessionStorage.getItem("user"));
     try {
            const response = await axios({
                                     method: 'post',
                                     headers: {  'mode':'cors',
                                                 'Access-Control-Allow-Origin': "*",
                                                 'Authorization': 'Bearer ' + token},
                                     url: config.apiUrl + '/VaultDomain/fetchUserVaultAssignments',
                                     data: {                                                                                     
                                         userId: userId,
                                         orgId: x.orgId
                                     }
                                 });
 
        //console.log(response.data.itemResult);
         return response.data.itemResult               
     } catch (error) {
         return false
     }
 }
 /*==========================================================================*/
async function processUserToVaultAssignment(vaultAssignment, token, thisUser, activeUserId) {
   
    //console.log(vaultAssignment);
    let x = JSON.parse(sessionStorage.getItem("user"));

    if (vaultAssignment.length === 0){
        vaultAssignment.push({vaultId:0,userId:thisUser,activeUserId:activeUserId,action:'delete',orgId:x.orgId}); 
    }
//   console.log(vaultAssignment);
 
    try {
        const response = await axios({
                                        method: 'post', 
                                        headers: {  'mode':'cors',
                                                    'Access-Control-Allow-Origin': "*",
                                                    'Authorization': 'Bearer ' + token},
                                        url: config.apiUrl + '/VaultDomain/ProcessUserVaultAssignments',
                                        data: {
                                                    vaultAssignment: vaultAssignment

                                        }
                                    });
        // //console.log("andr:",response.data);
        return response.data;
        // return response.data;
    } catch (error) {
        return false
    }
}
/*==========================================================================*/
async function buildUserToVaultChoices(userId, activeUser, token) {
        
//    //console.log(activeUser, userId);
let x = JSON.parse(sessionStorage.getItem("user"));
    try {
        const response = await axios({
                                        method: 'post',
                                        headers: {  'mode':'cors',
                                                    'Access-Control-Allow-Origin': "*",
                                                    'Authorization': 'Bearer ' + token},
                                        url: config.apiUrl + '/VaultDomain/buildUserToVaultChoices',
                                        data: {
                                                activeUserId: activeUser,
                                                userId: userId,
                                                orgId: x.orgId
                                        }
                                    });
        // //console.log(response.data)
        return response.data              
    } catch (error) {
        return false

    }

}
/*==========================================================================*/
async function fetchVaultToDomainExistingAssignments(vaultId, userId, token) {
        
    // //console.log(vaultId);
    let x = JSON.parse(sessionStorage.getItem("user"));
    try {
        const response = await axios({
                                        method: 'post',
                                        headers: {  'mode':'cors',
                                                    'Access-Control-Allow-Origin': "*",
                                                    'Authorization': 'Bearer ' + token},
                                        url: config.apiUrl + '/VaultDomain/fetchVaultToDomainExistingAssignments',
                                        data: {
                                                vaultId: vaultId
                                                ,userId: userId,
                                                orgId: x.orgId
                                        }
                                    });
        // //console.log(response)
        return response.data              
    } catch (error) {
        return false

    }

}
/*==========================================================================*/
async function fetchVaultToUserExistingAssignments(userId, activeUserId, token) {
        
    // //console.log(vaultId);
    let x = JSON.parse(sessionStorage.getItem("user"));
    try {
        const response = await axios({
                                        method: 'post',
                                        headers: {  'mode':'cors',
                                                    'Access-Control-Allow-Origin': "*",
                                                    'Authorization': 'Bearer ' + token},
                                        url: config.apiUrl + '/VaultDomain/fetchVaultToUserExistingAssignments',
                                        data: {
                                                activeUserId: activeUserId,
                                                userId: userId,
                                                orgId: x.orgId
                                        }
                                    });
    //    console.log(response)
        return response.data              
    } catch (error) {
        return false

    }

}
/*==========================================================================*/
async function fetchVaultEvents(userId, token, startDate, endDate) {
    let x = JSON.parse(sessionStorage.getItem("user")); 
    try {
        const response = await axios({
                                        method: 'post',
                                        headers: {  'mode':'cors',
                                                    'Access-Control-Allow-Origin': "*",
                                                    'Authorization': 'Bearer ' + token},
                                        url: config.apiUrl + '/VaultDomain/fetchVaultEvents',
                                        data: {                                                
                                                userId: userId,
                                                startDate: startDate,
                                                endDate: endDate,
                                                orgId: x.orgId
                                        }
                                    });
    //    console.log(response.data)
        return response.data              
    } catch (error) {
        return false

    }

}
/*==========================================================================*/
async function fetchUserEvents(userId, token, startDate, endDate) {
        
    // //console.log(vaultId);
    let x = JSON.parse(sessionStorage.getItem("user"));
    try {
        const response = await axios({
                                        method: 'post',
                                        headers: {  'mode':'cors',
                                                    'Access-Control-Allow-Origin': "*",
                                                    'Authorization': 'Bearer ' + token},
                                        url: config.apiUrl + '/VaultDomain/fetchUserEvents',
                                        data: {                                                
                                                userId: userId,
                                                startDate: startDate,
                                                endDate: endDate,
                                                orgId: x.orgId
                                        }
                                    });
        // console.log(userId)
        return response.data              
    } catch (error) {
        return false

    }

}
/*==========================================================================*/
async function fetchUserMenu() {
    
    let x = JSON.parse(sessionStorage.getItem("user"));
    try {
        const response = await axios({
                                        method: 'post',
                                        headers: {  'mode':'cors',
                                                    'Access-Control-Allow-Origin': "*",
                                                    'Authorization': 'Bearer ' + x.token},
                                        url: config.apiUrl + '/VaultDomain/fetchUserMenu',
                                        data: {                                                
                                                userId: x.userId,
                                                orgId: x.orgId                                               
                                        }
                                    });
       
        // console.log(response.data.x)
        return JSON.parse(response.data.x)
    } catch (error) {
        return false

    }

}
/*==========================================================================*/
async function fetchDashboardTotals() {
    
    let x = JSON.parse(sessionStorage.getItem("user"));
    try {
        const response = await axios({
                                        method: 'post',
                                        headers: {  'mode':'cors',
                                                    'Access-Control-Allow-Origin': "*",
                                                    'Authorization': 'Bearer ' + x.token},
                                        url: config.apiUrl + '/VaultDomain/fetchDashboardTotals',
                                        data: {                                                                                                
                                                orgId: x.orgId,
                                                userId: x.userId                                               
                                        }
                                    });
       
        // console.log(response.data)
        return response.data
    } catch (error) {
        return false

    }

}
/*==========================================================================*/
async function fetchTop10VaultEvents(userId, token, startDate, endDate) {
    let x = JSON.parse(sessionStorage.getItem("user")); 
    try {
        const response = await axios({
                                        method: 'post',
                                        headers: {  'mode':'cors',
                                                    'Access-Control-Allow-Origin': "*",
                                                    'Authorization': 'Bearer ' + token},
                                        url: config.apiUrl + '/VaultDomain/fetchTop10VaultEvents',
                                        data: {                                                
                                                userId: userId,
                                                startDate: startDate,
                                                endDate: endDate,
                                                orgId: x.orgId
                                        }
                                    });
    //    console.log(response.data)
        return response.data              
    } catch (error) {
        return false

    }

}
/*==========================================================================*/
async function fetchTempAccessCodeFromScan(assetTag, tempCodeJustification, userId, token, openCloseCodesDate, whichCode) {
    let x = JSON.parse(sessionStorage.getItem("user"));
    
    console.log("vaultId: ", assetTag, " tempCodeJustification ", tempCodeJustification, " userId ", userId
                , " token ", token)


    if(openCloseCodesDate === null){
        openCloseCodesDate = new Date();
    }
    
   
    try {
        const response = await axios({
                                        method: 'post',
                                        headers: {  'mode':'cors',
                                                    'Access-Control-Allow-Origin': "*",
                                                    'Authorization': 'Bearer ' + token},
                                        url: config.apiUrl + '/VaultDomain/fetchTempAccessCodeFromScan',
                                        data: {
                                                assetTag: assetTag,
                                                tempCodeJustification: tempCodeJustification,
                                                userId: userId,
                                                orgId: x.orgId,
                                                openCloseCodesDate: openCloseCodesDate,
                                                whichCode: whichCode
                                        }
                                    });
        //console.log(response.data)
        return response               
    } catch (error) {
        return false

    }
}

/*==========================================================================*/
async function fetchDomainsForVisualTool(userId, token) {
        
    // //console.log(domainId);
    let x = JSON.parse(sessionStorage.getItem("user"));
    try {
        const response = await axios({
                                        method: 'post',
                                        headers: {  'mode':'cors',
                                                    'Access-Control-Allow-Origin': "*",
                                                    'Authorization': 'Bearer ' + token},
                                        url: config.apiUrl + '/VaultDomain/fetchDomainsForVisualTool',
                                        data: {
                                                userId: userId,
                                                orgId: x.orgId
                                        }
                                    });
        // console.log(response)
        
        return response.data                
    } catch (error) {
        return false

    }

}
/*==========================================================================*/
async function processDomainStructureChange(datasource) {
        
    let x = JSON.parse(sessionStorage.getItem("user"));

    // console.log(datasource)
    
    try {
        const response = await axios({
                                        method: 'post',
                                        headers: {  'mode':'cors',
                                                    'Access-Control-Allow-Origin': "*",
                                                    'Authorization': 'Bearer ' + x.token},
                                        url: config.apiUrl + '/VaultDomain/editDomainChangesFromVisualTool',
                                        data: {                                                                                                
                                            DomainStructure: datasource,
                                            UserId: x.userId,
                                            OrgId: x.orgId                                                
                                        }
                                    });
       //console.log(response)
        return response.data               
    }catch (error) {
        // Error 😨
        if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            return error.response
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            //console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            //console.log('Error', error.message);
        }
        //console.log(error);
    }
    
}
/*==========================================================================*/
async function acceptedEula(userId){

    let x = JSON.parse(sessionStorage.getItem("user")); 
    try {
        const response = await axios({
                                        method: 'post',
                                        headers: {  'mode':'cors',
                                                    'Access-Control-Allow-Origin': "*",
                                                    'Authorization': 'Bearer ' + x.token},
                                        url: config.apiUrl + '/VaultDomain/acceptedEula',
                                        data: {                                                
                                                userId: userId,
                                                orgId: x.orgId
                                        }
                                    });
    //    console.log(response.data)
        return response.data              
    } catch (error) {
        return false

    }
}
/*==========================================================================*/