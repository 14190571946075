import React, {Component} from 'react';

import {vaultService} from '../services/VaultServices';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {Checkbox} from 'primereact/checkbox';
import { Growl } from 'primereact/growl';
import {Dropdown} from 'primereact/dropdown';
import {Message} from 'primereact/message';
import {ProgressSpinner} from 'primereact/progressspinner';
import {PickList} from 'primereact/picklist';
import {Panel} from 'primereact/panel';
import {InputTextarea} from 'primereact/inputtextarea';
import DateTime from 'react-datetime';
import moment from 'moment';
import 'moment-timezone';
import './Vault.css';

import {MobileView, BrowserView, isMobile} from 'react-device-detect';

export class Vault extends Component {

    constructor() {
        super();
        this.state = {
            dataTableValue:[],
            panelCollapsed: true,            
            defaultMLImagePath: '/assets/images/masterlock/',
            defaultImgExt: '.svg',            
            vaultSettings:[],
            totalRecords: 0,
            vault:[],
            dirty: false,
            vaultDescriptionError: "",
            vaultFirmwareError: "",
            vaultAssetTagError: "",
            vaultModelError: "",
            vaultPhysicalLocationError: "",
            vaultDeviceIdError: "",
            codeLenError: false,
            domainAssignments: {
                domainId: "",
                vaultId: "",
                userId: ""
            },
            layout: 'grid',
            selectedVault: null, 
            visible: false,
            sortKey: null,
            sortOrder: null,
            domainList: [],
            expandedRows: null,
            tempCodeJustification: "",
            tempCodeJustificationError: "",
            featureList: "",
            openCloseCodesDate: null,
            code1Delete: false,
            code2Delete: false,
            code3Delete: false,
            code4Delete: false,
            code5Delete: false,
            showVPCError: false,
            showCode1Error: false,
            showCode2Error: false,
            showCode3Error: false,
            showCode4Error: false,
            showCode5Error: false,
            vaultToDomainError: false
        };

        this.export = this.export.bind(this);
        this.onVaultSelect = this.onVaultSelect.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        // this.itemTemplate = this.itemTemplate.bind(this);
        this.imageTemplate = this.imageTemplate.bind(this);
        this.pinTemplate = this.pinTemplate.bind(this);
        this.domainRowTemplate = this.domainRowTemplate.bind(this);
    }

    componentDidMount() {
        let user = sessionStorage.getItem("user");
        if (user) {
                // console.log(user);
                let x = JSON.parse(user);
                this.setState(() => ({
                userId: x.userId,
                token: x.token,
                domainId: x.domainId,
                orgId: x.orgId,
                featureList: x.featureList,
                currentUserFullName: x.firstName + " " + x.lastName
                }));    
                
                if (!x.acceptedEula || x.userRole === 3){
                    this.props.handleLogout();
                  } else{
                this.setState({ loading: true });
                
                vaultService.fetchVaults(x.domainId, x.token, x.userId).then(data => this.setState({dataTableValue: data[0],
                                                                                                    totalRecords: data[0].length,
                                                                                                    domainList: data[2]}));     
                // vaultService.fetchVaultDomains(x.userId, x.token).then(data => this.setState({domainPickSource: data.itemResult}));                 
                this.setState({loading: false}); 
                  }
        }                   
    }    

    export() {

        this.dt.exportCSV();
    }

    handleFocus = name => event => {        
        var x = [name] + "Error";            
        this.setState({[x]: ""});         
        
    }

    onVaultAddRequest = () => {
        this.setState({
            displayDialogAddVault:true,
            vaultInput: { vaultDescription: ''
                         ,vaultFirmware: ''
                         ,vaultAssetTag: ''
                         ,vaultPhysicalLocation: ''
                         ,vaultModel: ''
                         ,vaultDeviceId: ''
                        }});
    }   

       
    updateProperty(property, value, checkIt) {        
       
         
        let hasError = false;        
       
        if(property === 'tempCodeJustification'){
            this.setState({dirty: true,
                           tempCodeJustification: value})
        } else if(property === 'openCloseCodesDate'){
            this.setState({openCloseCodesDate: value, dirty:true});
        } else {
       
            let vaultInput = this.state.vaultInput;                
            vaultInput[property] = value; 

            if(checkIt){
                this.setState({codeLenError: false});
                switch (property){
                    case "pendingPrimaryCode":
                        if(vaultInput[property].charAt(0) === '0'){
                            this.setState({showVPCError : true});
                            hasError = true;
                        } else if (vaultInput[property].length > 3) {
                            this.setState({showVPCError : false});
                            hasError = false;
                        } else {
                            this.setState({showVPCError : false});
                            hasError = false;
                        }
                    break;
                    case "pendingCode1":
                        if(vaultInput[property].charAt(0) === '0'){
                            this.setState({showCode1Error : true});
                            hasError = true;
                        } else if (vaultInput[property].length > 3) {
                            this.setState({showCode1Error : false});
                            hasError = false;
                        } else {
                            this.setState({showCode1Error : false});
                            hasError = false;
                        }
                    break;
                    case "pendingCode2":
                        if(vaultInput[property].charAt(0) === '0'){
                            this.setState({showCode2Error : true});
                            hasError = true;
                        } else if (vaultInput[property].length > 3) {
                            this.setState({showCode2Error : false});
                            hasError = false;
                        } else {
                            this.setState({showCode2Error : false});
                            hasError = false;
                        }
                    break;
                    case "pendingCode3":
                        if(vaultInput[property].charAt(0) === '0'){
                            this.setState({showCode3Error : true});
                            hasError = true;
                        } else if (vaultInput[property].length > 3) {
                            this.setState({showCode3Error : false});
                            hasError = false;
                        } else {
                            this.setState({showCode3Error : false});
                            hasError = false;
                        }
                    break;
                    case "pendingCode4":
                        if(vaultInput[property].charAt(0) === '0'){
                            this.setState({showCode4Error : true});
                            hasError = true;
                        } else if (vaultInput[property].length > 3) {
                            this.setState({showCode4Error : false});
                            hasError = false;
                        } else {
                            this.setState({showCode4Error : false});
                            hasError = false;
                        }
                    break;
                    case "pendingCode5":
                        if(vaultInput[property].charAt(0) === '0'){
                            this.setState({showCode5Error : true});
                            hasError = true;
                        } else if (vaultInput[property].length > 3) {
                            this.setState({showCode5Error : false});
                            hasError = false;
                        } else {
                            this.setState({showCode5Error : false});
                            hasError = false;
                        }
                    break;
                    default:
                        this.setState({dirty: true});
                }
            }
            if(hasError){
                this.setState({dirty: false});
            } else {
                this.setState({dirty: true});
            }
            
                
        }
    }

    onCancel(action) {        
        if (action === 'add'){
            this.setState({ vaultDescription: "",
                            vaultFirmware: "",
                            vaultAssetTag: "",
                            vaultPhysicalLocation: "",
                            vaultDeviceId: "",
                            dirty: false,
                            displayDialogAddVault:false,
                            vaultDescriptionError: "",
                            vaultFirmwareError: "",
                            vaultAssetTagError: "",
                            vaultPhysicalLocationError: "",
                            vaultModelError: "",
                            vaultDeviceIdError: "",                                                        
                            loading: false});
        } else if (action === 'edit'){
            this.setState({dirty:false,
                           loading:false,
                           showVPCError:false,
                           showCode1Error: false,
                           showCode2Error: false,
                           showCode3Error:false,
                           showCode4Error:false,
                           showCode5Error:false,
                           vaultPhysicalLocationError: "",                           
                        //    code1Delete: false,
                        //    code2Delete: false,
                        //    code3Delete: false,
                        //    code4Delete: false,
                        //    code5Delete: false,
                           codeLenError: false,
                           displayDialogEditVault:false});
        } else if (action === 'addAssign'){                   
            this.setState({dirty:false,
                           loading:false,
                           displayDialogDomainAssignments:false,
                           vaultToDomainError:false
                           });                    
        } else if (action === 'tempCodeReason'){                   
            this.setState({dirty:false,
                           loading:false,
                           displayDialogTempCodeReason:false  , 
                           tempCodeJustificationError: "",
                           tempCodeJustification: ""
                           });    
        } else if (action === 'openCloseCodesReason'){                   
            this.setState({dirty:false,
                           loading:false,
                           displayDialogOpenCloseCodesReason:false  , 
                           tempCodeJustificationError: "",
                           tempCodeJustification: "",
                           date:"",
                           openCloseCodesDate: ""});    
        }
        
    }

    onSortChange(event) {
        let value = event.value;

        if (value.indexOf('!') === 0)
            this.setState({sortOrder: -1, sortField:value.substring(1, value.length), sortKey: value});
        else
            this.setState({sortOrder: 1, sortField:value, sortKey: value});
    }
    
    async buildPickLists(data) {  
    
        const freshPickList = await vaultService.buildVaultToDomainChoices(data.vaultId, this.state.userId, this.state.token);   
        const existingAssignments = await vaultService.fetchVaultToDomainExistingAssignments(data.vaultId, this.state.userId, this.state.token);
        const picklist = freshPickList.itemResult;
        const existingList = existingAssignments.itemResult;
        
        // console.log(existingAssignments)

        this.setState({
            domainPickTarget : [...existingList],
            domainPickSource : [...picklist]
        });        
    }


    async onVaultSelect(data,action){

        
        if(action === "editVault"){                

            let dat = {};
            dat = await vaultService.fetchVault(data.vaultId);   

            this.setState({
            displayDialogEditVault:true,
            code1Delete: false,
            code2Delete: false,
            code3Delete: false,
            code4Delete: false,
            code5Delete: false,    
            pendingCode1Delete: (dat.pendingCode1Delete === -1 ? false : true),  
            pendingCode2Delete: (dat.pendingCode2Delete === -1 ? false : true),  
            pendingCode3Delete: (dat.pendingCode3Delete === -1 ? false : true),  
            pendingCode4Delete: (dat.pendingCode4Delete === -1 ? false : true),  
            pendingCode5Delete: (dat.pendingCode5Delete === -1 ? false : true),       
            vaultInput: Object.assign({}, dat)
            });

        } else if (action === "vaultAssign"){
            this.setState({
            displayDialogVaultAssign:true,
            vault: Object.assign({}, data)
            });
        } else if (action === "fetchTempCode"){
            //this.renderTempCode(data);       
            this.setState({
                displayDialogTempCodeReason:true,
                tempCodeJustificationError: "",
                tempCodeJustification: "",  
                vault: Object.assign({}, data)
            }); 
        } else if (action === "fetchOpenCloseCodes"){
            this.setState({
                displayDialogOpenCloseCodesReason:true,
                tempCodeJustificationError: "",
                tempCodeJustification: "",                
                vault: Object.assign({}, data)
            }); 
        } else {                        
            this.buildPickLists(data);
            
            this.setState({
                displayDialogDomainAssignments:true,
                dirty: false,
                vault: Object.assign({}, data)                
            });                
        }
    }
   
    onVaultProcess = (action) => {
        
       let isError = false;    
        
        if (action === 'add'){
            this.setState({ loading: true });
            const err = this.validate("add");
            if (!err) {
                try {
                    let currentCount = this.state.totalRecords + 1;
                    vaultService.processVaultAction(this.state.domainId,
                                        this.state.token, 
                                        this.state.vaultInput,
                                        'add', this.state.userId)
                                    .then(data => this.setState({dataTableValue: data.itemResult}));                    
                    
                    this.setState({loading: false,
                                displayDialogAddVault:false,
                                totalRecords: currentCount});
                    this.growl.show({severity: 'success', summary: 'Success Message', detail: 'Vault added.'});             

                } catch (e) {
                    alert(e);                    
                    this.setState({loading: false,
                                displayDialogAddVault:false});             
                }      
            }
        } else if (action === 'edit') {
                   //console.log("HERE... ", this.state.vaultInput.code1Description)
            this.setState({ loading: true });    
            try {                    
                    /* check our fields prior to update to prevent data loss */                    
                    if(this.state.vaultInput.vaultPhysicalLocation === undefined){
                        this.updateProperty("vaultPhysicalLocation", this.state.vaultInput.physicalLocation);
                    }
                    
                    if(this.state.vaultInput.vaultPrimaryCode === undefined || this.state.vaultInput.vaultPrimaryCode === ''){
                        this.updateProperty("vaultPrimaryCode", this.state.vaultInput.pendingPrimaryCode);                        
                    } else if (this.state.vaultInput.vaultPrimaryCode !== undefined && this.state.vaultInput.vaultPrimaryCode.length < 4){
                                isError = true;                            
                    }
                    if(this.state.vaultInput.primaryCodeDescription === ""){
                        this.updateProperty("primaryCodeDescription", "Master Code");
                    }
                    if(this.state.vaultInput.code1Description === ""){
                        this.updateProperty("code1Description", "Code 1");
                    }
                    if(this.state.vaultInput.code2Description === ""){
                        this.updateProperty("code2Description", "Code 2");
                    }

                    if(this.state.vaultInput.code3Description === ""){
                        this.updateProperty("code3Description", "Code 3");
                    }

                    if(this.state.vaultInput.code4Description === ""){
                        this.updateProperty("code4Description", "Code 4");
                    }

                    if(this.state.vaultInput.code5Description === ""){
                        this.updateProperty("code5Description", "Code 5");
                    }

   
                    
                    //don't forget the delete requests....
                    this.updateProperty("pendingCode1Delete", this.state.pendingCode1Delete ? 1 : -1);
                    this.updateProperty("pendingCode2Delete", this.state.pendingCode2Delete ? 1 : -1);
                    this.updateProperty("pendingCode3Delete", this.state.pendingCode3Delete ? 1 : -1);
                    this.updateProperty("pendingCode4Delete", this.state.pendingCode4Delete ? 1 : -1);
                    this.updateProperty("pendingCode5Delete", this.state.pendingCode5Delete ? 1 : -1);

                    /* end */
                    if(isError){
                        this.updateProperty("vaultPrimaryCode", );
                        this.updateProperty("vaultCode1", );
                        this.updateProperty("vaultCode2", );
                        this.updateProperty("vaultCode3", );
                        this.updateProperty("vaultCode4", );
                        this.updateProperty("vaultCode5", );
                        this.updateProperty("vaultPrimaryCodeDescription", );
                        this.updateProperty("vaultCode1Description", );
                        this.updateProperty("vaultCode2Description", );
                        this.updateProperty("vaultCode3Description", );
                        this.updateProperty("vaultCode4Description", );
                        this.updateProperty("vaultCode5Description", );




                        this.setState({ codeLenError: true
                                       ,dirty:false
                                       ,loading:false});
                        return false;
                    }
                    vaultService.processVaultAction(this.state.vaultInput,
                                        'edit', this.state.userId)
                                    
                                    .then(data => this.setState({dataTableValue: data[0],
                                        totalRecords: data[0].length,
                                        domainList: data[2]}));  
                    this.setState({loading: false,
                                displayDialogEditVault:false,
                                dirty: false,
                            codeLenError: false});
                    this.growl.show({severity: 'success', summary: 'Success Message', detail: 'Vault edited.'});             

                } catch (e) {
                    alert(e);                    
                    this.setState({loading: false,
                                displayDialogEditVault:false,
                            dirty: false});             
                }      
        } else {
            try {       
                this.updateProperty("vaultPhysicalLocation", "--No description on file--");
                this.updateProperty("physicalLocation", "--No description on file--");
            } catch (e) {
                alert(e);                    
                this.setState({loading: false,
                            displayDialogEditVault:false});             
            }
        }        
    }
   

    validate = (action) => {
        let isError = false;
        let vaultDescription = "";
        let vaultFirmware = "";
        let vaultAssetTag = "";        
        let vaultPhysicalLocation = "";
        let vaultDeviceId = "";
        let vaultModel = "";

        const errors = {
          vaultDescriptionError: "",
          vaultFirmwareError: "",
          vaultAssetTagError: "",
          vaultModelError: "",
          vaultPhysicalLocationError: "",
          vaultDeviceIdError: ""
        };
        
        
        if (action === 'add'){
            vaultDescription = this.state.vaultInput.vaultDescription.replace(/^\s+|\s+$/gm,'');
            vaultFirmware = this.state.vaultInput.vaultFirmware.replace(/^\s+|\s+$/gm,'');
            vaultAssetTag = this.state.vaultInput.vaultAssetTag.replace(/^\s+|\s+$/gm,'');
            vaultPhysicalLocation = this.state.vaultInput.vaultPhysicalLocation.replace(/^\s+|\s+$/gm,'');   
            vaultDeviceId = this.state.vaultInput.vaultDeviceId.replace(/^\s+|\s+$/gm,''); 
            vaultModel = this.state.vaultInput.vaultModel.replace(/^\s+|\s+$/gm,'');   
           
            if (!vaultDescription.length >= 1) {
                    isError = true;
                    errors.vaultDescriptionError = "Code Description is required";                
            } else if (!vaultFirmware.length >= 1) {
                    isError = true;
                    errors.vaultFirmwareError = "Vault Firmware is required";
            } else if (!vaultAssetTag.length >= 1){
                    isError = true;
                    errors.vaultAssetTagError = "Vault Asset Tag is required";
            } else if (!vaultModel.length >= 1){
                    isError = true;
                    errors.vaultModelError = "Vault Model is required";
            } else if (!vaultPhysicalLocation.length >= 1){
                    isError = true;
                    errors.vaultPhysicalLocationError = "Vault Description is required";
            } else if (!vaultDeviceId.length >= 1){
                    isError = true;
                    errors.vaultDeviceIdError = "Vault Mfg Device ID is required";
            }
            this.setState({
            ...this.state,
            ...errors
            });
        }
        return isError;
      }

    actionTemplate(rowData, column) {
      
        if (isMobile){ 
        return <span style={{textAlign:'center'}}>                
           {(this.state.featureList.includes(5) && !rowData.model.includes('6440')) &&
            <React.Fragment>
            <Button id="fetchTempCode"
                    type="button" icon="pi pi-unlock"
                    style={{margin:'2px'}}
                    className="p-button-raised p-button-rounded p-button-danger"
                    tooltip="Obtain Vault Temp Code"
                    onClick={() => this.onVaultSelect(rowData, "fetchTempCode")}></Button> 
            <Button id="fetchOpenCloseCodes"
                        type="button" icon="pi pi-tags"
                        style={{margin:'2px'}}
                        className="p-button-raised p-button-rounded p-button-warning"
                        tooltip="Obtain Morning/Night Codes"
                        onClick={() => this.onVaultSelect(rowData, "fetchOpenCloseCodes")}></Button>  
            </React.Fragment>       
           }
           {/* {(this.state.featureList.includes(10)) &&
            <Button id="qrScan"
                    type="button" icon="pi qrCodeIcon"
                    style={{margin:'2px'}}
                    className="p-button-raised p-button-rounded p-button-success"
                    tooltip="QR Scan"
                    onClick={() => this.scanCode()}></Button>         
           }         */}
         </span>;
           
        } else { 
            return <div style={{textAlign:'left'}}>                
               {(this.state.featureList.includes(8)) &&
                    <Button id="editVaultInfo"
                        type="button" icon="pi pi-pencil"
                        style={{margin:'2px'}}
                        className="p-button-raised p-button-rounded p-button-success"
                        tooltip="Edit Vault"
                        onClick={() => this.onVaultSelect(rowData, "editVault")}></Button>               
                }
               
                {(this.state.featureList.includes(7)) &&
                    <Button id="vault2DomainAssign"
                            type="button" icon="pi pi-sitemap"
                            style={{margin:'2px'}}
                            className="p-button-raised p-button-rounded p-button-info"
                            tooltip="Domain Assignments"
                            onClick={() => this.onVaultSelect(rowData, "domainAssignments")}></Button>                
                }
                
                {(this.state.featureList.includes(5) && !rowData.model.includes('6440')) &&
                    <React.Fragment>
                    <Button id="fetchTempCode"
                        type="button" icon="pi pi-unlock"
                        style={{margin:'2px'}}
                        className="p-button-raised p-button-rounded p-button-danger"
                        tooltip="Obtain Vault Temp Code"
                        onClick={() => this.onVaultSelect(rowData, "fetchTempCode")}></Button> 
                    <Button id="fetchOpenCloseCodes"
                        type="button" icon="pi pi-tags"
                        style={{margin:'2px'}}
                        className="p-button-raised p-button-rounded p-button-warning"
                        tooltip="Obtain Morning/Night Codes"
                        onClick={() => this.onVaultSelect(rowData, "fetchOpenCloseCodes")}></Button>                      
                    </React.Fragment>  
                }                              
                </div>;
            }
    }

    onDomainPickChange = (event) => {       
        
        this.setState({
            domainPickSource: event.source,
            domainPickTarget: event.target,         
            dirty: true
        });
    }

    domainTemplate(domain) {      
       if (!domain){
           return null
       }
        return (
            <div className="p-clearfix">
                    <div>{domain.domain} - {domain.domainDescription}</div>                
            </div>
        );       
    }

  async  onDomainAssignmentAdd(thisVault) {
        
        try {
            //console.log(this.state.domainPickTarget);
            
            if(this.state.domainPickTarget.length === 0){
                this.setState ({loading: false,
                                vaultToDomainError: true});
            } else {
                this.setState({ loading: true, vaultToDomainError: false });
                let userId = this.state.userId;
                let orgId = this.state.orgId;
                //    console.log(orgId,"tets");
                let newArr = this.state.domainPickTarget.map(function(elem) {
                        return {
                            domainId: elem.domainId,
                            vaultId: thisVault,
                            userId: userId,
                            orgId: orgId
                        };
                    });                 

                await vaultService.processVaultsToDomainsAssignment(newArr, this.state.token, userId, thisVault, orgId)
                                .then(data => this.setState({dataTableValue: data[0],
                                                            totalRecords: data[0].length,
                                                            domainList: data[2]}));   

                this.growl.show({severity: 'success', summary: 'Success Message', detail: 'Vault Assignment completed.'});             
                
                this.setState({loading: false,
                            displayDialogDomainAssignments:false});
            }    
          } catch (e) {
            alert(e);                    
            this.setState({loading: false,
                           displayDialogDomainAssignments:false});             
          }        
    }

    renderListItem(vault) {
    //    console.log("in rli",vault);
      
    
    const domains = this.state.domainList.map(item => (
        <li style={{listStyleType: 'none'}}>{(vault.vaultId === item.vaultId ? (item.domain + '-' + item.domainDescription) :  '   ')}</li>
    ));

       return (
            <div className="p-col-12" style={{padding: '2em', borderBottom: '1px solid #d9d9d9'}}>
                <div className="p-col-12 p-md-3">
                    <img src={this.state.defaultMLImagePath + vault.model + this.state.defaultImgExt}
                        alt={this.state.vault.vaultModel}
                        width="60px"
                        height="60px" />
                </div>
                <div className="p-col">
                    <div className="p-grid">
                        <div className="p-col-2 dataHeader">Vault ID:</div>
                        <div className="p-col-4 dataBody">{vault.assetTag}</div>
                        
                        <div className="p-col-2 dataHeader">Mfg Device ID:</div>
                        <div className="p-col-4 dataBody">{vault.deviceId}</div>

                        <div className="p-col-2 dataHeader">Description:</div>
                        <div className="p-col-4 dataBody">{vault.physicalLocation}</div>

                        <div className="p-col-2 dataHeader">Last Pin Request:</div>
                        <div className="p-col-4 dataBody">{vault.lastPinRequest}</div>

                        <div className="p-col-2 dataHeader">Last Pin Requester:</div>
                        <div className="p-col-4 dataBody">{vault.lastPinRequester}</div>

                        <div className="p-col-2 dataHeader">Domain Assignments:</div>
                        <div className="p-col-4 dataBody">{domains}</div>
                        
                       
                        

                    </div>
                </div>

                <div className="p-col-12 p-md-1 search-icon" style={{marginTop:'40px'}}>
                    
                    {this.actionTemplate(vault)}
                </div>
            </div>
        );
    }

     renderGridItem(vault) {
        // console.log("in rli",vault);
    
        const domains = this.state.domainList.map(item => (
            <li style={{listStyleType: 'none'}}>{(vault.vaultId === item.vaultId ? (item.domain + '-' + item.domainDescription) :  '   ')}</li>
        ));
        
        //console.log(domains)


        return (
            <div style={{ padding: '.5em' }} className="p-col-12 p-md-3">
                <Panel header={vault.vaultDescription} style={{ textAlign: 'center' }}>
                <img src={this.state.defaultMLImagePath + vault.model + this.state.defaultImgExt}
                        alt={this.state.vault.vaultModel}
                        width="60px"
                        height="60px" />
                    <div className="p-col-12">
                    <div className="p-grid" style={{textAlign:'left'}}>
                        <div className="p-col-4 dataHeader">Vault Id:</div>
                        <div className="p-col-8 dataBody">{vault.assetTag}</div>

                        <div className="p-col-4 dataHeader">Mfg Device ID:</div>
                        <div className="p-col-8 dataBody">{vault.deviceId}</div>

                        <div className="p-col-4 dataHeader">Description:</div>
                        <div className="p-col-8 dataBody">{vault.physicalLocation}</div>

                        <div className="p-col-4 dataHeader">Last Pin Request:</div>
                        <div className="p-col-8 dataBody">{vault.lastPinRequest}</div>

                        <div className="p-col-4 dataHeader">Last Pin Requester:</div>
                        <div className="p-col-8 dataBody">{vault.lastPinRequester}</div>

                        <div className="p-col-4 dataHeader">Domain Assignments:</div>
                        <div className="p-col-8 dataBody">{domains}</div>
                        
                    </div>
                    </div>    
                    <hr className="ui-widget-content" style={{ borderTop: 0 }} />
                    {this.actionTemplate(vault)}
                </Panel>
            </div>
        );
    }

    renderVaultDialogContent() {
        // console.log(this.state.selectedVault)
        if (this.state.selectedVault) {
            const domains = this.state.domainList.map(item => (
                <li style={{listStyleType: 'none'}}>{(this.state.selectedVault.vaultId === item.vaultId ? (item.domain + '-' + item.domainDescription) :  '   ')}</li>
            ));
            return (
                <div className="p-grid" style={{fontSize: '16px', textAlign: 'center', padding: '20px'}}>
                    <div className="p-col-12">
                    <img src={this.state.defaultMLImagePath + this.state.selectedVault.model + this.state.defaultImgExt}
                        alt={this.state.selectedVault.vaultModel}
                        width="60px"
                        height="60px" />
                    </div>
                    <div className="p-col-12">
                    <div className="p-grid" style={{textAlign:'left'}}>
                        <div className="p-col-4 dataHeader">Vault ID:</div>
                        <div className="p-col-8 dataBody">{this.state.selectedVault.assetTag}</div>

                        <div className="p-col-4 dataHeader">Mfg Device ID:</div>
                        <div className="p-col-8 dataBody">{this.state.selectedVault.deviceId}</div>

                        <div className="p-col-4 dataHeader">Description:</div>
                        <div className="p-col-8 dataBody">{this.state.selectedVault.physicalLocation}</div>

                        <div className="p-col-4 dataHeader">Last Pin Request:</div>
                        <div className="p-col-8 dataBody">{this.state.selectedVault.lastPinRequest}</div>

                        <div className="p-col-4 dataHeader">Last Pin Requester:</div>
                        <div className="p-col-8 dataBody">{this.state.selectedVault.lastPinRequester}</div>

                        <div className="p-col-4 dataHeader">Domain Assignments:</div>
                        <div className="p-col-8 dataBody">{domains}</div>
                        
                    </div>
                    </div>   
                    
                    
                  

                </div>
            );
        }
        else {            
            return null;
        }
    }

    pinTemplate(data) {
        if(data.lastPinRequest!== "") {
            return(<div>{data.lastPinRequester} - {data.lastPinRequest}</div>);
        } else {
            return(<div>No pins requested</div>);
        }
    }

    domainRowTemplate(data) {
        const domains = this.state.domainList.map(item => (
            // <li style={{listStyleType: 'none'}}>{(data.vaultId === item.vaultId ? (item.domain + '-' + item.domainDescription) :  '   ')}</li>
            <div>{data.vaultId === item.vaultId ? (item.domain) :  (null)}</div>
        ));
        return(<div>{domains}</div>);
    }

    renderHeader() {
        return ( <div className="p-grid p-fluid">
                        <div className="p-col p-lg-2 p-sm-12">
                            <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})}
                                       placeholder="Global Search" size="50" autoComplete={false} />
                        </div>
                        <div className="p-col p-sm-12 p-lg-10" style={{textAlign:'right'}}>
                              <span>Total Devices: {this.state.totalRecords}<br/></span>
                              {/* <span>Filtered Results: 0</span> */}                             
                        </div>                        
                    </div>); 
       
    }

    getArrows = (theVal) => {
  
        var s = theVal.split('');
          for (var i = 0; i < s.length; i++) {
              switch(s[i]) {
                  case 'R':            
                  s[i] = "R";
                  break;
              case 'L':     
                  s[i] = "L";
                  break;
              case 'U':
                  s[i] = "U";
                  break;
              case 'D':
                  s[i] = "D";
                  break;
              default:
                  break;
              }      
          }
          var x = s.join('');
          var result = this.flatMap(x, function (part) {
            return [part];
          });
      
          return result ;
      }
      flatMap(array, fn) {
        var result = [];
        for (var i = 0; i < array.length; i++) {
          var mapping = fn(array[i]);
          result = result.concat(mapping);
        }
        return result;
      } 

    async renderTempCode(vault) {
                
        //quick verify
        let justification = this.state.tempCodeJustification.replace(/^\s+|\s+$/gm,'');
        
        if (justification.length === 0) {           
            this.setState({ tempCodeJustificationError: "ERROR",
                            dirty: false,
                            displayDialogTempCodeReason: true});
            return false
        }
        this.setState({displayDialogTempCodeReason: false,
                       dirty: false,
                       tempCodeJustificationError: "",
                       loading: true});
        
        //fetch data - ferret out the arrows - output the results//
        let whichCode = 0;        
        let data = await vaultService.fetchTempAccessCode(vault.vaultId , this.state.tempCodeJustification
                                                                        , this.state.userId
                                                                        , this.state.token
                                                                        , null
                                                                        , whichCode);
                                                                    
        
        let vaultData = data.data;
        let y = null;
        let display = vaultData["tempCode"];
        // console.log(display)
        let arr = this.getArrows(display);
        
        if(isMobile){
            y = arr.map(code => (
                <div style={{display: "inline-block"}}>
                    {code === 'R' ?
                    (<i class="pi pi-arrow-right" style={{'fontSize': '1.5em'}}></i>)
                    : code === 'L' ?
                    (<i class="pi pi-arrow-left" style={{'fontSize': '1.5em'}}></i>)
                    : code === 'U' ?
                    (<i class="pi pi-arrow-up" style={{'fontSize': '1.5em'}}></i>)
                    : code === 'D' ?         
                    (<i class="pi pi-arrow-down" style={{'fontSize': '1.5em'}}></i>)
                    : <span>{code}</span>
                    }
                </div>
                )
            );
        } else {
            y = arr.map(code => (
                <div style={{display: "inline-block"}}>
                  {code === 'R' ?
                   (<i class="pi pi-arrow-right" style={{'fontSize': '3em'}}></i>)
                   : code === 'L' ?
                   (<i class="pi pi-arrow-left" style={{'fontSize': '3em'}}></i>)
                  : code === 'U' ?
                    (<i class="pi pi-arrow-up" style={{'fontSize': '3em'}}></i>)
                  : code === 'D' ?         
                    (<i class="pi pi-arrow-down" style={{'fontSize': '3em'}}></i>)
                  : <span>{code}</span>
                  }
                </div>
              )
             );   
        }
        vaultService.fetchVaults(this.state.domainId, this.state.token, this.state.userId).then(data => this.setState({dataTableValue: data[0]}));   
        
        this.setState({displayDialogFetchTempCode:true,
                       loading: false,
                       tempCode: y});
        
        //console.log(y)
                       
    }
    
    imageTemplate(data) {
       
        return  (
                <div><img src={this.state.defaultMLImagePath + data.model + this.state.defaultImgExt}
                        alt={data.model}
                        width="40px"
                        height="40px" style={{margin:"auto", width:"100%"}}/>
                </div>
        );
    }    

    async renderOpenCloseCodes(vault) {
                
        //quick verify
        let justification = this.state.tempCodeJustification.replace(/^\s+|\s+$/gm,'');
        
        if (justification.length === 0) {           
            this.setState({ tempCodeJustificationError: "ERROR",
                            dirty: false,
                            openCloseCodesDate: "",
                            displayDialogOpenCloseCodesReason: true});
            return false
        }
        this.setState({displayDialogOpenCloseCodesReason: false,
                       dirty: false,
                       tempCodeJustificationError: "",                       
                       loading: true});
        
       
    
        let whichCode = 1;
        let data = await vaultService.fetchTempAccessCode(vault.vaultId, this.state.tempCodeJustification
                                                                       , this.state.userId
                                                                       , this.state.token
                                                                       , this.state.openCloseCodesDate
                                                                       , whichCode);  
        let vaultData = data.data;        
                
        let y = null;
        let z = null;

        let displayOpen = vaultData["openTempCode"];
        let displayClose = vaultData["closeTempCode"];
        let codeEffectiveDate = vaultData["openCloseCodesDate"];
        let morningLabel = null;
        let nightLabel = null;

//        console.log("vd:",vaultData);        
        codeEffectiveDate = moment(codeEffectiveDate).format("DD-MMM-YYYY");
        
        let arrOpen = this.getArrows(displayOpen);
        let arrClose = this.getArrows(displayClose);

        if(isMobile){            
            y = arrOpen.map(code => (
                <div style={{display: "inline-block"}}>
                    {code === 'R' ?
                    (<i class="pi pi-arrow-right" style={{'fontSize': '1.5em'}}></i>)
                    : code === 'L' ?
                    (<i class="pi pi-arrow-left" style={{'fontSize': '1.5em'}}></i>)
                    : code === 'U' ?
                    (<i class="pi pi-arrow-up" style={{'fontSize': '1.5em'}}></i>)
                    : code === 'D' ?         
                    (<i class="pi pi-arrow-down" style={{'fontSize': '1.5em'}}></i>)
                    : <span>{code}</span>
                    }
                </div>
                )
            );
            z = arrClose.map(code => (
                <div style={{display: "inline-block"}}>
                    {code === 'R' ?
                    (<i class="pi pi-arrow-right" style={{'fontSize': '1.5em'}}></i>)
                    : code === 'L' ?
                    (<i class="pi pi-arrow-left" style={{'fontSize': '1.5em'}}></i>)
                    : code === 'U' ?
                    (<i class="pi pi-arrow-up" style={{'fontSize': '1.5em'}}></i>)
                    : code === 'D' ?         
                    (<i class="pi pi-arrow-down" style={{'fontSize': '1.5em'}}></i>)
                    : <span>{code}</span>
                    }
                </div>
                )
            );
        } else {
            y = arrOpen.map(code => (
                <div style={{display: "inline-block"}}>
                  {code === 'R' ?
                   (<i class="pi pi-arrow-right" style={{'fontSize': '3em'}}></i>)
                   : code === 'L' ?
                   (<i class="pi pi-arrow-left" style={{'fontSize': '3em'}}></i>)
                  : code === 'U' ?
                    (<i class="pi pi-arrow-up" style={{'fontSize': '3em'}}></i>)
                  : code === 'D' ?         
                    (<i class="pi pi-arrow-down" style={{'fontSize': '3em'}}></i>)
                  : <span>{code}</span>
                  }
                </div>
              )
             );   
             z = arrClose.map(code => (
                <div style={{display: "inline-block"}}>
                  {code === 'R' ?
                   (<i class="pi pi-arrow-right" style={{'fontSize': '3em'}}></i>)
                   : code === 'L' ?
                   (<i class="pi pi-arrow-left" style={{'fontSize': '3em'}}></i>)
                  : code === 'U' ?
                    (<i class="pi pi-arrow-up" style={{'fontSize': '3em'}}></i>)
                  : code === 'D' ?         
                    (<i class="pi pi-arrow-down" style={{'fontSize': '3em'}}></i>)
                  : <span>{code}</span>
                  }
                </div>
              )
             );  
        }
        vaultService.fetchVaults(this.state.domainId, this.state.token, this.state.userId).then(data => this.setState({dataTableValue: data[0]}));   
        

        switch (vault.orgTimeZoneAbbrv) {

            case 'EDT':
                morningLabel = "Your Morning Code (4AM-Noon EDT) ";
                nightLabel = "Your Night Code (4PM-Midnight EDT) ";
            break;
            case 'EST':
                morningLabel = "Your Morning Code (3AM-11AM EST) ";
                nightLabel = "Your Night Code (3PM-11PM EST) ";
            break;
            case 'MDT':
                morningLabel = "Your Morning Code (6AM-2PM MDT) ";
                nightLabel = "Your Night Code (2PM-10PM MDT) ";
            break;
            case 'MST':
                morningLabel = "Your Morning Code (5AM-1PM MST) ";
                nightLabel = "Your Night Code (1PM-9PM MST) ";
            break;
            case 'CDT':
                morningLabel = "Your Morning Code (3AM-11AM CDT) ";
                nightLabel = "Your Night Code (3PM-11PM CDT) ";
            break;
            case 'CST':
                morningLabel = "Your Morning Code (6AM-2PM CST) ";
                nightLabel = "Your Night Code (2PM-10PM CST) ";
            break;
            default:
                morningLabel = "Your Morning Code (4AM-Noon) ";
                nightLabel = "Your Night Code (4PM-Midnight) ";
            break;
        }
          



        this.setState({displayDialogOpenCloseCodesResults:true,
                       loading: false,
                       tempCodeOpen: y,
                       tempCodeClose: z,
                       shareOpenCode: vaultData["openTempCode"],
                       shareCloseCode: vaultData["closeTempCode"],
                       tempCodeEffectiveDate: codeEffectiveDate,
                       tzMorningLabel: morningLabel,
                       tzNightLabel: nightLabel});
        
        //console.log(y)
        
                       
    }

    handleDate(codeDate){        
       this.setState({openCloseCodesDate: codeDate});
    }

    scanCode = () => {
    
        this.props.history.push('/QRScan');
    }
    
    onShare = () => {        
        
        // console.log(this.state.vault["assetTag"])
        const title = "Date Codes Effective " + this.state.tempCodeEffectiveDate + "\r\n";
        const text= "For " + this.state.vault["assetTag"] + "\r\n\r\n" + this.state.tzMorningLabel + this.state.shareOpenCode + "\r\n\r\n " + this.state.tzNightLabel + this.state.shareCloseCode + "\r\n\r\n";
        //const url = "Obtained by " + this.state.currentUserFullName;
        const onSuccess = null;
        const onError = null;

        if (window.navigator.share) {
            //console.log(this.state.vault["assetTag"])
          window.navigator.share({ title, text })
            .then(onSuccess)
            .catch(onError);
        } else {
          //not supported
        }
      }     
      
/* =========================================================================================== */
    render() {
        const { loading, vault, dirty, vaultInput
                       , domainPickTarget
                       , domainPickSource
                       , tempCode
                       , tempCodeJustification
                       , featureList
                       , tempCodeOpen
                       , tempCodeClose
                       , tempCodeEffectiveDate
                       , tzMorningLabel
                       , tzNightLabel} = this.state;

        const vaultModels = [
            {label: '5441D', value: '5441D'},
            {label: '4400D', value: '4400D'},
            {label: '6400', value: '6400'}
        ];
       

    var header = <div className="p-grid p-fluid">        
        <div className="p-col p-lg-2 p-sm-12">
            <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})}
                       placeholder="Global Search" size="50"/>
        </div>
        <div className="p-col p-sm-12 p-lg-10" style={{textAlign:'right'}}>
              <span>Total Devices: {this.state.totalRecords}<br/></span>
              {/* <span>Filtered Results: 0</span> */}             
        </div>                        
     </div>;  

    // const { isSupported } = useWebShare();
    
    
/* =========================================================================================== */    
        return (
            <div className="flexi-grid">
                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1><i className="pi pi-lock"/>	Devices                      
                        {(this.state.featureList.includes(10)) && isMobile &&
                            <span style={{float:"right"}}>                                                                                           
                                <Button id="qrScan"
                                        type="button"                                                                                                                      
                                        label="QR Scan"
                                        icon="pi qrCodeIcon"                                        
                                        className="p-button-primary p-button-raised p-button-rounded" 
                                        tooltip="QR Scan"                                                                                                                     
                                        onClick={() => this.scanCode()}>    
                                </Button> 
                            </span>                     
                        }
                        </h1>
                        {
							    loading && <ProgressSpinner/> 
						}
                    
                        <Growl ref={(el) => this.growl = el} />
                        
                        <div>
                        <MobileView>                      
                            <DataTable id="vaults"
                                   value={this.state.dataTableValue} paginatorPosition="bottom"
                                   header={header} paginator={true} rows={10} responsive={true}                                   
                                   resizableColumns={true} 
                                   globalFilter={this.state.globalFilter} emptyMessage="No records found"
                                   ref={(el) => { this.dt = el; }} style={{fontSize:'75%'}}  
                                   dataKey="vaultId">                                                       
                                {featureList.includes(5) &&
                                    <Column body={this.actionTemplate.bind(this)} header="Actions" style={{width:'25%'}} />                               
                                }
                                <Column field="physicalLocation" header="Description" sortable={false}/>
                                <Column field="assetTag" header="Device ID" sortable={false}/>
                                <Column field="deviceId" header="Manufacturer Device ID" sortable={false}/>
                                <Column body={this.pinTemplate} header="Last Pin Requested" sortable={false}/>
                                <Column body={this.domainRowTemplate} header="Domains" sortable={false}/>
                            </DataTable>
                        </MobileView>
                        <BrowserView>
                        <DataTable id="vaults"
                                   value={this.state.dataTableValue} paginatorPosition="bottom"
                                   header={header} paginator={true} rows={10} responsive={true}
                                   selection={this.state.dataTableSelection} selectionMode="single"
                                   resizableColumns={true} autoLayout
                                   globalFilter={this.state.globalFilter} emptyMessage="No records found"
                                   ref={(el) => { this.dt = el; }}
                                   dataKey="vaultId">
                          
                          {featureList.includes(5) &&
                            <Column body={this.actionTemplate.bind(this)} header="Actions" style={{textAlign:'center',width:'180px'}}/>                                                      
                          }
                            <Column body={this.imageTemplate} style={{width: '4em', textAlign: 'left',margin:0,padding:0}}/>
                            <Column field="physicalLocation" header="Description" sortable={true} headerStyle={{textAlign: "left"}}/>
                            <Column field="assetTag" header="Device ID" sortable={true} headerStyle={{textAlign: "left"}}/>
                            <Column field="deviceId" header="Manufacturer Device ID" sortable={true} headerStyle={{textAlign: "left"}}/>
                            <Column body={this.pinTemplate} header="Last Pin Requested" sortable={true} headerStyle={{textAlign: "left"}}/>
                            <Column body={this.domainRowTemplate} header="Domains" sortable={true} headerStyle={{textAlign: "left"}}/>
                        </DataTable>
                        </BrowserView>
                        <Dialog header="Vault Details" visible={this.state.visible} width="225px" modal={true} onHide={() => this.setState({visible: false})}>
                            {this.renderVaultDialogContent()}
                        </Dialog>
                        </div>
                    </div>
                    <div>
                    <Dialog visible={this.state.displayDialogAddVault}
                            modal={true} header={<span><i className="pi pi-lock"/>Add Vault</span>}
                            responsive={true} 
                            onHide={() => this.setState({displayDialogAddVault: false})}>                            
                            <div className="p-grid p-fluid">
                                                                       
                                    {this.state.vaultDescriptionError && (
							           	<Message severity="error" text="Code Description is required" />              
							        )}  
                                    {this.state.vaultFirmwareError && (
                                        <Message severity="error" text="Vault Firmware is required"/>
                                    )}    
                                    {this.state.vaultAssetTagError && (
                                        <Message severity="error" text="Vault Asset Tag is required"/>
                                    )}
                                    {this.state.vaultPhysicalLocationError && (
                                        <Message severity="error" text="Vault Description is required"/>
                                    )}
                                    {this.state.vaultDeviceIdError && (
                                        <Message severity="error" text="Vault Mfg Device ID is required"/>
                                    )}   
                                    {this.state.vaultModelError && (
                                        <Message severity="error" text="Vault Model is required"/>
                                    )}  
                                
                            </div>                                                                                    
                            { vaultInput &&
                            <div className="p-grid p-fluid">
                                <div className="p-col-12 p-md-4 p-inputgroup">                                 
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-globe"></i>
                                    </span>                                        
                                    <InputText id="vaultDescription" 
                                               onChange={(e) => {this.updateProperty('vaultDescription', e.target.value)}}
                                            //    placeholder="Vault Description"  
                                               value={vaultInput.vaultDescription} 
                                               onFocus={this.handleFocus('vaultDescription')}
                                               className={
                                                            this.state.vaultDescriptionError ? 'p-error' : null
                                                        }/>
                                </div>
                                <div className="p-col-12 p-md-4 p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-info"></i>
                                    </span>
                                    <InputText id="vaultFirmware"
                                               onChange={(e) => {this.updateProperty('vaultFirmware', e.target.value)}}
                                               placeholder="Vault Firmware" value={vaultInput.vaultFirmware}
                                               onFocus={this.handleFocus('vaultFirmware')}
                                               className={
                                                            this.state.vaultFirmwareError ? 'p-error' : null
                                                        }/> 
                                </div>
                                <div className="p-col-12 p-md-4 p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-info"></i>
                                        </span>                       
                                        <InputText id="vaultAssetTag"
                                                   onChange={(e) => {this.updateProperty('vaultAssetTag', e.target.value)}}
                                                   placeholder="Vault Asset Tag" value={vaultInput.vaultAssetTag}
                                                   onFocus={this.handleFocus('vaultAssetTag')}
                                                   className={
                                                                this.state.vaultAssetTag ? 'p-error' : null
                                                            }/>  
                                </div>   
                                <div className="p-col-12 p-md-4 p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-info"></i>
                                        </span>
                                        <InputText  id="vaultPhysicalLocation"
                                                    onChange={(e) => {this.updateProperty('vaultPhysicalLocation', e.target.value)}}
                                                    placeholder="Description" value={vaultInput.vaultPhysicalLocation}
                                                    onFocus={this.handleFocus('vaultPhysicalLocation')}
                                                    className={
                                                                    this.state.vaultPhysicalLocation ? 'p-error' : null
                                                    } /> 
                                </div>
                                <div className="p-col-12 p-md-4 p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-info"></i>
                                        </span>
                                        <InputText  id="vaultDeviceId"
                                                    onChange={(e) => {this.updateProperty('vaultDeviceId', e.target.value)}}
                                                    placeholder="Vault Mfg Device ID" value={vaultInput.vaultDeviceId}
                                                    onFocus={this.handleFocus('vaultDeviceId')}
                                                    className={
                                                                    this.state.vaultDeviceId ? 'p-error' : null
                                                    } /> 
                                </div>
                                <div className="p-col-12 p-md-3 p-inputgroup">      
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-info"></i>
                                        </span>
                                        <Dropdown   value={vaultInput.vaultModel} options={vaultModels} 
                                                    onChange={(e) => {this.updateProperty('vaultModel', e.value)}}
                                                    itemTemplate={this.vaultModelTemplate} placeholder="Please Select Model"
                                                    filter={true} filterPlaceholder="Select Model" 
                                                    filterBy="label,value" showClear={false}/>
                                </div>    
                            </div>
                            }
                            <div className="p-grid" style={{textAlign:'right'}}>
                                <div className="p-col" style={{marginTop:'1em'}}>                                                                          
                                        <Button id="vaultCancel"
                                            type="button" icon="pi pi-times"
                                            style={{margin:'2px'}}                               
                                            className="p-button-raised p-button-rounded p-button-danger"
                                            tooltip="Cancel"
                                            label="Cancel"
                                            onClick={() => this.onCancel('add')}>
                                        </Button>  
                                </div>                                                              
                             </div>                           
                    </Dialog>
                    </div>
                    
                    {
                            vaultInput && 
                    <div>
                    {/* {console.log(this.state.vaultInput)} */}
                    
                    <Dialog visible={this.state.displayDialogEditVault}
                            modal={true} header={<span><img src={this.state.defaultMLImagePath + vaultInput.model + this.state.defaultImgExt}
                                                 alt="img"
                                                 width="60px"
                                                 height="60px"
                                                 style={{verticalAlign:"middle"}} />Editing {vaultInput.deviceId}</span>}
                            responsive={true} style={{width:'50%'}}
                            onHide={() => this.setState({displayDialogEditVault: false, codeLenError: false})}>                     
                             <div className="p-grid p-fluid">                                             
                                    {this.state.codeLenError && (
                                        <Message severity="error" text="Provided codes must be 4 to 8 numbers, no leading zeros"/>
                                    )}                                        
                            </div>  
                            <div>
                                <div className="card">
                                
                                <div className="p-grid  p-justify-start">
                                        <div className="p-col-8">                                                                                  
                                            <strong>Description:</strong>  {vaultInput.physicalLocation}                                              
                                        </div>
                                        <div className="p-col-4">
                                            <div className="box">
                                                <Button id="editNoLocale"
                                                        type="button" icon="pi pi-ban"                                                                             
                                                        className="p-button-raised p-button-rounded p-button-warning"                                                        
                                                        label="Remove Description"
                                                        tooltip="Click to remove current description"
                                                        onClick={() => this.onVaultProcess('remove')}>
                                                </Button>
                                            </div> 
                                        </div>
                                    </div>

                                    <div className="p-grid">
                                        <div className="p-col-12 p-inputgroup" style={{marginLeft: 10}}>
                                            {/* <span className="p-inputgroup-addon">
                                                <i className="pi pi-pencil"></i>
                                            </span> */}
                                            <InputText  id="vaultPhysicalLocationEdit"
                                                    onChange={(e) => {this.updateProperty('vaultPhysicalLocation', e.target.value)}}
                                                    placeholder="New Description" value={vaultInput.vaultPhysicalLocation}
                                                    onFocus={this.handleFocus('vaultPhysicalLocationEdit')}                                                   
                                                    style={{width:'75%'}} />                                                                    
                                        </div>                                                                                                                
                                    </div>
                                
                                </div>
                               
                              
                                <div className="card">
                                {/* {console.log(vaultInput)} */}

                                {(!vaultInput.model.includes("4400D")  && !vaultInput.model.includes("6440")) &&
                                <div>
                                {vaultInput.daysPending ?
                                        <div className="p-grid">
                                            <div className="p-col-6 p-offset-3"><h1 style={{textAlign:"center"}}>Access Codes</h1></div>
                                            <div className="p-col" style={{fontSize:"80%"}}>
                                                Days Changes Pending: {vaultInput.daysPending}
                                            </div>
                                        </div>                                                                        
                                    : <h1 style={{textAlign:"center"}}>Access Codes</h1>                                    
                                    }
                                </div>
                                }    
                                {(!vaultInput.model.includes("4400D")  && !vaultInput.model.includes("6440")) &&
                                    <div className="card">                                    
                                    <table className="codeInputs">
                                        <thead>
                                            <th>Code Type</th>
                                            <th>Code Description</th>
                                            <th>On Vault</th>
                                            <th>Pending Update</th>
                                            <th></th>
                                        </thead>
                                        <tbody>
                                            <tr><td colSpan={5}><div style={{borderBottom: "1px solid #EEEEEE", marginBottom:2}}></div></td></tr>
                                            <tr>
                                                <td>Master Code</td>
                                                <td className="inputFields">                                                    
                                                    <InputText  id="primaryCodeDescriptionEdit"
                                                    onChange={(e) => {this.updateProperty('primaryCodeDescription', e.target.value)}}
                                                    value={vaultInput.primaryCodeDescription}
                                                    onFocus={this.handleFocus('primaryCodeDescriptionEdit')}
                                                    tooltip={vaultInput.primaryCodeDescription ? "Click to edit" : 
                                                    "Click to create"}
                                                    />
                                                </td>
                                                <td>{vaultInput.primaryCode ? vaultInput.primaryCode : "-none-"}</td>
                                                <td className="inputFields">
                                                {this.state.showVPCError ? 
                                                    <span style={{color:"red",fontSize:"80%",marginRight:"5px"}}>No leading zeros</span>
                                                 : null
                                                }                                                  
                                                <InputText  id="primaryCodeEdit"
                                                    onChange={(e) => {this.updateProperty('pendingPrimaryCode', e.target.value, true)}}
                                                    placeholder={vaultInput.pendingPrimaryCode ? vaultInput.pendingPrimaryCode : "Click to enter code"}
                                                    value={vaultInput.pendingPrimaryCode}
                                                    onFocus={this.handleFocus('primaryCodeEdit')}
                                                    keyfilter="pint"
                                                    maxLength={8}                                                    
                                                    tooltip={vaultInput.pendingPrimaryCode ? "Click to edit" :  "Click to create"}                                             
                                                    />   
                                                </td>
                                                <td style={{border: 0}}></td>
                                            </tr>
                                            <tr><td colSpan={5}><div style={{borderBottom: "1px solid #EEEEEE", marginBottom:2}}></div></td></tr>
                                            <tr>
                                            <td>Code 1</td>
                                                <td className="inputFields">                                                  
                                                    <InputText  id="code1DescriptionEdit"
                                                        onChange={(e) => {this.updateProperty('code1Description', e.target.value)}}                                                        
                                                        value={vaultInput.code1Description}
                                                        onFocus={this.handleFocus('code1DescriptionEdit')}                                                                                                                
                                                        tooltip={this.state.code1Delete ? "" : vaultInput.code1Description ? "Click to edit" :
                                                                 "Click to create"}
                                                        />      
                                                  </td>
                                                <td>{vaultInput.code1 ? vaultInput.code1 : "-none-"}</td>
                                                <td className="inputFields">
                                                {this.state.showCode1Error ? 
                                                    <span style={{color:"red",fontSize:"80%",marginRight:"5px"}}>No leading zeros</span>
                                                : null
                                                }      
                                                                                           
                                                 <InputText  id="code1Edit"
                                                        onChange={(e) => {this.updateProperty('pendingCode1', e.target.value, true)}}                                                        
                                                        placeholder={this.state.pendingCode1Delete === 1 ? "Pending Delete" 
                                                                     : this.state.pendingCode1Delete === true ? "Pending Delete"
                                                                     : vaultInput.pendingCode1 ? vaultInput.pendingCode1 : "Click to enter code" }                                                        
                                                        value={vaultInput.pendingCode1}                                                       
                                                        onFocus={this.handleFocus('code1Edit')}
                                                        keyfilter="pint"
                                                        maxLength={8}
                                                        disabled={this.state.pendingCode1Delete === true}
                                                        tooltip={vaultInput.pendingCode1 ? "Click to edit" :  "Click to create"} 
                                                        />    
                                                </td>
                                                
                                                {vaultInput.code1 ? 
                                                
                                                <td> <Checkbox style={{marginRight: 2}} 
                                                        onChange={e => {
                                                                        this.setState({pendingCode1Delete: e.checked, 
                                                                                       dirty: true,
                                                                                       codeLenError: false});

                                                                        this.updateProperty('pendingCode1', "");                                                                                                                                               
                                                                        }
                                                                }                                                           
                                                        checked={this.state.pendingCode1Delete}></Checkbox>Delete</td>
                                                        : <td></td>
                                                }
                                            </tr>
                                            <tr><td colSpan={5}><div style={{borderBottom: "1px solid #EEEEEE", marginBottom:2}}></div></td></tr>
                                            <tr>
                                            <td>Code 2</td>
                                                <td className="inputFields">
                                                    <InputText  id="code2DescriptionEdit"
                                                        onChange={(e) => {this.updateProperty('code2Description', e.target.value)}}                                                        
                                                        value={vaultInput.code2Description}
                                                        onFocus={this.handleFocus('code2DescriptionEdit')}                                                                                                                
                                                        tooltip={this.state.code2Delete ? "" : vaultInput.code2Description ? "Click to edit" :
                                                                 "Click to create"}
                                                        />      
                                                  </td>
                                                  <td>{vaultInput.code2 ? vaultInput.code2 : "-none-"}</td>
                                                <td className="inputFields">
                                                {this.state.showCode2Error ? 
                                                    <span style={{color:"red",fontSize:"80%",marginRight:"5px"}}>No leading zeros</span>
                                                : null
                                                }
                                                <InputText  id="code2Edit"
                                                        onChange={(e) => {this.updateProperty('pendingCode2', e.target.value, true)}}                                                        
                                                        placeholder={this.state.pendingCode2Delete === 1 ? "Pending Delete" 
                                                                     : this.state.pendingCode2Delete === true ? "Pending Delete"
                                                                     : vaultInput.pendingCode2 ? vaultInput.pendingCode2 : "Click to enter code" }  
                                                        value={vaultInput.pendingCode2}                                                       
                                                        onFocus={this.handleFocus('code2Edit')}
                                                        keyfilter="pint"
                                                        maxLength={8}
                                                        disabled={this.state.pendingCode2Delete === true}
                                                        tooltip={vaultInput.pendingCode2 ? "Click to edit" :  "Click to create"} 
                                                        />   
                                                </td>
                                                {vaultInput.code2  ? 
                                                <td> <Checkbox style={{marginRight: 2}} 
                                                        onChange={e => {
                                                                        this.setState({pendingCode2Delete: e.checked, 
                                                                                            dirty: true,
                                                                                            codeLenError: false});
                                                                        this.updateProperty('pendingCode2', "");
                                                                      }
                                                                        }                                                           
                                                        checked={this.state.pendingCode2Delete}></Checkbox>Delete</td>
                                                :        
                                                        <td></td>
                                                }
                                            </tr>
                                            <tr><td colSpan={5}><div style={{borderBottom: "1px solid #EEEEEE", marginBottom:2}}></div></td></tr>
                                            <tr>
                                            <td>Code 3</td>
                                            <td className="inputFields">
                                                <InputText  id="code3DescriptionEdit"
                                                        onChange={(e) => {this.updateProperty('code3Description', e.target.value)}}                                                        
                                                        value={vaultInput.code3Description}
                                                        onFocus={this.handleFocus('code3DescriptionEdit')}                                                        
                                                        tooltip={this.state.code3Delete ? "" : vaultInput.code3Description ? "Click to edit" :
                                                                 "Click to create"}
                                                        />      
                                            </td>
                                            <td>{vaultInput.code3 ? vaultInput.code3 : "-none-"}</td>
                                            <td className="inputFields">
                                                {this.state.showCode3Error ? 
                                                    <span style={{color:"red",fontSize:"80%",marginRight:"5px"}}>No leading zeros</span>
                                                : null
                                                }
                                                <InputText  id="code3Edit"
                                                        onChange={(e) => {this.updateProperty('pendingCode3', e.target.value, true)}}                                                        
                                                        placeholder={this.state.pendingCode3Delete === 1 ? "Pending Delete" 
                                                                     : this.state.pendingCode3Delete === true ? "Pending Delete"
                                                                     : vaultInput.pendingCode3 ? vaultInput.pendingCode3 : "Click to enter code" }  
                                                        value={vaultInput.pendingCode3}                                                       
                                                        onFocus={this.handleFocus('code3Edit')}
                                                        keyfilter="pint"
                                                        maxLength={8}
                                                        disabled={this.state.pendingCode3Delete === true}
                                                        tooltip={vaultInput.pendingCode3 ? "Click to edit" :  "Click to create"} 
                                                        /> 
                                                </td>
                                                {vaultInput.code3  ? 
                                                <td> <Checkbox style={{marginRight: 2}} 
                                                        onChange={e => {
                                                                        this.setState({pendingCode3Delete: e.checked, 
                                                                                            dirty: true,
                                                                                            codeLenError: false});
                                                                        this.updateProperty('pendingCode3', "");
                                                                      }
                                                                        }                                                           
                                                        checked={this.state.pendingCode3Delete}></Checkbox>Delete</td>
                                                :        
                                                        <td></td>
                                                }
                                            </tr>
                                            <tr><td colSpan={5}><div style={{borderBottom: "1px solid #EEEEEE", marginBottom:2}}></div></td></tr>
                                            <tr>
                                            <td>Code 4</td>
                                            <td className="inputFields">
                                                    <InputText  id="code4DescriptionEdit"
                                                        onChange={(e) => {this.updateProperty('dode4Description', e.target.value)}}
                                                        value={vaultInput.code4Description}
                                                        onFocus={this.handleFocus('code4DescriptionEdit')}                                                        
                                                        tooltip={this.state.code4Delete ? "" : vaultInput.code4Description ? "Click to edit" :
                                                                 "Click to create"}
                                                        />      
                                                  </td>
                                                  <td>{vaultInput.code4 ? vaultInput.code4 : "-none-"}</td>
                                                  <td className="inputFields">
                                                {this.state.showCode4Error ? 
                                                    <span style={{color:"red",fontSize:"80%",marginRight:"5px"}}>No leading zeros</span>
                                                : null
                                                }
                                                <InputText  id="code4Edit"
                                                        onChange={(e) => {this.updateProperty('pendingCode4', e.target.value, true)}}                                                        
                                                        placeholder={this.state.pendingCode4Delete === 1 ? "Pending Delete" 
                                                                     : this.state.pendingCode4Delete === true ? "Pending Delete"
                                                                     : vaultInput.pendingCode4 ? vaultInput.pendingCode4 : "Click to enter code" }  
                                                        value={vaultInput.pendingCode4}                                                       
                                                        onFocus={this.handleFocus('code4Edit')}
                                                        keyfilter="pint"
                                                        maxLength={8}
                                                        disabled={this.state.pendingCode4Delete === true}
                                                        tooltip={vaultInput.pendingCode4 ? "Click to edit" :  "Click to create"} 
                                                        />    
                                                </td>
                                                { vaultInput.code4 ?
                                                <td> <Checkbox style={{marginRight: 2}} 
                                                        onChange={e => {
                                                                        this.setState({pendingCode4Delete: e.checked, 
                                                                                            dirty: true,
                                                                                            codeLenError: false});
                                                                        this.updateProperty('pendingCode4', "")}
                                                                        }                                                           
                                                        checked={this.state.pendingCode4Delete}></Checkbox>Delete</td>
                                                        : <td></td>
                                                }
                                            </tr>
                                            <tr><td colSpan={5}><div style={{borderBottom: "1px solid #EEEEEE", marginBottom:2}}></div></td></tr>
                                            <tr>
                                            <td>Code 5</td>
                                            <td className="inputFields">
                                                    <InputText  id="code5DescriptionEdit"
                                                        onChange={(e) => {this.updateProperty('code5Description', e.target.value)}}                                                       
                                                        value={vaultInput.code5Description}
                                                        onFocus={this.handleFocus('code5DescriptionEdit')}                                                        
                                                        tooltip={this.state.code5Delete ? "" : vaultInput.code5Description ? "Click to edit" :
                                                                 "Click to create"}
                                                        />      
                                                  </td>
                                                  <td>{vaultInput.code5 ? vaultInput.code5 : "-none-"}</td>
                                                  <td className="inputFields">
                                                {this.state.showCode5Error ? 
                                                    <span style={{color:"red",fontSize:"80%",marginRight:"5px"}}>No leading zeros</span>
                                                : null
                                                }
                                              
                                                <InputText  id="code5Edit"
                                                        onChange={(e) => {this.updateProperty('pendingCode5', e.target.value, true)}}                                                        
                                                        placeholder={this.state.pendingCode5Delete === 1 ? "Pending Delete" 
                                                                     : this.state.pendingCode5Delete === true ? "Pending Delete"
                                                                     : vaultInput.pendingCode5 ? vaultInput.pendingCode5 : "Click to enter code" }  
                                                        value={vaultInput.pendingCode5}                                                       
                                                        onFocus={this.handleFocus('code5Edit')}
                                                        keyfilter="pint"
                                                        maxLength={8}
                                                        disabled={this.state.pendingCode5Delete === true}
                                                        tooltip={vaultInput.pendingCode5 ? "Click to edit" :  "Click to create"} 
                                                        />     
                                                </td>
                                                {vaultInput.code5 ? 
                                                <td> <Checkbox style={{marginRight: 2}} 
                                                        onChange={e => {
                                                                        this.setState({pendingCode5Delete: e.checked, 
                                                                                            dirty: true,
                                                                                            codeLenError: false});
                                                                        this.updateProperty('pendingCode5', "")}
                                                                        }                                                           
                                                        checked={this.state.pendingCode5Delete}></Checkbox>Delete</td>
                                                :
                                                <td></td>
                                                }
                                            </tr>
                                        </tbody>
                                        </table>                                
                                    </div>
                                
                                }
                                                              
                                    
                                    <div className="p-grid p-justify-end" style={{marginTop: "4px"}}>
                                      <div className="p-col-2">
                                        <div className="box">
                                            <Button id="editVault"
                                                    type="button" icon="pi pi-check"                                                        
                                                    className="p-button-raised p-button-rounded p-button-success"
                                                    tooltip="Submit Request"
                                                    label="Submit"
                                                    onClick={() => this.onVaultProcess('edit')}
                                                    disabled={!dirty}>
                                            </Button>  
                                        </div>
                                      </div>
                                      <div className="p-col-4">
                                        <div className="box">
                                            <Button id="editCancel"
                                                    type="button" icon="pi pi-times"                                                                             
                                                    className="p-button-raised p-button-rounded p-button-danger"                                                        
                                                    label="Cancel"
                                                    onClick={() => this.onCancel('edit')}>
                                            </Button>
                                        </div> 
                                      </div> 
                                      
                                    </div>
                                </div>
                            </div>
                    </Dialog>
                    </div>
                    }
                   <BrowserView>
                    <div>
                    <Dialog visible={this.state.displayDialogDomainAssignments}
                            modal={true} header="Domain Assignments" style={{width:"90%"}}                            
                            onHide={() => this.setState({displayDialogDomainAssignments: false})}>
                        
                        {
                            domainPickSource &&
                                 
                            <div className="card">
                            <div className="p-grid p-fluid">    
                            {this.state.vaultToDomainError && (
                                <Message severity="error" text="A vault must have at least one domain assigned" />              
                            )} 
                        </div> 
                                    <div className="p-grid p-align-center p-fluid">
                                        <div className="p-col-fixed" style={{width:'62px'}}>
                                            <div className="box">    
                                                <img src={this.state.defaultMLImagePath + vault.model + this.state.defaultImgExt}
                                                     alt="img"
                                                     width="60px"
                                                     height="60px" />
                                            </div>
                                        </div>
                                        <div className="p-col">
                                            <div className="box"><h1>{vault.vaultDescription}</h1></div>
                                        </div>
                                        <div className="p-col">
                                            <div className="p-grid">
                                                <div className="p-col">
                                                    <Button id="domainAssignAdd"
                                                        type="button" icon="pi pi-check"                                                        
                                                        className="p-button-raised p-button-rounded p-button-success"
                                                        tooltip="Create assignments"
                                                        label="Assign"
                                                        onClick={() => this.onDomainAssignmentAdd(vault.vaultId)}
                                                        disabled={!dirty}>
                                                    </Button>  
                                                </div>
                                                <div className="p-col">
                                                    <Button id="domainAssignCancel"
                                                        type="button" icon="pi pi-times"                                                                             
                                                        className="p-button-raised p-button-rounded p-button-danger"                                                        
                                                        label="Cancel"
                                                        onClick={() => this.onCancel('addAssign')}>
                                                    </Button>
                                                </div>
                                            </div>  
                                        </div>                                        
                                    </div>
                                    <div className="p-col">
                                            <PickList source={domainPickSource}
                                                      target={domainPickTarget}                                                       
                                                      sourceHeader="Available" 
                                                      targetHeader="Selected"
                                                      itemTemplate={this.domainTemplate} 
                                                      responsive={true} 
                                                      sourceStyle={{height: '300px'}} 
                                                      targetStyle={{height: '300px'}}
                                                      onChange={this.onDomainPickChange}>
                                            </PickList>                                                                             
                                    </div>
                                </div>
                        }
                    </Dialog>
                    </div>
                    </BrowserView>
                    
                    <div> 
                    <Dialog visible={this.state.displayDialogFetchTempCode}
                            modal={true} header={<span><i className="pi pi-unlock"/>Temp Code</span>}
                            responsive={true} style={{width:'90%'}}
                            onHide={() => this.setState({displayDialogFetchTempCode: false})}>                                                   
                       <div className="p-col-12" style={{padding: '2em', borderBottom: '1px solid #d9d9d9'}}>                                       
                            {tempCode}
                        </div>                    
                    </Dialog>
                    </div>

                    <div>
                    <MobileView>
                    <Dialog visible={this.state.displayDialogTempCodeReason}
                            modal={true} header={<span  style={{fontSize:"80%"}}><i className="pi pi-unlock"/>Temp Code Request Justification</span>}
                            responsive={true} 
                            onHide={() => this.setState({displayDialogTempCodeReason: false})}>                                               
                        <div className="p-grid p-fluid">    
                            {this.state.tempCodeJustificationError && (
                                <Message severity="error" text="You must provide a justification" />              
                            )} 
                        </div>       
                        <div className="p-col-12" style={{padding: '2em', borderBottom: '1px solid #d9d9d9'}}>                                                                  
                            <div>Please provide a reason for this request</div>
                                <div className="p-grid">                                
                                <div className="p-col-12 p-md-12 p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-pencil"></i>
                                        </span> 
                                        <InputTextarea rows={5} cols={30} value={tempCodeJustification} 
                                                       onChange={(e) => {this.updateProperty('tempCodeJustification', e.target.value)}} 
                                                       onFocus={this.handleFocus('tempCodeJustification')}
                                                       placeholder="Justification" id="tempCodeJustification"
                                                       />                                                              
                                </div>                                 
                                <div className="p-col">
                                    <Button id="tempCodeReasonAdd"
                                        type="button" icon="pi pi-check"                                                        
                                        className="p-button-raised p-button-rounded p-button-success"
                                        tooltip="Submit Request"
                                        label="Submit"
                                        onClick={() => this.renderTempCode(vault)}
                                        disabled={!dirty}>
                                    </Button>  
                                </div>
                                <div className="p-col">
                                    <Button id="tempCodeReasonCancel"
                                        type="button" icon="pi pi-times"                                                                             
                                        className="p-button-raised p-button-rounded p-button-danger"                                                        
                                        label="Cancel"
                                        onClick={() => this.onCancel('tempCodeReason')}>
                                    </Button>
                                </div>                    
                            </div>                                                      
                        </div>
                    </Dialog>
                    </MobileView>
                    <BrowserView>
                    <Dialog visible={this.state.displayDialogTempCodeReason}
                            modal={true} header={<span><i className="pi pi-unlock"/>Temp Code Request Justification</span>}
                            responsive={true} 
                            onHide={() => this.setState({displayDialogTempCodeReason: false})}>                                               
                        <div className="p-grid p-fluid">    
                            {this.state.tempCodeJustificationError && (
                                <Message severity="error" text="You must provide a justification" />              
                            )} 
                        </div>       
                        <div className="p-col-12" style={{padding: '2em', borderBottom: '1px solid #d9d9d9'}}>                                                                  
                            <div>Please provide a reason for this request</div>
                                <div className="p-grid">                                
                                <div className="p-col-12 p-md-12 p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-pencil"></i>
                                        </span> 
                                        <InputTextarea rows={5} cols={30} value={tempCodeJustification} 
                                                       onChange={(e) => {this.updateProperty('tempCodeJustification', e.target.value)}} 
                                                       onFocus={this.handleFocus('tempCodeJustification')}
                                                       placeholder="Justification" id="tempCodeJustification"
                                                       />                                                              
                                </div>                                 
                                <div className="p-col">
                                    <Button id="tempCodeReasonAdd"
                                        type="button" icon="pi pi-check"                                                        
                                        className="p-button-raised p-button-rounded p-button-success"
                                        tooltip="Submit Request"
                                        label="Submit"
                                        onClick={() => this.renderTempCode(vault)}
                                        disabled={!dirty}>
                                    </Button>  
                                </div>
                                <div className="p-col">
                                    <Button id="tempCodeReasonCancel"
                                        type="button" icon="pi pi-times"                                                                             
                                        className="p-button-raised p-button-rounded p-button-danger"                                                        
                                        label="Cancel"
                                        onClick={() => this.onCancel('tempCodeReason')}>
                                    </Button>
                                </div>                    
                            </div>                                                      
                        </div>
                    </Dialog>
                    </BrowserView>
                    </div>
                    
                    <div>
                    <MobileView>
                    <Dialog visible={this.state.displayDialogOpenCloseCodesReason}
                            modal={true} header={<span style={{fontSize:"85%"}}><i className="pi pi-tags"/>Morning/Night Codes Request</span>}
                            responsive={true} 
                            onHide={() => this.setState({displayDialogOpenCloseCodesReason: false})}>                            
                        
                        <div className="p-grid p-fluid">    
                            {this.state.tempCodeJustificationError && (
                                <Message severity="error" text="You must provide a justification" />              
                            )} 
                        </div>                          
                        <div className="p-col-12" style={{padding: '2em', borderBottom: '1px solid #d9d9d9'}}>                                                                                            
                            <div>Effective Date</div>                            
                            <div className="p-grid" style={{marginBottom: '1em'}}>                                 
                                <div className="p-col-12 p-md-12 p-inputgroup">                                   
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-calendar"></i>                                       
                                    </span>  
                                    <span  title="Click to adjust">
                                    <DateTime closeOnSelect={true} 
                                        name="openCloseCodesDate"                                                  
                                        className="p-inputtext p-container dpFill-width" 
                                        inputProps={{ placeholder: 'Effective Date'}}                                                  
                                        onChange={(e) => this.handleDate(e,'openCloseCodesDate')}
                                        timeFormat={false}                                        
                                        defaultValue={new Date()}/>
                                    </span>                                                                      
                                </div>
                            </div>
                            <div>Please provide a reason for this request</div>
                                <div className="p-grid">                                
                                <div className="p-col-12 p-md-12 p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-pencil"></i>
                                        </span> 
                                        <InputTextarea rows={5} cols={30} value={tempCodeJustification} 
                                                       onChange={(e) => {this.updateProperty('tempCodeJustification', e.target.value)}} 
                                                       onFocus={this.handleFocus('tempCodeJustification')}
                                                       placeholder="Justification" id="tempCodeJustification"
                                                       />                                                              
                                </div>                                 
                                <div className="p-col">
                                    <Button id="tempCodeReasonAdd"
                                        type="button" icon="pi pi-check"                                                        
                                        className="p-button-raised p-button-rounded p-button-success"
                                        tooltip="Submit Request"
                                        label="Submit"
                                        onClick={() => this.renderOpenCloseCodes(vault)}
                                        disabled={!dirty}>
                                    </Button>  
                                </div>
                                <div className="p-col">
                                    <Button id="tempCodeReasonCancel"
                                        type="button" icon="pi pi-times"                                                                             
                                        className="p-button-raised p-button-rounded p-button-danger"                                                        
                                        label="Cancel"
                                        onClick={() => this.onCancel('openCloseCodesReason')}>
                                    </Button>
                                </div>                    
                            </div>                                                      
                        </div>
                    </Dialog>
                    </MobileView>
                    <BrowserView>
                    <Dialog visible={this.state.displayDialogOpenCloseCodesReason}
                            modal={true} header={<span><i className="pi pi-tags"/>Morning/Night Codes Request</span>}
                            responsive={true} 
                            onHide={() => this.setState({displayDialogOpenCloseCodesReason: false})}>                            
                        
                        <div className="p-grid p-fluid">    
                            {this.state.tempCodeJustificationError && (
                                <Message severity="error" text="You must provide a justification" />              
                            )} 
                        </div>                          
                        <div className="p-col-12" style={{padding: '2em', borderBottom: '1px solid #d9d9d9'}}>                                                                                            
                            <div>Effective Date</div>                            
                            <div className="p-grid" style={{marginBottom: '1em'}}>                                 
                                <div className="p-col-12 p-md-12 p-inputgroup">                                   
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-calendar"></i>                                       
                                    </span>  
                                    <span  title="Click to adjust">
                                    <DateTime closeOnSelect={true} 
                                        name="openCloseCodesDate"                                                  
                                        className="p-inputtext p-container dpFill-width" 
                                        inputProps={{ placeholder: 'Effective Date'}}                                                  
                                        onChange={(e) => this.handleDate(e,'openCloseCodesDate')}
                                        timeFormat={false}                                        
                                        defaultValue={new Date()}/>
                                    </span>                                                                      
                                </div>
                            </div>
                            <div>Please provide a reason for this request</div>
                                <div className="p-grid">                                
                                <div className="p-col-12 p-md-12 p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-pencil"></i>
                                        </span> 
                                        <InputTextarea rows={5} cols={30} value={tempCodeJustification} 
                                                       onChange={(e) => {this.updateProperty('tempCodeJustification', e.target.value)}} 
                                                       onFocus={this.handleFocus('tempCodeJustification')}
                                                       placeholder="Justification" id="tempCodeJustification"
                                                       />                                                              
                                </div>                                 
                                <div className="p-col">
                                    <Button id="tempCodeReasonAdd"
                                        type="button" icon="pi pi-check"                                                        
                                        className="p-button-raised p-button-rounded p-button-success"
                                        tooltip="Submit Request"
                                        label="Submit"
                                        onClick={() => this.renderOpenCloseCodes(vault)}
                                        disabled={!dirty}>
                                    </Button>  
                                </div>
                                <div className="p-col">
                                    <Button id="tempCodeReasonCancel"
                                        type="button" icon="pi pi-times"                                                                             
                                        className="p-button-raised p-button-rounded p-button-danger"                                                        
                                        label="Cancel"
                                        onClick={() => this.onCancel('openCloseCodesReason')}>
                                    </Button>
                                </div>                    
                            </div>                                                      
                        </div>
                    </Dialog>
                    </BrowserView>
                    </div>
                    
                    <div>                   
                    <Dialog visible={this.state.displayDialogOpenCloseCodesResults}
                            modal={true} header={<span><i className="pi pi-unlock"/>Morning/Night Codes</span>}
                           
                            onHide={() => this.setState({displayDialogOpenCloseCodesResults: false})}>                                                   
                       <div className="p-col-12" style={{padding: '2em', borderBottom: '1px solid #d9d9d9'}}>                                       
                            <div className="p-col-12">Date Codes Effective: <strong>{tempCodeEffectiveDate}</strong></div>
                            <div className="p-col-12">{tzMorningLabel}: <strong>{tempCodeOpen}</strong></div>
                            <div className="p-col-12">{tzNightLabel}: <strong>{tempCodeClose}</strong></div>
                            {/* {(window.navigator.share  && */}
                            <div className="p-col">
                                <Button id="shareButton"
                                        type="button" icon="pi pi-share-alt"                                                                             
                                        className="p-button-raised p-button-rounded p-button-warning"                                                        
                                        label="Share"
                                        onClick={() => this.onShare()}>
                                </Button>
                            </div>   
                            {/* )} */}
                        </div>                    
                    </Dialog>
                    </div>

                    
                </div>
            </div>
        );
    }
}