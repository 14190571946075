import React, {Component} from 'react';

import {vaultService} from '../services/VaultServices';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Growl} from 'primereact/growl';
import {ProgressSpinner} from 'primereact/progressspinner';
import DateTime from 'react-datetime';
import moment from 'moment';
import 'moment-timezone';
import {MobileView, BrowserView} from 'react-device-detect';
import ObjectUtils from 'primereact/components/utils/ObjectUtils';

export class UserActivities extends Component {

    constructor() {
        super();
        this.state = {
            dataTableValue:[],
            panelCollapsed: true,
            defaultMLImagePath: 'assets/images/masterlock/',
            defaultImgExt: '.svg',            
            totalRecords: 0,
            vault:null,
            dirty: false,
            user: null,            
            selectedVault: null, 
            visible: false,
            sortKey: null,
            sortOrder: null,
            domainList: [],
            expandedRows: null,
            auditStartDate: null,
            auditEndDate: null,
            orgTimeZone: null            
        };

        this.export = this.export.bind(this);        
        this.onSortFunction = this.onSortFunction.bind(this);
        this.endDateRef = React.createRef();
    }

    componentDidMount() {
        let user = sessionStorage.getItem("user");
        if (user) {
                // console.log(user);
                let x = JSON.parse(user);
                this.setState(() => ({
                userId: x.userId,
                token: x.token,
                domainId: x.domainId
                }));
                
                if (!x.acceptedEula || x.userRole === 3){
                    this.props.handleLogout();
                  } else{
                this.setState({ loading: true });
                
                vaultService.fetchUserEvents(x.userId, x.token).then(data => this.setState({dataTableValue: data.itemResult,
                                                                                            totalRecords: data.itemResult.length,
                                                                                            orgTimeZone: 
                                                                                            data.itemResult.length ? 
                                                                                             data.itemResult[0].orgTimeZone
                                                                                            : "NONE FOUND",
                                                                                             loading: false}));                 
                  }
        }                   
    }    

    export() {

        this.dt.exportCSV();
    }

    handleDate(date,which){
        //console.log(this.state.auditEndDate);                               
           
            if (which === 'startdate'){
                 
                if(this.state.auditEndDate != null){
                    this.setState({ auditStartDate : date,
                                    dirty: true});
                } else {
                    this.setState({auditStartDate: date});
                }
                
                this.setState({ auditStartDate : date});
            } else {
                if(this.state.auditStartDate != null){
                    if(date < this.state.auditStartDate){
                        alert("The end search date must be greater than or equal to the start date\nPlease correct");                                                
                        this.endDateRef.current.setState({inputValue: null});                        
                        this.resetDataTable();                        
                    } else {
                        this.setState({ auditEndDate : date,
                                        dirty: true}); 
                    }                    
                } else {
                    this.setState({ auditEndDate : date}); 
                }                
            }
            
    }
    async  updateDataTable() {
        
        try {
            this.setState({ loading: true });
            let userId = this.state.userId;
            let token = this.state.token;
            let startDate = this.state.auditStartDate;
            let endDate = this.state.auditEndDate;

            await vaultService.fetchUserEvents(userId, token, startDate, endDate).then(data => this.setState({dataTableValue: data.itemResult,
                                                                                                               totalRecords: data.itemResult.length}));
            this.setState({loading: false});
            
          } catch (e) {
            alert(e);                    
            this.setState({loading: false});             
          }        
    }

    async  resetDataTable() {
        
        try {
            this.setState({ loading: true,
                            auditStartDate: null,
                            auditEndDate: null });
            let userId = this.state.userId;
            let token = this.state.token;
           

            await vaultService.fetchUserEvents(userId, token).then(data => this.setState({dataTableValue: data.itemResult,
                                                                                          totalRecords: data.itemResult.length}));
            this.setState({loading: false,
                           dirty: false});
            
          } catch (e) {
            alert(e);                    
            this.setState({loading: false,
                           dirty: false});             
          }        
    }   
           
    renderHeader() {
       
        return (
            <div className="p-grid">
                <div className="p-col" style={{textAlign: 'left'}}>                                                                                                                                             
                    <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})} 
                               placeholder="Global Search" size="25"/>                    
                </div>
                <BrowserView>
                <div className="p-col">
                    <Button type="button" icon="pi pi-external-link" 
                                iconPos="left" label="Export" 
                                onClick={this.export}>
                    </Button>
                </div>
                </BrowserView>
                <div className="p-col" style={{paddingTop: "1em"}}>
                    Total Events: {this.state.totalRecords}
                </div>               
            </div>
        );
    }   
    
    onSortFunction(event) {
        
        var value = [...this.state.dataTableValue];
        value.sort((data1, data2) => {
            let value1 = ObjectUtils.resolveFieldData(data1, event.field);
            let value2 = ObjectUtils.resolveFieldData(data2, event.field);
            let result = null;

            if (value1 === null && value2 !== null)
                result = -1;
            else if (value1 !== null && value2 === null)
                result = 1;
            else if (value1 === null && value2 === null)
                result = 0;           
            else if (typeof value1 === 'string' && typeof value2 === 'string') {
    
                    // let test = value1.substr(2,1)                   
                    
                    // if(test === '-' || test === '/'){                      
                    //   //var date1 = moment(value1).format("DD/MM/YYYY HH:mm:ss");
                    //   //var date2 = moment(value2).format("DD/MM/YYYY HH:mm:ss");
                    //   //result = moment.duration(moment(value1).diff(moment(value2,'hours')));
                    //   result = moment(value1).diff(moment(value2));
                    // }else{
                    //   result = value1.localeCompare(value2);
                    // }
                    result = moment(value1).diff(moment(value2));
                
            }           
            else
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

        //    console.log("result: ", event.order * result)


            return (event.order * result);
        });

        return value;
    }


    // rowExpansionTemplate(data) {                         
    //     console.log("data: ", data);
    //     return  (
    //         <div>
    //         <div className="dataHeader" style={{width:'50%', marginBottom:'2px', textAlign:'center'}}>Additional Info </div>
                                                           
    //             {data.assetTag && (
    //                 <React.Fragment>
    //                 <div style={{width:'100%'}}>
    //                 <table style={{width:'50%'}}>
    //                     <tr>
    //                         <td className="innerDataHeader">Vault Id</td>
    //                         <td className="dataBody">{data.assetTag}</td>
    //                     </tr>
    //                 </table></div>
    //                 </React.Fragment>
    //             )
    //             }                                                        
    //         </div>            
    //     )        
    
    // }

    
    
/* =========================================================================================== */
    render() {
        const { loading, auditEndDate, auditStartDate, dirty, dataTableValue } = this.state;
        const header = this.renderHeader();     
        let test = dataTableValue;
        // console.log(test) 
       

/* =========================================================================================== */    
        return (
            <div className="flexi-grid">
                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1>                        
                        {
							    loading && <ProgressSpinner style={{width: '50px', height: '50px'}} 
                                                             strokeWidth="3"                                                             
                                                             animationDuration=".8s"/>
						}
                        <i className="pi pi-chart-bar"/>	User Activity</h1>
                        <Growl ref={(el) => this.growl = el} />
                        <MobileView>
                        <div className="p-col-4" style={{float: "left", margin: 0, padding:0}}>  All activities recorded in {this.state.orgTimeZone}</div> 
                        <div className="p-grid p-justify-center">                           
                            <div className="p-col-12 p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-calendar"></i>
                                </span>                       
                                <DateTime closeOnSelect={true} 
                                        name="auditStartDate"                                                  
                                        className="p-inputtext p-container dpFill-width" 
                                        inputProps={{ placeholder: 'Start Date'}}
                                        value={(auditStartDate==="1/1/1900" ? "" : auditStartDate)}
                                        onChange={(e) => this.handleDate(e,'startdate')}
                                        timeFormat={true}                                                  
                                        />
                            </div>
                            <div className="p-col-12 p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-calendar"></i>
                                </span>                       
                                <DateTime closeOnSelect={true} 
                                            name="auditEndDate"                                                  
                                            className="p-inputtext p-container dpFill-width" 
                                            inputProps={{ placeholder: 'End Date'}}
                                            value={(auditEndDate ==="1/1/1900" ? "" : auditEndDate)}
                                            onChange={(e) => this.handleDate(e,'enddate')}
                                            timeFormat={true}
                                            ref={this.endDateRef}                                                  
                                            />
                            </div>
                            <div className="p-col-8 p-justify-right p-inputgroup">
                                <Button id="filter"
                                    type="button" icon="pi pi-key"
                                    style={{margin:'2px'}}                               
                                    className="p-button-raised p-button-rounded p-button-success"
                                    tooltip="Filter Records"
                                    label="GO"
                                    onClick={() => this.updateDataTable()}
                                    disabled={dirty === false}>
                                </Button>
                                <Button id="clear"
                                    type="button" icon="pi pi-trash"
                                    style={{margin:'2px'}}                               
                                    className="p-button-raised p-button-rounded p-button-warning"
                                    tooltip="Clear Filter"
                                    label="CLEAR"
                                    onClick={() => this.resetDataTable()}
                                    disabled={dirty === false}>
                                </Button> 
                            </div>
                        </div>
                        </MobileView>
                        <BrowserView>
                        <div style={{float: "left", margin: 0, padding:0}}> All activities recorded in {this.state.orgTimeZone}</div> 
                        <div className="p-grid p-justify-center">                           
                            <div className="p-col-2 p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-calendar"></i>
                                </span>                       
                                <DateTime closeOnSelect={true} 
                                        name="auditStartDate"                                                  
                                        className="p-inputtext p-container" 
                                        style={{width: '100px'}}
                                        inputProps={{ placeholder: 'Start Date-Time'}}
                                        value={(auditStartDate==="1/1/1900" ? "" : auditStartDate)}
                                        onChange={(e) => this.handleDate(e,'startdate')}
                                        timeFormat={true}                                                  
                                        />
                            </div>
                            <div className="p-col-2 p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-calendar"></i>
                                </span>                       
                                <DateTime closeOnSelect={true} 
                                            name="auditEndDate"                                                  
                                            className="p-inputtext p-container dpFill-width" 
                                            inputProps={{ placeholder: 'End Date-Time'}}
                                            value={(auditEndDate ==="1/1/1900" ? "" : auditEndDate)}
                                            onChange={(e) => this.handleDate(e,'enddate')}
                                            timeFormat={true}
                                            ref={this.endDateRef}                                                                                              
                                            />
                            </div>
                            <div className="p-col-1 p-inputgroup">
                                <Button id="filter"
                                    type="button" icon="pi pi-key"
                                    style={{margin:'2px'}}                               
                                    className="p-button-raised p-button-rounded p-button-success"
                                    tooltip="Filter Records"
                                    label="GO"
                                    onClick={() => this.updateDataTable()}
                                    disabled={dirty === false}>
                                </Button>
                                <Button id="clear"
                                    type="button" icon="pi pi-trash"
                                    style={{margin:'2px'}}                               
                                    className="p-button-raised p-button-rounded p-button-warning"
                                    tooltip="Clear Filter"
                                    label="CLEAR"
                                    onClick={() => this.resetDataTable()}
                                    disabled={dirty === false}>
                                </Button> 
                            </div>
                        </div>
                        </BrowserView>
                        

                        <MobileView>
                        <div>
                        <DataTable id="userEvents"
                                   value={this.state.dataTableValue} paginatorPosition="bottom"
                                   header={header} paginator={true} rows={10} responsive={true}                                                                     
                                   globalFilter={this.state.globalFilter} emptyMessage="No records found"
                                   ref={(el) => { this.dt = el; }}    style={{fontSize:'75%'}}                                  
                                   dataKey="vaultId">                                                                                                              
                            <Column field="eventDate" header="Event Date" style={{width:"15%"}} sortable="custom" sortFunction={this.onSortFunction}/>
                            <Column field="event" header="Event Detail" sortable={true}/>
                            <Column field="userImpacted" header="User Impacted" sortable={true}/>
                            <Column field="activatedByUser" header="Triggered By" sortable={true}/>  
                            <Column field="orgTimeZone" style={{display:"none"}}/>
                            <Column body={this.rowExpansionTemplate} />                               
                        </DataTable>                        
                        </div>
                        </MobileView>

                        <BrowserView>                                                  
                        <div>                        
                        <DataTable id="userEvents"
                                   value={this.state.dataTableValue} paginatorPosition="bottom"
                                   header={header} paginator={true} rows={10} responsive={true}
                                   selection={this.state.dataTableSelection} selectionMode="single"
                                   resizableColumns={true} 
                                   globalFilter={this.state.globalFilter} emptyMessage="No records found"
                                   ref={(el) => { this.dt = el; }}
                                   dataKey="evtId">                                                                                                              
                            <Column field="eventDate" header="Event Date" style={{width:"15%",textAlign:"center"}} sortable="custom" sortFunction={this.onSortFunction}/>                                                        
                            <Column field="event" header="Event Detail" sortable={true} headerStyle={{textAlign:"left"}}/>
                            <Column field="userImpacted" header="User Impacted" sortable={true} headerStyle={{textAlign:"left"}}/>
                            <Column field="activatedByUser" header="Triggered By" sortable={true} headerStyle={{textAlign:"left"}}/> 
                            <Column field="orgTimeZone" style={{display:"none"}}/>                          
                        </DataTable>                        
                        </div>
                        </BrowserView>
                    </div>
                    

                    
                </div>
            </div>
        );
    }
}