import { config } from './config';
import axios from 'axios';

export const userService = {
    login,
    logout,
    authUser
    // fetchDeviceInfo,
    // fetchTempAccessCode
};

/*==========================================================================*/
async function login(login, password, orgId) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Accept': 'application/json',
                   'Content-Type': 'application/json'},        
        data: JSON.stringify({ login, password, orgId })
    };

    try {
        const response = await axios(config.apiUrl + '/users/authenticate', requestOptions)                       
        
        //console.log(response.data);

        sessionStorage.setItem('user', JSON.stringify(response.data))
        return true               
    } catch (error) {
        return false

    }

}
/*==========================================================================*/
function logout() {
   
    sessionStorage.removeItem('user');
    return true
   
}
/*==========================================================================*/
function  authUser() {
    // return authorization header with jwt token
    let user = sessionStorage.getItem('user');
        if(user){
            let x = JSON.parse(user);
            if (x.token){
                return true;
            }
            else {
                return false;
            }
        }
        else{
            return false;
        }
  
}

/*==========================================================================*/
