import React, {Component} from 'react';
import { userService } from '../services/AuthServices';
import {InputText} from 'primereact/inputtext';
import {Button} from "primereact/button";
import {Message} from 'primereact/message';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Growl} from 'primereact/growl';
import {BrowserView} from 'react-device-detect';
export default class Login extends Component {
	constructor(props){
		super(props);
		this.state = {
			orgId: "",
			orgIdError: "",
			username: "",
			usernameError: "",
			password: "",
			passwordError: "",
			authenticationError: "",
			loading: false,
			dirty: false,
			height: props.height, width:props.width
		  };
		  this.showError = this.showError.bind(this);
	}
	
	handleChange = name => event => {
		this.setState({
		  [name]: event.target.value,
		  dirty: true
		});
		
	};
	  
	handleFocus = name => event => {
	  var x = [name] + "Error";    
	  this.setState({[x]: ""});
	  this.setState({authenticationError: ""});  
	}
  
	validate = () => {
	  let isError = false;
	  const errors = {
		orgIdError: "",
		usernameError: "",
		passwordError: ""
	  };

	  if (this.state.orgId.length < 2) {
        isError = true;
        errors.orgIdError = "Invalid Organization Id";
	  }

 	  if (this.state.username.length < 5) {
		isError = true;
		errors.usernameError = "Invalid Username";
	  }
	  else if (this.state.username.indexOf("@") === -1){        
		isError = true;
		errors.usernameError = "Requires valid information";
	  }
  
	  if (this.state.password.length < 6) {
		  isError = true;
		  errors.passwordError = "Invalid password";
	  }
  
	  this.setState({
		...this.state,
		...errors
	  });
  
	  return isError;
	};
  
	onSubmit = async e => {
	  e.preventDefault();
	  
	  const err = this.validate();
	  
	  if (!err) {
		
		try {
		  this.setState({ loading: true });
		  let x = await userService.login(this.state.username, this.state.password, this.state.orgId);
		  if (x) {	
		 	  this.props.userHasAuthenticated(true);    
			  
		  	if(this.state.authenticationError.length > 0){
				this.setState({authenticationError:""});
		  	}
		  	this.setState({loading: false});
		  } else {
			  throw (e);
		  }
		  
		} catch (e) {
		   //alert(e);        
		  this.showError(); 
		  this.setState({authenticationError: 'Invalid credentials. Please try again'});
		  this.setState({ isAuthenticating: false });
		  this.setState({loading: false});		  
		}
	  }
	};
	showError = () =>  {
		let msg = {severity: 'error', summary: 'Error Message', detail: 'Validation failed'};
		this.growl.show(msg);
		this.setState({dirty:false})		;
	};

	componentWillMount() {
        // console.log("WINDOW : ",window);
          this.setState({height: window.innerHeight + 'px',width:window.innerWidth+'px'});
	}
	
	/*===============================================*/

	render() {
		const { loading, dirty } = this.state;	
		
		return  <div className="login-body">
					<Growl ref={(el) => this.growl = el} />
					<div className="login-panel"></div>
					<div className="login-content" style={{maxHeight:this.state.height, overflow:'auto'}}>						
						<BrowserView>
							<img src="assets/layout/images/logo-black.png" alt="babylon-layout"/>										
							<h1><span>SIGN IN</span></h1>										
						</BrowserView>
						
						
						{this.state.authenticationError &&
							<Message severity="error" text="Invalid Credentials" style={{float:'left'}}/> 							
      					}
						<form onSubmit={e => this.onSubmit(e)}>
							<div className="login-input-wrapper">
							{this.state.orgIdError && (
								<Message severity="error" text="Invalid Organization Id" style={{float:'right',marginBottom:'10px'}}/>              
							)}
								<span className="p-float-label">            
								<InputText
								id="orgId"              
								type="text"
								value={this.state.orgId}
								onChange={this.handleChange('orgId')}
         						onFocus={this.handleFocus('orgId')}
								className={
									this.state.orgIdError ? 'p-error' : null
								}
								/>								
								<label htmlFor="orgId" style={{ display: 'block' }}>
								Your Organization Id
								</label>
								</span>
          					</div>
							<div className="login-input-wrapper">
							{this.state.usernameError && (
								<Message severity="error" text="Invalid Login" style={{float:'right'}}/>              
							)}
								<span className="p-float-label">            
								<InputText
								id="username"              
								type="text"
								value={this.state.username}
								onChange={this.handleChange('username')}
         						onFocus={this.handleFocus('username')}
								className={
									this.state.usernameError ? 'p-error' : null
								}
								/>								
								<label htmlFor="username" style={{ display: 'block' }}>
								Your Login
								</label>
								</span>
          					</div>	  
							<div className="login-input-wrapper">
								{this.state.passwordError && (
								<Message severity="error" text="Invalid password" style={{float:'right'}}/>              
								)}
								<span className="p-float-label">            
								<InputText
								id="password"              
								type="password"
								value={this.state.password}
								onChange={this.handleChange('password')}
          						onFocus={this.handleFocus('password')}
								className={
									this.state.passwordError ? 'p-error' : null
								}
								/>
								<label htmlFor="password" style={{ display: 'block' }}>
								Your Password
								</label>
								</span>
							</div> 
          
          					<Button type="submit" disabled={!dirty} label="Submit">
							  {
							    loading && <ProgressSpinner/> 
							  }
							</Button>                         
          				</form>
          			</div>
				</div>	
	}
}