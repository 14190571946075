
const hostname = window && window.location && window.location.hostname;

// console.log(window.location.hostname);

let config2use = "";

if (hostname === "localhost" || hostname === "10.10.0.110"){
    config2use = process.env.REACT_APP_DEVENV;
} else if (hostname === "uatenterprise.microkeyvault.com"){
    config2use = process.env.REACT_APP_UATENV;
} else {
    config2use = process.env.REACT_APP_PRODENV;
}

export const config = {    
    apiUrl: config2use
};
