import React, {Component} from 'react';

import {vaultService} from '../services/VaultServices';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Growl} from 'primereact/growl';
import {Message} from 'primereact/message';
import {Messages} from 'primereact/messages';

export class Domains extends Component {
    constructor() {
        super();
        this.state = {
            dataTableValue:[],
            panelCollapsed: true,
            totalRecords: 0,
            loading: false,
            domain: [],
            dirty: false,
            domainName: "",
            domainDescription: "",
            domainNameError: "",
            domainDescriptionError: "",
            featureList: ""
        };
        this.export = this.export.bind(this);
        this.onDomainSelect = this.onDomainSelect.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
        //this.vaultServiceLoad = new VaultTestService();
        this.onSortChange = this.onSortChange.bind(this);
    }

    componentDidMount() {
        let user = sessionStorage.getItem("user");
        if (user) {
                    // console.log(user);
                    let x = JSON.parse(user);
                    this.setState(() => ({
                    userId: x.userId,
                    token: x.token,
                    orgId: x.orgId,
                    domainId: x.domainId,
                    featureList: x.featureList
                  }));

                  if (!x.acceptedEula){
                    this.props.handleLogout();
                  } else{
                     
                    //console.log('tok',x.orgId);
                    this.setState({ loading: true });
                    vaultService.fetchVaultDomains(x.userId, x.token).then(data => this.setState({dataTableValue: data.itemResult,
                                                                                         totalRecords: data.itemResult.length}));                                             
                    this.setState({loading: false});   
                    }  
                }
        }  

    onDomainAddRequest = () => {
        this.setState({
            displayDialogAddDomain:true,
            domainInput: {domainName:'', domainDescription: ''}});
    }
    
    updateProperty(property, value) {
        let domainInput = this.state.domainInput;     
        domainInput[property] = value;        
        this.setState({dirty: true});
    }
    
    onCancel(action) {
       
        if (action === 'add'){
            this.setState({domainName: "",
                        domainDescription: "",
                        dirty: false,
                        displayDialogAddDomain:false,
                        domainNameError: "",
                        domainDescriptionError: "",
                        loading: false});
        } else if (action === 'edit'){
            this.setState({dirty:false,
                           loading:false,
                           domainNameError: "",
                           domainDescriptionError: "",
                           displayDialogEditDomain:false});
        }
    }

    async onDomainAdd () {
        //console.log("in add..", domain);
        
        this.setState({ loading: true });
        
        const err = this.validate("add");
        if (!err) {
            try {
                //console.log(this.state.domainInput);
                let currentCount = this.state.totalRecords + 1;
                let data = await vaultService.addVaultDomain(this.state.token, 
                                            this.state.domainInput.domainName, 
                                            this.state.domainInput.domainDescription,
                                            this.state.userId);
                                // .then(data => this.setState({dataTableValue: data.itemResult}));                                                         
               
                if (data.itemResult) {
                    this.setState({loading: false,
                        displayDialogAddDomain:false,
                        totalRecords: currentCount,
                        dataTableValue: data.itemResult});
                    this.growl.show({severity: 'success', summary: 'Success Message', detail: 'Domain added.'});               
                }else {                    
                    console.log(data.data)
                    this.showError(data.data)
                }
            } catch (e) {
                // alert(e);                    
                this.setState({loading: false,
                               displayDialogAddDomain:false});             
            }      
         }
    }
    async onDomainEdit (domain) {
        //console.log("in edit..", domain);
        
        this.setState({ loading: true });
        
        const err = this.validate("edit");
        if (!err) {
            try {
                //console.log("in edit...",this.state.domainInput);
                
                let data = await vaultService.editVaultDomain(domain.domainId, 
                                             this.state.token,                                               
                                             this.state.domainInput.domainNameEdit, 
                                             this.state.domainInput.domainDescriptionEdit,
                                             this.state.userId
                                             );
                                // .then(data => this.setState({dataTableValue: data.itemResult}));                                                         
                if (data.itemResult) {
                    this.setState({loading: false,
                                   displayDialogEditDomain:false,
                                   dataTableValue: data.itemResult});
                    this.growl.show({severity: 'success', summary: 'Success Message', detail: 'Domain edited.'});             
                }else {
                    this.showError(data.data)
                }

            } catch (e) {
                // alert(e);                    
                this.setState({loading: false,
                            displayDialogEditDomain:false});             
            }      
         }
    }
    handleFocus = name => event => {
        var x = [name] + "Error";    
        this.setState({[x]: ""});         
    }

    validate = (action) => {
        let isError = false;
        let domainName = "";
        let domainDescription = "";

        const errors = {
          domainNameError: "",
          domainDescriptionError: ""
        };
        
        if (action === 'add'){
            domainName = this.state.domainInput.domainName.replace(/^\s+|\s+$/gm,'');
        } else{
            domainName = this.state.domainInput.domainNameEdit.replace(/^\s+|\s+$/gm,'');
        }        
        if (!domainName.length >= 1) {
          isError = true;
          errors.domainNameError = "Domain Name is required";
        };   
        if (action === 'add'){
            domainDescription = this.state.domainInput.domainDescription.replace(/^\s+|\s+$/gm,'');
        } else {
            domainDescription = this.state.domainInput.domainDescriptionEdit.replace(/^\s+|\s+$/gm,'');
        }
        if (!domainDescription.length >= 1) {
          isError = true;
          errors.domainDescriptionError = "Domain Description is required";
        };    
        this.setState({
          ...this.state,
          ...errors
        });
    
        return isError;
      }

    async onDomainDelete (domainId) {
        try {
            this.setState({ loading: true });
                        
            await vaultService.deleteVaultDomain(domainId, this.state.token, this.state.userId)
                            .then(data => this.setState({dataTableValue: data.itemResult, returnCode: data.rc}));                                                         
            
            //console.log("in del",this.state.returnCode);
            
            let currentCount = this.state.totalRecords;
            if(this.state.returnCode === 0){
                this.growl.show({severity: 'success', summary: 'Success Message', detail: 'Domain deleted.'});             
                currentCount -= 1;
            } else {
                this.growl.show({severity: 'error', summary: 'Error Message', detail: 'Remove all domain assignments prior to deletion request'});
            }
            
            this.setState({loading: false,
                           displayDialogDeleteDomain:false,
                           totalRecords: currentCount});
            
          } catch (e) {
            alert(e);                    
            this.setState({loading: false,
                           displayDialogDeleteDomain:false});             
          }        
    }
    
    export() {

        this.dt.exportCSV();
    }

    onSortChange(event) {
        let value = event.value;

        if (value.indexOf('!') === 0)
            this.setState({sortOrder: -1, sortField:value.substring(1, value.length), sortKey: value});
        else
            this.setState({sortOrder: 1, sortField:value, sortKey: value});
    }

    onDomainSelect(data,action){

        if(action === "domainDelete"){
            //console.log("data domain",data.description);
            
                this.setState({
                    displayDialogDeleteDomain:true,
                    domain: Object.assign({}, data)
                });
                    
        } else { 
                //console.log("here",data);
                this.setState({
                    displayDialogEditDomain:true,
                    domain: Object.assign({}, data),
                    domainInput: {domainNameEdit:data.domain, 
                                  domainDescriptionEdit:data.description,
                                  domainId: data.domainId,
                                  orgId: data.orgId}});                
        }
    }

    actionTemplate(rowData, column) {        
        if (rowData.description !== 'Default'){
            return <div>
                <Button id="domainEdit"
                        type="button" icon="pi pi-pencil"
                        style={{margin:'2px',fontSize:'1em'}}
                        className="p-button-raised p-button-rounded p-button-warning"
                        tooltip="Edit this domain"
                        onClick={() => this.onDomainSelect(rowData, "domainEdit")}></Button> 
                <Button id="domainDelete"
                        type="button" icon="pi pi-times"
                        style={{margin:'2px',fontSize:'1em'}}
                        className="p-button-raised p-button-rounded p-button-danger"
                        tooltip="Remove this domain"
                        onClick={() => this.onDomainSelect(rowData, "domainDelete")}></Button>            
            </div>;
        }
    }

    showError = (message) =>  {
        let msg = {severity: 'error', summary: 'Error Message', detail: message};
        //this.growl.show(msg);
        this.messages.show(msg);
        this.setState({dirty:false});
    };
/* =========================================================================================== */
    render() {
       // console.log("and...",this.state);
                
        const { loading, domain, dirty, domainInput, featureList } = this.state;
       

        var footer = <div style={{textAlign:'left'}}>
                        <Button type="button" icon="pi pi-external-link" 
                                iconPos="left" label="Export" 
                                onClick={this.export}>
                        </Button>
                     </div>;

        var header = <div className="p-grid p-fluid">
                        <div className="p-col p-lg-2 p-sm-12">
                            <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})}
                                       placeholder="Global Search" size="50"/>
                        </div>
                        <div className="p-col p-sm-12 p-lg-10" style={{textAlign:'right'}}>
                              <span>Total Domains: {this.state.totalRecords}<br/></span>
                              {/* <span>Filtered Results: 0</span> */}
                        </div>                        
                     </div>;    
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1><i className="pi pi-globe"/> Domains</h1>
                        {
							    loading && <ProgressSpinner/> 
						} 
                        {featureList.includes(7) &&                       
                        <div className="p-col-12" style={{textAlign:"right"}}>                            
                            <span>
                            <Button id="domainAdd"
                                type="button" icon="pi pi-plus"
                                style={{margin:'2px'}}
                                label="Add Domain"
                                className="p-button-raised p-button-rounded p-button-success"
                                tooltip="Add a new domain"
                                onClick={() => this.onDomainAddRequest()}>
                            </Button>                            
                            </span>
                        </div>
                        }
                        <Growl ref={(el) => this.growl = el} />
                        <div>
                        <DataTable id="domains"
                                   value={this.state.dataTableValue} paginatorPosition="bottom"
                                   header={header} footer={footer} paginator={true} rows={10} responsive={true}
                                   selection={this.state.dataTableSelection} selectionMode="single"
                                   resizableColumns={true} 
                                   globalFilter={this.state.globalFilter} emptyMessage="No records found"
                                   ref={(el) => { this.dt = el; }}>
                             {featureList.includes(7) &&
                                <Column body={this.actionTemplate.bind(this)} header="Actions" style={{textAlign:'center',width:'10%'}}/>
                             }
                            
                            <Column field="domain" header="Domain" sortable={true} headerStyle={{textAlign: "left"}}/>
                            <Column field="description" header="Description" sortable={true} headerStyle={{textAlign: "left"}}/>
                        </DataTable>
                        </div>
                    </div>
                    <div>
                    <Dialog visible={this.state.displayDialogAddDomain}
                            modal={true} header={<span><i className="pi pi-globe"/>Add Domain</span>}
                            responsive={true} 
                            onHide={() => this.setState({displayDialogAddDomain: false})}>                            
                            <Messages ref={(elAdd) => this.messages = elAdd}></Messages>
                            <div className="p-grid">
                                <div className="p-col">
                                    {this.state.domainNameError && (
							           	<Message severity="error" text="Domain Name is required" />              
							        )}
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-globe"></i>
                                        </span>
                                        
                                        { domainInput &&                                         
                                        <InputText id="domainName" onChange={(e) => {this.updateProperty('domainName', e.target.value)}}
                                                   placeholder="Domain Name"  value={domainInput.domainName} 
                                                   onFocus={this.handleFocus('domainName')}
                                                   className={
								                            	this.state.domainNameError ? 'p-error' : null
							                              	 }/>
                                        }
                                    </div>
                                </div>
                                <div className="p-col">
                                {this.state.domainDescriptionError && (
                                            <div><Message severity="error" text="Domain Description is required"/></div>
                                        )}
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-info"></i>
                                        </span>
                                        
                                        { domainInput && 
                                        <InputText id="domainDescription" onChange={(e) => {this.updateProperty('domainDescription', e.target.value)}}
                                                   placeholder="Description" value={domainInput.domainDescription}
                                                   onFocus={this.handleFocus('domainDescription')}
                                                   className={
								                            	this.state.domainDescriptionError ? 'p-error' : null
							                              	 }/> 
                                        }
                                    </div>
                                </div>
                            </div>
                            
                            <div className="p-grid" style={{textAlign:'right'}}>
                                <div className="p-col" style={{marginTop:'1em'}}>                                   
                                        <Button id="domainAdd"
                                            type="button" icon="pi pi-check"
                                            style={{margin:'2px'}}                               
                                            className="p-button-raised p-button-rounded p-button-success"
                                            tooltip="Add a new domain"
                                            label="Add Domain"
                                            onClick={() => this.onDomainAdd()}
                                            disabled={!dirty}>
                                        </Button>  
                                        <Button id="domainCancel"
                                            type="button" icon="pi pi-times"
                                            style={{margin:'2px'}}                               
                                            className="p-button-raised p-button-rounded p-button-danger"
                                            tooltip="Cancel"
                                            label="Cancel"
                                            onClick={() => this.onCancel('add')}>
                                        </Button>  
                                </div>                                                              
                             </div>                           
                    </Dialog>
                    </div>
                    <div>
                    <Dialog visible={this.state.displayDialogEditDomain}
                            modal={true} header={<span><i className="pi pi-globe"/>Edit Domain</span>}
                            responsive={true} 
                            onHide={() => this.setState({displayDialogEditDomain: false})}>                            
                           
                           <Messages ref={(el) => this.messages = el}></Messages>
                            <div className="p-grid">
                                <div className="p-col">
                                    {this.state.domainNameError && (
							           	<Message severity="error" text="Domain Name is required" />              
							        )}
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-globe"></i>
                                        </span>
                                        
                                        { domainInput &&                                         
                                        <InputText id="domainNameEdit" onChange={(e) => {this.updateProperty('domainNameEdit', e.target.value)}}
                                                   placeholder="Domain Name"  value={domainInput.domainNameEdit} 
                                                   onFocus={this.handleFocus('domainNameEdit')}
                                                   className={
								                            	this.state.domainNameError ? 'p-error' : null
							                              	 }/>
                                        }
                                    </div>
                                </div>
                                <div className="p-col">
                                {this.state.domainDescriptionError && (
                                            <div><Message severity="error" text="Domain Description is required"/></div>
                                        )}
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-info"></i>
                                        </span>
                                        
                                        { domainInput && 
                                        <InputText id="domainDescriptionEdit" 
                                                   onChange={(e) => {this.updateProperty('domainDescriptionEdit', e.target.value)}}
                                                   placeholder="Description" value={domainInput.domainDescriptionEdit}
                                                   onFocus={this.handleFocus('domainDescriptionEdit')}
                                                   className={
								                            	this.state.domainDescriptionError ? 'p-error' : null
							                              	 }/> 
                                        }
                                    </div>
                                </div>
                            </div>
                            
                            <div className="p-grid" style={{textAlign:'right'}}>
                                <div className="p-col" style={{marginTop:'1em'}}>                                   
                                        <Button id="domainEdit"
                                            type="button" icon="pi pi-check"
                                            style={{margin:'2px'}}                               
                                            className="p-button-raised p-button-rounded p-button-success"
                                            tooltip="Edit this domain"
                                            label="Edit Domain"
                                            onClick={() => this.onDomainEdit(domain)}
                                            disabled={!dirty}>
                                        </Button>  
                                        <Button id="domainCancel"
                                            type="button" icon="pi pi-times"
                                            style={{margin:'2px'}}                               
                                            className="p-button-raised p-button-rounded p-button-danger"
                                            tooltip="Cancel"
                                            label="Cancel"
                                            onClick={() => this.onCancel('edit')}>
                                        </Button>  
                                </div>                                                              
                             </div>                           
                    </Dialog>
                    </div>     
                    <div>
                    <Dialog visible={this.state.displayDialogDeleteDomain}
                            modal={true} header={<span><i className="pi pi-globe"/>Delete Domain</span>}
                            responsive={true} 
                            onHide={() => this.setState({displayDialogDeleteDomain: false})}>
                            <div className="p-grid">
                                <div className="p-col">
                                    <div className="p-inputgroup">
                                        Are you sure you want to delete {domain.domain} {domain.description} ?<br/>
                                        This action is permanent
                                    </div>
                                </div>
                            </div>                            
                            <div className="p-grid" style={{textAlign:'right'}}>
                                <div className="p-col" style={{marginTop:'1em'}}>                                   
                                        <Button id="domainConfirmed"
                                            type="button" icon="pi pi-check"
                                            style={{margin:'2px'}}                               
                                            className="p-button-raised p-button-rounded p-button-success"                                            
                                            label="Yes"
                                            onClick={() => this.onDomainDelete(domain.domainId)}>
                                        </Button>  
                                        <Button id="domainCancel"
                                            type="button" icon="pi pi-times"
                                            style={{margin:'2px'}}                               
                                            className="p-button-raised p-button-rounded p-button-danger"                                            
                                            label="Cancel"
                                            onClick={() => this.setState({displayDialogDeleteDomain: false, loading: false})}>
                                        </Button>  
                                </div>                                                              
                             </div>
                            
                    </Dialog>
                    </div>                                                        
                </div>
            </div>
        );
    }
}