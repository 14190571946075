import React, {Component} from 'react';
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';
import classNames from 'classnames';

export class AppTopbar extends Component {

	static defaultProps = {
		onMenuButtonClick: null,
		onTopbarMenuButtonClick: null,
		onTopbarItemClick: null,
		topbarMenuActive: false,
		activeTopbarItem: null,
		profileMode: null,
		horizontal: null
	}

	static propTypes = {
		onMenuButtonClick: PropTypes.func.isRequired,
		onTopbarMenuButtonClick: PropTypes.func.isRequired,
		onTopbarItemClick: PropTypes.func.isRequired,
		topbarMenuActive: PropTypes.bool.isRequired,
		activeTopbarItem: PropTypes.string,
		profileMode: PropTypes.string,
		horizontal: PropTypes.bool
	}

	constructor() {
		super();
		this.state = {};
	}

	onTopbarItemClick(event, item) {
		if (this.props.onTopbarItemClick) {
			this.props.onTopbarItemClick({
				originalEvent: event,
				item: item
			});			
		}
	}

	launchHelp = () => {
		window.open("https://mwspi.atlassian.net/wiki/spaces/MUG/pages/98304027/MicroKeyVault+Enterprise", "_blank");
	}

	launchEula = () => {
		//this.props.history.push("/EulaStatic");
		console.log(this.props)	
	}

	launchPrivPol = () => {
		window.open("https://www.mwspi.com/privacy-policy/", "_blank");
	}

	render() {
		let topbarItemsClassName = classNames('topbar-menu fadeInDown', {'topbar-menu-visible': this.props.topbarMenuActive});		

		return <div className="layout-topbar clearfix">

			<button className="layout-topbar-logo p-link">		
      		<Link style={{display: 'block', height: '100%'}} to="/">
			  	<img id="layout-topbar-logo" alt="babylon-layout" src="assets/layout/images/logo-white.png"/>
			</Link>
			</button>

			<button className="layout-menu-button p-link"  onClick={this.props.onMenuButtonClick}>
				<i className="pi pi-bars"/>
			</button>

			<button id="topbar-menu-button" className="p-link"  onClick={this.props.onTopbarMenuButtonClick}>
				<i className="pi pi-ellipsis-v"/>
			</button>

			<ul className={topbarItemsClassName}>				
				<li className={classNames({'active-topmenuitem': this.props.activeTopbarItem === 'logout'})}
					onClick={(e) => this.onTopbarItemClick(e, 'logout')}>
					<button className="p-link">
						<i className="topbar-icon pi pi-sign-out" title="Sign out" style={{padding:0,margin:0,fontSize:'2.5em'}}/>
						<span className="weelittle">Sign out</span>						
					</button>					
				</li>
				<li className={classNames({'active-topmenuitem': this.props.activeTopbarItem === 'profile'})}
					onClick={(e) => this.onTopbarItemClick(e, 'profile')}>
					<button className="p-link">
						<i className="topbar-icon pi pi-user" title="Your Profile"/>	
					</button>

					<ul className="fadeInDown">
						<li role="menuitem">
							Logged in as: {this.props.firstName} {this.props.lastName}
						</li>
						<li role="menuitem">							
							<Link style={{display: 'block', height: '100%'}} to="EulaStatic">
								<i className="topbar-icon pi pi-caret-right" title="Review EULA"></i>	
								Review EULA
							</Link>		
							<Link style={{display: 'block', height: '100%'}} onClick={(e) => {e.preventDefault();this.launchPrivPol()}}>
								<i className="topbar-icon pi pi-caret-right" title="Review Privacy Policy"></i>	
								Review Privacy Policy
							</Link>												
				    		{/* <button className="p-link" 
					        		onClick={(e) => {e.preventDefault();this.launchPrivPol()}}>
								<i className="topbar-icon pi pi-caret-right" title="Privacy Policy"></i>	
								Review Privacy Policy
							</button> */}
						</li>	
					</ul>
				</li>
				<li className={classNames({'active-topmenuitem': this.props.activeTopbarItem === 'help'})}>
				    <button className="p-link" 
					        onClick={(e) => {e.preventDefault();this.launchHelp()}}>
						<i className="topbar-icon pi pi-question" title="Help"></i>	
					</button>
				</li>	
						
			</ul>
		</div>;
	}
}